<template>
  <div class="addBox">
    <!--  面包屑  -->
    <div
      class="mrj-layout-tabs-custom flex align-item-center"
      style="z-index:1;"
    >
      <div
        @click="changeRoute"
        class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer"
      >
        <div
          type="left"
          class="leftarrow"
          :class="imgflag?'leftarrow_hover':''"
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >
          <i class="meiye-icon meiye-fanghui"></i>
        </div>

        <span
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page">{{$route.query.type == 1?'添加储值卡':$route.query.type == 2?"编辑":"编辑储值卡" }}</span>
    </div>
    <!-- 内容区 -->
    <div class="add">
      <div class="addleft">
        <div class="leftbox">
          <div
            class="onetxt"
            @click="goStep(1)"
            :class="step===1?'active':''"
          >1</div>
          <div
            class="base"
            @click="goStep(1)"
            :class="step===1?'active1':''"
          >基本设置</div>
          <div class="line"></div>
          <div
            class="onetxt"
            @click="goStep(2)"
            :class="step===2?'active':''"
          >2</div>
          <div
            class="base"
            @click="goStep(2)"
            :class="step===2?'active1':''"
          >适用设置</div>
          <div class="line"></div>
          <div
            class="onetxt"
            @click="goStep(3)"
            :class="step===3?'active':''"
          >3</div>
          <div
            class="base"
            @click="goStep(3)"
            :class="step===3?'active1':''"
          >其他设置</div>
          <!-- <div class="line"></div>   -->
        </div>
      </div>
      <div class="addright">
        <!-- 第一步 -->
        <div
          class="one"
          v-if="step===1"
        >
          <div class="zengpinstep">
            <h2 class="twoh2">
              <span class="tpointer"></span>
              <span class="ttxt">基本设置</span>
            </h2>
            <a-form
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 16 }"
              labelAlign="left"
            >
              <!-- 来源 -->
              <a-form-item
                class="mgb-18"
                label="来源"
              >
                总部创建
              </a-form-item>
              <!-- 产品名称 -->
              <a-form-item
                label="储值卡名称"
                required
              >
                <!-- <span v-if="$route.query.type == 3">{{form.scheme_name}}</span> -->
                <a-input
                  allowClear
                  class="add_input   ant-input_1 wid-488"
                  v-model="form.goods_name"
                  :maxLength="20"
                  @change="getGoodsname"
                  placeholder="输入储值卡名称，20字以内"
                />
              </a-form-item>
              <!-- 商品码 -->
              <a-form-item
                label="商品码"
                required
              >
                <!-- <span v-if="$route.query.type == 3">{{form.goods_bn}}</span> -->
                <a-input
                  allowClear
                  v-model="form.goods_bn"
                  class="add_input  ant-input_1 wid-488"
                  :maxLength="20"
                  oninput="value=value.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g, '')"
                  placeholder="产品名称首字母+4位随机数"
                />
              </a-form-item>
              <!-- 标准单价 -->
              <a-form-item
                label="标准单价"
                required
              >
                <!-- <span v-if="$route.query.type == 3">{{form.goods_attr_val[0].product_price}}</span> -->
                <a-input
                  allowClear
                  v-model="form.goods_attr_val[0].product_price"
                  style="width:154px;"
                  class="add_input  ant-input_1"
                  :maxLength="7"
                  placeholder=""
                  prefix="￥"
                  oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                />
              </a-form-item>
              <!-- 面额 -->
              <a-form-item
                label="面额"
                required
                v-if="false"
              >
                <div class="flex align-item-center">
                  <a-input
                    allowClear
                    v-model="form.face_value"
                    style="width:154px;height:36px;line-height: 36px;margin-right: 12px;"
                    placeholder=""
                    prefix="￥"
                    class="add_input ant-input_1"
                    :maxLength="7"
                    oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>1000000)value=1000000;if(value<0)value=null;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
                  />
                  <a-switch
                    class="mgl-12 "
                    @change="changefaceisedit"
                    v-model="is_edit_face_value"
                  />
                  <span class="font5 fs-14 mgl-8">是否允许开单时修改面额</span>
                  <!-- <div
                    class="checkqystatus flex align-item-center"
                    style="width:278px; "
                  >
                    <span
                      class="cqt1"
                      style="width:139px"
                      @click="form.is_edit_face_value=1"
                      :class="form.is_edit_face_value==1? 'cqt1A':''"
                    >允许修改面额</span>
                    <span
                      class="cqt1"
                      style="width:139px"
                      @click="form.is_edit_face_value=0"
                      :class="form.is_edit_face_value==0? 'cqt1A':''"
                    >禁止修改面额</span>
                  </div> -->

                </div>

                <!-- oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')" -->

              </a-form-item>

              <!-- 财务分类 -->
              <div class="samerow flex mgb-24 ">
                <a-form-item
                  label="财务分类"
                  required
                  class="samerow1"
                  style="margin-bottom:0px"
                >

                  <span
                    style="width:auto;position: relative;"
                    id="styledatas"
                    class="styledatas"
                  >
                    <a-cascader
                      v-model="form.finance_category_id"
                      :options="treeData1"
                      placeholder="分类"
                      @change="onChange"
                      style="min-width: 260px;"
                      :fieldNames="selectreeObj2"
                      :getPopupContainer="getPopupContainer"
                    />
                  </span>

                  <!-- 商城分类 -->
                  <a-form-item
                    label="商城分类"
                    class="samerow2"
                    style="margin-bottom:0px"
                  >
                    <template>
                      <span
                        style="width:auto;position: relative;"
                        id="styledatas3"
                        class="styledatas3"
                      >
                        <a-cascader
                          v-model="form.store_category_id"
                          :options="treeData3"
                          placeholder="分类"
                          @change="onChange"
                          style="width: 260px;"
                          :fieldNames="selectreeObj3"
                          :getPopupContainer="getPopupContainer3"
                        />
                      </span>

                    </template>
                  </a-form-item>

                </a-form-item>
              </div>
              <!-- 品牌 -->
              <div class="samerow flex mgb10">
                <a-form-item
                  label="品牌"
                  class="samerow1"
                >
                  <div>
                    <a-select
                      placeholder="选择品牌"
                      allowClear
                      v-model="form.goods_brand"
                      style="width: 260px"
                      :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                    >
                      <a-select-option
                        v-for="item in brandsList"
                        :key="item.brand_id"
                      >
                        {{ item.brand_name }}
                      </a-select-option>
                    </a-select>
                  </div>

                  <a-form-item
                    label="门店分类"
                    class="samerow2"
                    style="margin-bottom:0px"
                  >
                    <template>
                      <span
                        style="width:auto;position: relative;"
                        id="styledatas2"
                        class="styledatas2"
                      >
                        <a-cascader
                          v-model="form.goods_category"
                          :options="treeData2"
                          placeholder="分类"
                          @change="onChange"
                          style="min-width: 260px;"
                          :fieldNames="selectreeObj"
                          :getPopupContainer="getPopupContainer2"
                        />
                      </span>

                    </template>
                  </a-form-item>
                </a-form-item>
              </div>

              <!-- 适用产品 -->
              <a-form-item
                label="适用产品"
                v-if="false"
              >
                <div class="allselectgoods ">
                  <a-select
                    placeholder="选择"
                    v-model="goods_obj.value"
                    style="width: 260px;margin-right:12px;"
                    :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                    @change="changeconsumableValue"
                  >
                    <a-select-option
                      v-for="item in goods_select"
                      :key="item.key"
                    >
                      {{ item.value }}
                    </a-select-option>
                  </a-select>

                  <a-button
                    class="class_btn4 "
                    @click="changeconsumableValue(goods_obj.value,1)"
                  >编辑</a-button> <span style="margin:0 24px 0 12px">已选 <span class="baseColor11">23</span> </span>
                  <div
                    class="bot_box"
                    v-if="!goods_obj.openOrFold"
                  >
                    <span class="bot_item">苹果原萃精华护理</span> <span class="bot_item">苹果原萃精华护理</span> <span class="bot_item">苹果原萃精华护理</span><span class="bot_item">钒钛微晶水光</span> <span class="bot_item">面部中胚美塑护理</span>
                    <span class="bot_length bot_item">+{{32}}…</span> <span
                      class="bot_item bot_open pointer"
                      @click="goods_obj.openOrFold=!goods_obj.openOrFold"
                    >展开 <i class="meiye-icon meiye-moren1"></i> </span>
                  </div>
                  <div
                    class="bot_box1"
                    v-else
                  >
                    <span class="bot_item">苹果原萃精华护理</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">苹果原萃精华护理</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">苹果原萃精华护理</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">面部中胚美塑护理</span>
                    <!-- <span class="bot_length bot_item">+{{32}}…</span> -->
                    <span
                      @click="goods_obj.openOrFold=!goods_obj.openOrFold"
                      class="bot_item bot_open pointer"
                    >收起 <i
                        class="meiye-icon meiye-moren1"
                        style="display:inline-block;transform: rotateZ(180deg);"
                      ></i> </span>
                  </div>

                </div>

              </a-form-item>
              <!-- 适用服务 -->
              <a-form-item
                label="适用服务"
                v-if="false"
              >
                <div class="allselectgoods ">
                  <a-select
                    placeholder="选择"
                    v-model="service__obj.value"
                    :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                    style="width: 180px;margin-right:12px;"
                  >
                    <a-select-option
                      v-for="item in service_select"
                      :key="item.key"
                    >
                      {{ item.value }}
                    </a-select-option>
                  </a-select>
                  <span v-if="service__obj.value>2">
                    <a-button
                      class="class_btn4 "
                      @click="changeconsumableValue(service__obj.value,2)"
                    >编辑</a-button> <span style="margin:0 24px 0 12px">已选 <span class="baseColor11">23</span> </span>
                    <span
                      class="bot_box"
                      v-if="!service__obj.openOrFold"
                    >
                      <span class="bot_item">苹果原萃精华护理</span><span class="bot_item">钒钛微晶水光</span> <span class="bot_item">面部中胚美塑护理</span>
                      <span class="bot_length bot_item">+{{32}}…</span> <span
                        class="bot_item bot_open pointer"
                        @click="service__obj.openOrFold=!service__obj.openOrFold"
                      >展开 <i class="meiye-icon meiye-moren1"></i> </span>
                    </span>
                    <span
                      class="bot_box1"
                      v-else
                    >
                      <span class="bot_item">苹果原萃精华护理</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">苹果原萃精华护理</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">苹果原萃精华护理</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">面部中胚美塑护理</span>
                      <!-- <span class="bot_length bot_item">+{{32}}…</span> -->
                      <span
                        @click="service__obj.openOrFold=!service__obj.openOrFold"
                        class="bot_item bot_open pointer"
                      >收起 <i
                          class="meiye-icon meiye-moren1"
                          style="display:inline-block;transform: rotateZ(180deg);"
                        ></i> </span>
                    </span>
                  </span>

                </div>

              </a-form-item>
              <!-- 适用方案 -->
              <a-form-item
                label="适用方案"
                class="mgb10"
                v-if="false"
              >
                <div class="allselectgoods ">
                  <a-select
                    placeholder="选择"
                    v-model="plan__obj.value"
                    :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                    style="width: 180px;margin-right:12px;"
                  >
                    <a-select-option
                      v-for="item in plan_select"
                      :key="item.key"
                    >
                      {{ item.value }}
                    </a-select-option>
                  </a-select>
                  <span v-if="plan__obj.value>2">
                    <a-button
                      class="class_btn4 "
                      @click="changeconsumableValue(plan__obj.value,3)"
                    >编辑</a-button> <span style="margin:0 24px 0 12px">已选 <span class="baseColor11">23</span> </span>
                    <span
                      class="bot_box"
                      v-if="!plan__obj.openOrFold"
                    >
                      <span class="bot_item">苹果原萃精华护理</span><span class="bot_item">钒钛微晶水光</span> <span class="bot_item">面部中胚美塑护理</span>
                      <span class="bot_length bot_item">+{{32}}…</span> <span
                        class="bot_item bot_open pointer"
                        @click="plan__obj.openOrFold=!plan__obj.openOrFold"
                      >展开 <i class="meiye-icon meiye-moren1"></i> </span>
                    </span>
                    <span
                      class="bot_box1"
                      v-else
                    >
                      <span class="bot_item">苹果原萃精华护理</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">苹果原萃精华护理</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">苹果原萃精华护理</span><span class="bot_item">钒钛微晶水光</span><span class="bot_item">面部中胚美塑护理</span><span class="bot_item">面部中胚美塑护理</span>
                      <!-- <span class="bot_length bot_item">+{{32}}…</span> <span -->
                      @click="plan__obj.openOrFold=!plan__obj.openOrFold"
                      class="bot_item bot_open pointer"
                      >收起 <i
                        class="meiye-icon meiye-moren1"
                        style="display:inline-block;transform: rotateZ(180deg);"
                      ></i> </span>
                  </span>
                  </span>

                </div>

              </a-form-item>
              <a-form-item
                label=" "
                class=""
              >
                <a-button
                  type="primary"
                  class="bigheightbn wid-80 "
                  @click="onStep(2)"
                >下一步</a-button>
                <a-button
                  class="mgl-20 normalbtn bigheightbn wid-80"
                  @click="cancel()"
                >取消</a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <!-- 第二步 -->
        <div
          class="two"
          v-if="step===2"
        >
          <div class="zengpinstep zengpinstep2">
            <h2
              class="twoh2 mgl-28"
              style="margin-bottom:-3px;"
            >
              <span class="tpointer"></span>
              <span class="ttxt">适用设置</span>
            </h2>
            <div
              class="zengpinBody mgl-28"
              style="padding-bottom:48px;"
              slot-scope=""
            >
              <!-- 适用产品 *  -->
              <div class="prostep2h flex align-item-center">
                <i
                  @click=";step2data.isfold=!step2data.isfold"
                  v-if="!step2data.isfold"
                  class="meiye-icon meiye-xiangyou font-18"
                ></i>
                <i
                  @click=";step2data.isfold=!step2data.isfold"
                  v-else
                  class="meiye-icon meiye-xiangxia   meiye-jiantou2 color11 font-18"
                ></i>
                <span class="sypro mgr-24 ">适用产品
                  <span
                    class="xing"
                    style="color:#F35E67"
                  >*</span>
                </span>
                <a-switch
                  class="mgr-6"
                  default-checked
                  :checked="step2data.changepro"
                  @change=";step2data.changepro=!step2data.changepro;changepopways1(step2data.changepro)"
                />
                <span>{{step2data.changepro?'适用':'不适用'}}</span>
              </div>
              <!-- 是否全部 step2data.changepro ||-->
              <div
                class="isprosBox"
                v-if="step2data.isfold"
              >
                <div
                  class="ispros"
                  v-if="step2data.changepro"
                >
                  <div class=" ">
                    <a-radio-group
                      v-model="step2data.allgoodsorsingle"
                      class="lable_radiogroup"
                      style="width:240px;"
                    >
                      <a-radio-button
                        value="1"
                        style="width:120px;text-align: center;"
                        @click="form.rule_entity_type='1'"
                      >
                        全部产品
                      </a-radio-button>
                      <a-radio-button
                        value="2"
                        style="width:120px;text-align: center;"
                        @click="loadProduct([1,2,3,4]);form.rule_entity_type='4'"
                      >
                        部分产品
                      </a-radio-button>
                    </a-radio-group>
                  </div>
                </div>
                <!-- 选择产品  选择产品2 选择分类3 选择品牌4 -->
                <!-- 选择服务  选择服务 选择分类 选择品牌 -->
                <!-- 选择分类  选择分类 选择分类 选择品牌 -->
                <div
                  class="step2Choose"
                  v-if="step2data.changepro&&step2data.allgoodsorsingle==2"
                >
                  <!-- 选择框 -->
                  <div class="selectstep2 mgb-10 mgt-24 ">
                    <div class="anotherSelecgt flex align-item-center">
                      <a-select
                        placeholder="选择产品"
                        v-model="form.rule_entity_type"
                        style="width: 180px"
                        :allowClear="true"
                        :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                        class="mgr-16"
                      >
                        <a-select-option
                          value="4"
                          key="4"
                          @click="loadProduct([1,2,3,4]);"
                        >
                          选择产品
                        </a-select-option>
                        <a-select-option
                          value="2"
                          key="2"
                          @click="step2data.types='2'"
                        >
                          选择分类
                        </a-select-option>
                        <a-select-option
                          value="3"
                          key="3"
                        >
                          选择品牌
                        </a-select-option>
                      </a-select>
                      <a-select
                        v-if="form.rule_entity_type==2"
                        placeholder="选择分类"
                        v-model="step2data.types"
                        style="width: 180px"
                        :allowClear="true"
                        class="mgr-16"
                        :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                      >
                        <a-select-option
                          value="2"
                          key="2"
                          @click="product_types_tree1 = treeData;"
                        >
                          财务分类
                        </a-select-option>
                        <a-select-option
                          value="6"
                          key="6"
                          @click="product_types_tree1 = treeData2;"
                        >
                          门店分类
                        </a-select-option>
                        <a-select-option
                          value="5"
                          key="5"
                          @click="product_types_tree1 = treeData3;"
                        >
                          商城分类
                        </a-select-option>
                      </a-select>
                    </div>

                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入产品名称"
                      v-if="form.rule_entity_type==4"
                      @change="changeentityOptions"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入分类"
                      v-model="step2data.searchValue"
                      v-if="form.rule_entity_type==2 && step2data.types==2"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入分类"
                      v-model="step2data.searchValue2"
                      v-if="form.rule_entity_type==2 && step2data.types==6"
                      @change="changeentityOptions3"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入分类"
                      v-model="step2data.searchValue3"
                      v-if="form.rule_entity_type==2 && step2data.types==5"
                      @change="changeentityOptions4"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入品牌"
                      v-model="step2data.searchValueBrands"
                      v-if="form.rule_entity_type==3"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>

                  </div>
                  <!-- 3个 穿梭框  产品/服务/方案4  分类2  品牌3-->
                  <a-transfer
                    v-if="form.rule_entity_type==4"
                    :data-source="step2data.mockData"
                    :target-keys="step2data.targetKeys"
                    :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
                    :show-select-all="false"
                    @change="event=> onlimitsChange(event,1)"
                    class="ant-transfer_1"
                    :list-style="{
                        width: '575px', 
                    }"
                  >
                    <template
                      slot="children"
                      slot-scope="{
                            props: { direction, filteredItems, selectedKeys, disabled: listDisabled },
                            on: { itemSelectAll, itemSelect },
                          }"
                    >
                      <a-table
                        :pagination="false"
                        :row-selection="
                            getRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })"
                        :columns="direction === 'left' ? step2data.leftColumns : step2data.rightColumns"
                        :data-source="filteredItems"
                        :scroll="{y:522}"
                        size="small"
                      >

                        <template
                          slot="titles"
                          slot-scope="action, record"
                        >

                          <a-tooltip>
                            <template slot="title">
                              <div class="">{{ record.title }}</div>
                            </template>
                            <div class="transferaPQ">{{ record.title }}</div>
                          </a-tooltip>
                          <a-tooltip>
                            <template slot="title">
                              <div class=" ">商品码：{{ record.goods_bn }}</div>
                            </template>
                            <div class="transferaPQ2">商品码：{{ record.goods_bn }}</div>
                          </a-tooltip>

                        </template>
                        <template
                          slot="finance_category_name"
                          slot-scope="action, record"
                        >
                          <a-tooltip>
                            <template slot="title">
                              <div class=" ">
                                {{ record.finance_category_name!='-'?record.finance_category_name+',':'' }}
                                {{record.goods_category_name!='-'?record.goods_category_name+',':'' }}
                                {{record.store_category_name!='-'?record.store_category_name+',':''  }}
                              </div>
                            </template>
                            <div
                              class="blockTypes "
                              style="margin-left:12px;"
                            >
                              {{ record.finance_category_name!='-'?record.finance_category_name+',':'' }}
                              {{record.goods_category_name!='-'?record.goods_category_name+',':'' }}
                              {{record.store_category_name!='-'?record.store_category_name+',':''  }}
                            </div>
                          </a-tooltip>
                        </template>
                        <template
                          slot="goods_type"
                          slot-scope="action, record"
                        >
                          <span style="margin-left:12px;">
                            {{ record.goods_type=='1'?'客装':record.goods_type=='2'?
                                '店装': record.goods_type=='3'?"客装":'店装' 
                                }}
                          </span>
                        </template>
                      </a-table>
                    </template>
                  </a-transfer>
                  <!--财务 分类 -->
                  <a-transfer
                    v-if="form.rule_entity_type==2 && step2data.types==2"
                    class="tree-transfer tree-transfer1 pdr-32"
                    :data-source="step2data.mockDataTypes"
                    :target-keys="step2data.targetKeysTypes"
                    :render="item => item.title"
                    :show-select-all="false"
                    @change="event=> onlimitsChange(event,2)"
                    :titles="['分类名称','分类名称']"
                    :rowKey="record => record.key"
                    :list-style="{
                        width: '575px', 
                    }"
                  >
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
                    >
                      <a-tree
                        v-if="direction === 'left'"
                        blockNode
                        checkable
                        checkStrictly
                        defaultExpandAll
                        :checkedKeys="[...selectedKeys, ...step2data.targetKeysTypes]"
                        :treeData="product_types_tree"
                        @check="  
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes], itemSelect);
                        }
                      "
                        @select="
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes], itemSelect);
                        }
                      "
                      >
                        <template
                          slot="title"
                          slot-scope="{ title }"
                        >
                          <span v-if="title.indexOf(step2data.searchValue) > -1">
                            {{ title.substr(0, title.indexOf(step2data.searchValue)) }}
                            <span style="color: #00B2A8">{{ step2data.searchValue }}</span>
                            {{ title.substr(title.indexOf(step2data.searchValue) + step2data.searchValue.length) }}
                          </span>
                          <span v-else>{{ title }}</span>
                        </template>
                      </a-tree>

                    </template>
                    <!--  product_types_tree -->
                  </a-transfer>
                  <!--门店 分类 -->
                  <a-transfer
                    class="tree-transfer "
                    :target-keys="step2data.targetKeysTypes2"
                    :render="item => item.title"
                    v-if="form.rule_entity_type==2 && step2data.types==6"
                    :data-source="step2data.mockDataTypes2"
                    :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
                    :show-select-all="false"
                    @change="event=> onlimitsChange(event,2)"
                    :titles="['分类名称','分类名称']"
                    :list-style="{
                        width: '575px', 
                    }"
                  >
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
                    >
                      <a-tree
                        v-if="direction === 'left'"
                        blockNode
                        checkable
                        checkStrictly
                        defaultExpandAll
                        :expanded-keys="step2data.expandedKeys"
                        :auto-expand-parent="step2data.autoExpandParent"
                        :replace-fields="replaceFields2"
                        :checkedKeys="[...selectedKeys, ...step2data.targetKeysTypes2]"
                        :treeData="product_types_tree2copy"
                        :rowKey="record=>record.key"
                        @check="  
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes2], itemSelect);
                        }
                      "
                        @select="
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes2], itemSelect);
                        }
                      "
                        @expand=" (_, props) => {
                          onExpand(_, props, [...selectedKeys, ...step2data.targetKeysTypes2], itemSelect);
                        }"
                      >
                        <!-- -->
                        <template
                          slot="title"
                          slot-scope="{ title }"
                        >
                          <span v-if="title.indexOf(step2data.searchValue2) > -1">
                            {{ title.substr(0, title.indexOf(step2data.searchValue2)) }}
                            <span style="color: #00B2A8">{{ step2data.searchValue2 }}</span>
                            {{ title.substr(title.indexOf(step2data.searchValue2) + step2data.searchValue2.length) }}
                          </span>
                          <span v-else>{{ title }}</span>
                        </template>
                      </a-tree>
                      <!-- 
                        :replace-fields="replaceFields2" -->
                    </template>
                  </a-transfer>
                  <!--商城 分类 -->
                  <a-transfer
                    class="tree-transfer "
                    :target-keys="step2data.targetKeysTypes3"
                    :render="item => item.title"
                    v-if="form.rule_entity_type==2 && step2data.types==5"
                    :data-source="step2data.mockDataTypes3"
                    :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
                    :show-select-all="false"
                    @change="event=> onlimitsChange(event,2)"
                    :titles="['分类名称','分类名称']"
                  >
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
                    >
                      <a-tree
                        v-if="direction === 'left'"
                        blockNode
                        checkable
                        checkStrictly
                        defaultExpandAll
                        :expanded-keys="step2data.expandedKeys2"
                        :auto-expand-parent="step2data.autoExpandParent2"
                        :replace-fields="replaceFields"
                        :checkedKeys="[...selectedKeys, ...step2data.targetKeysTypes3]"
                        :treeData="product_types_tree3copy"
                        @check="  
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes3], itemSelect);
                        }
                      "
                        @select="
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes3], itemSelect);
                        }
                      "
                        @expand=" (_, props) => {
                          onExpand2(_, props, [...selectedKeys, ...step2data.targetKeysTypes3], itemSelect);
                        }"
                      >
                        <template
                          slot="title"
                          slot-scope="{ title }"
                        >
                          <span v-if="title.indexOf(step2data.searchValue3) > -1">
                            {{ title.substr(0, title.indexOf(step2data.searchValue3)) }}
                            <span style="color: #00B2A8">{{ step2data.searchValue3 }}</span>
                            {{ title.substr(title.indexOf(step2data.searchValue3) + step2data.searchValue3.length) }}
                          </span>
                          <span v-else>{{ title }}</span>
                        </template>
                      </a-tree>
                    </template>
                  </a-transfer>
                  <!-- 品牌 穿梭 -->
                  <a-transfer
                    class="tree-transfer tree-transfer1"
                    :target-keys="step2data.targetKeysBrands"
                    :render="item => item.title"
                    v-if="form.rule_entity_type==3"
                    :data-source="step2data.mockDataBrands"
                    :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
                    :show-select-all="false"
                    @change="event=> onlimitsChange(event,3)"
                    :titles="['品牌名称','品牌名称']"
                    :rowKey="record => record.key"
                  >
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
                    >
                      <a-tree
                        v-if="direction === 'left'"
                        blockNode
                        checkable
                        checkStrictly
                        defaultExpandAll
                        :checkedKeys="[...selectedKeys, ...step2data.targetKeysBrands]"
                        :treeData="product_types_tree4"
                        @check="  
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysBrands], itemSelect);
                        }
                      "
                        @select="
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysBrands], itemSelect);
                        }
                      "
                      >
                        <template
                          slot="title"
                          slot-scope="{ title }"
                        >
                          <span v-if="title.indexOf(step2data.searchValueBrands) > -1">
                            {{ title.substr(0, title.indexOf(step2data.searchValueBrands)) }}
                            <span style="color: #00B2A8">{{ step2data.searchValueBrands }}</span>
                            {{ title.substr(title.indexOf(step2data.searchValueBrands) + step2data.searchValueBrands.length) }}
                          </span>
                          <span v-else>{{ title }}</span>
                        </template>
                      </a-tree>
                    </template>
                  </a-transfer>
                </div>
              </div>
              <!-- 适用服务 *  -->
              <div class="prostep2h flex align-item-center">
                <i
                  @click=";step2data.isfold2=!step2data.isfold2"
                  v-if="!step2data.isfold2"
                  class="meiye-icon meiye-xiangyou   font-18"
                ></i>
                <i
                  v-else
                  @click=";step2data.isfold2=!step2data.isfold2"
                  class="meiye-icon meiye-xiangxia   meiye-jiantou2 color11  font-18"
                ></i>
                <span class="sypro mgr-24">适用服务
                  <span
                    class="xing"
                    style="color:#F35E67"
                  >*</span>
                </span>
                <a-switch
                  class="mgr-6"
                  default-checked
                  :checked="step2data.changepro2"
                  @change=";step2data.changepro2=!step2data.changepro2;changepopways2(step2data.changepro2)"
                />
                <span>{{step2data.changepro2?'适用':'不适用'}}</span>
              </div>
              <!-- 是否全部 -->
              <div
                class="isprosBox"
                v-if="step2data.isfold2"
              >
                <div
                  class="ispros"
                  v-if="step2data.changepro2 && step2data.isfold2"
                >
                  <div class=" ">
                    <a-radio-group
                      v-model="step2data.allgoodsorsingle2"
                      class="lable_radiogroup"
                      style="width:240px;"
                    >
                      <a-radio-button
                        value="1"
                        style="width:120px;text-align: center;"
                        @click="form.rule_service_type='1'"
                      >
                        全部服务
                      </a-radio-button>
                      <a-radio-button
                        value="2"
                        style="width:120px;text-align: center;"
                        @click="loadProduct2([5]);form.rule_service_type='4'"
                      >
                        部分服务
                      </a-radio-button>
                    </a-radio-group>
                  </div>
                </div>
                <!-- 选择产品  选择产品2 选择分类3 选择品牌4 -->
                <!-- 选择服务  选择服务 选择分类 选择品牌 -->
                <!-- 选择分类  选择分类 选择分类 选择品牌 -->
                <div
                  class="step2Choose"
                  v-if="step2data.changepro2 && step2data.allgoodsorsingle2==2"
                >
                  <!-- 选择框 -->
                  <div class="selectstep2 mgb-10 mgt-24">
                    <div class="anotherSelecgt flex align-item-center">
                      <a-select
                        placeholder="选择服务"
                        v-model="form.rule_service_type"
                        style="width: 180px"
                        :allowClear="true"
                        :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                        class="mgr-16"
                      >
                        <a-select-option
                          value="4"
                          key="4"
                          @click="loadProduct2([5]);"
                        >
                          选择服务
                        </a-select-option>
                        <a-select-option
                          value="2"
                          key="2"
                          @click="step2data.types2='2'"
                        >
                          选择分类
                        </a-select-option>
                        <a-select-option
                          value="3"
                          key="3"
                        >
                          选择品牌
                        </a-select-option>
                      </a-select>
                      <a-select
                        v-if="form.rule_service_type==2"
                        placeholder="选择分类"
                        v-model="step2data.types2"
                        style="width: 180px"
                        :allowClear="true"
                        :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                        class="mgr-16 "
                      >
                        <a-select-option
                          value="2"
                          key="2"
                        >
                          财务分类
                        </a-select-option>
                        <a-select-option
                          value="6"
                          key="6"
                        >
                          门店分类
                        </a-select-option>
                        <a-select-option
                          value="5"
                          key="5"
                        >
                          商城分类
                        </a-select-option>
                      </a-select>
                    </div>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入服务名称"
                      v-if="form.rule_service_type==4"
                      @change="changeentityOptions_2"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入分类"
                      v-model="step2data.searchValue_2"
                      v-if="form.rule_service_type==2 && step2data.types2==2"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入分类"
                      v-model="step2data.searchValue2_2"
                      v-if="form.rule_service_type==2 && step2data.types2==6"
                      @change="changeentityOptions3_2"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入分类"
                      v-model="step2data.searchValue3_2"
                      v-if="form.rule_service_type==2 && step2data.types2==5"
                      @change="changeentityOptions4_2"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入品牌"
                      v-model="step2data.searchValueBrands_2"
                      v-if="form.rule_service_type==3"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>
                  </div>
                  <!-- 3个 穿梭框  产品/服务/方案2  分类3  品牌4-->
                  <a-transfer
                    v-if="form.rule_service_type==4"
                    :data-source="step2data.mockData2"
                    :target-keys="step2data.targetKeys_2"
                    :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
                    :show-select-all="false"
                    @change="event=> onlimitsChange2(event,1)"
                    class="ant-transfer_1 place100"
                  >
                    <template
                      slot="children"
                      slot-scope="{
                            props: { direction, filteredItems, selectedKeys, disabled: listDisabled },
                            on: { itemSelectAll, itemSelect },
                          }"
                    >
                      <a-table
                        :pagination="false"
                        :row-selection="
                            getRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })"
                        :columns="direction === 'left' ? step2data.leftColumns2 : step2data.rightColumns2"
                        :data-source="filteredItems"
                        :scroll="{y:522}"
                        size="small"
                      >

                        <template
                          slot="titles"
                          slot-scope="action, record"
                        >
                          <a-tooltip>
                            <template slot="title">
                              <div class="">{{ record.title }}</div>
                            </template>
                            <div class="transferaPQ">{{ record.title }}</div>
                          </a-tooltip>
                          <a-tooltip>
                            <template slot="title">
                              <div class=" ">商品码：{{ record.goods_bn }}</div>
                            </template>
                            <div class="transferaPQ2">商品码：{{ record.goods_bn }}</div>
                          </a-tooltip>
                        </template>
                        <template
                          slot="finance_category_name"
                          slot-scope="action, record"
                        >
                          <a-tooltip>
                            <template slot="title">
                              <div class=" ">
                                {{ record.finance_category_name!='-'?record.finance_category_name+',':'' }}
                                {{record.goods_category_name!='-'?record.goods_category_name+',':'' }}
                                {{record.store_category_name!='-'?record.store_category_name+',':''  }}
                              </div>
                            </template>
                            <div
                              class="blockTypes "
                              style="margin-left:12px;"
                            >
                              {{ record.finance_category_name!='-'?record.finance_category_name+',':'' }}
                              {{record.goods_category_name!='-'?record.goods_category_name+',':'' }}
                              {{record.store_category_name!='-'?record.store_category_name+',':''  }}
                            </div>
                          </a-tooltip>
                        </template>
                        <template
                          slot="goods_type"
                          slot-scope="action, record"
                        >
                          <span style="margin-left:12px;">
                            {{ record.goods_type=='1'?'客装':record.goods_type=='2'?
                                '店装': record.goods_type=='3'?"客装":'店装' 
                                }}
                          </span>
                        </template>
                      </a-table>
                    </template>
                  </a-transfer>
                  <!--财务 分类 -->
                  <a-transfer
                    v-if="form.rule_service_type==2 && step2data.types2==2"
                    class="tree-transfer tree-transfer1"
                    :data-source="step2data.mockDataTypes"
                    :target-keys="step2data.targetKeysTypes_2"
                    :render="item => item.title"
                    :show-select-all="false"
                    @change="event=> onlimitsChange2(event,2)"
                    :titles="['分类名称','分类名称']"
                    :rowKey="record => record.key"
                  >
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
                    >
                      <a-tree
                        v-if="direction === 'left'"
                        blockNode
                        checkable
                        checkStrictly
                        defaultExpandAll
                        :checkedKeys="[...selectedKeys, ...step2data.targetKeysTypes_2]"
                        :treeData="product_types_tree_2copy"
                        @check="  
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes_2], itemSelect);
                        }
                      "
                        @select="
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes_2], itemSelect);
                        }
                      "
                      >
                        <template
                          slot="title"
                          slot-scope="{ title }"
                        >
                          <span v-if="title.indexOf(step2data.searchValue_2) > -1">
                            {{ title.substr(0, title.indexOf(step2data.searchValue_2)) }}
                            <span style="color: #00B2A8">{{ step2data.searchValue_2 }}</span>
                            {{ title.substr(title.indexOf(step2data.searchValue_2) + step2data.searchValue_2.length) }}
                          </span>
                          <span v-else>{{ title }}</span>
                        </template>
                      </a-tree>
                    </template>
                    <!--  product_types_tree -->
                  </a-transfer>
                  <!--门店 分类 -->
                  <a-transfer
                    class="tree-transfer "
                    :target-keys="step2data.targetKeysTypes2_2"
                    :render="item => item.title"
                    v-if="form.rule_service_type==2 && step2data.types2==6"
                    :data-source="step2data.mockDataTypes2_2"
                    :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
                    :show-select-all="false"
                    @change="event=> onlimitsChange2(event,2)"
                    :titles="['分类名称','分类名称']"
                  >
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
                    >
                      <a-tree
                        v-if="direction === 'left'"
                        blockNode
                        checkable
                        checkStrictly
                        defaultExpandAll
                        :expanded-keys="step2data.expandedKeys2_2"
                        :auto-expand-parent="step2data.autoExpandParent2_2"
                        :replace-fields="replaceFields2"
                        :checkedKeys="[...selectedKeys, ...step2data.targetKeysTypes2_2]"
                        :treeData="product_types_tree2_2copy"
                        @check="  
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes2_2], itemSelect);
                        }
                      "
                        @select="
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes2_2], itemSelect);
                        }
                      "
                        @expand=" (_, props) => {
                          onExpand_2(_, props, [...selectedKeys, ...step2data.targetKeysTypes2_2], itemSelect);
                        }"
                      >
                        <!-- -->
                        <template
                          slot="title"
                          slot-scope="{ title }"
                        >
                          <span v-if="title.indexOf(step2data.searchValue2_2) > -1">
                            {{ title.substr(0, title.indexOf(step2data.searchValue2_2)) }}
                            <span style="color: #00B2A8">{{ step2data.searchValue2_2 }}</span>
                            {{ title.substr(title.indexOf(step2data.searchValue2_2) + step2data.searchValue2_2.length) }}
                          </span>
                          <span v-else>{{ title }}</span>
                        </template>
                      </a-tree>
                    </template>
                  </a-transfer>
                  <!--商城 分类 -->
                  <a-transfer
                    class="tree-transfer "
                    :target-keys="step2data.targetKeysTypes3_2"
                    :render="item => item.title"
                    v-if="form.rule_service_type==2 && step2data.types2==5"
                    :data-source="step2data.mockDataTypes3_2"
                    :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
                    :show-select-all="false"
                    @change="event=> onlimitsChange2(event,2)"
                    :titles="['分类名称','分类名称']"
                    :rowKey="record => record.key"
                  >
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
                    >
                      <a-tree
                        v-if="direction === 'left'"
                        blockNode
                        checkable
                        checkStrictly
                        defaultExpandAll
                        :expanded-keys="step2data.expandedKeys3_2"
                        :auto-expand-parent="step2data.autoExpandParent3_2"
                        :replace-fields="replaceFields"
                        :checkedKeys="[...selectedKeys, ...step2data.targetKeysTypes3_2]"
                        :treeData="product_types_tree3_2copy"
                        @check="  
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes3_2], itemSelect);
                        }
                      "
                        @select="
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes3_2], itemSelect);
                        }
                      "
                        @expand=" (_, props) => {
                          onExpand2_2(_, props, [...selectedKeys, ...step2data.targetKeysTypes3_2], itemSelect);
                        }"
                      >
                        <template
                          slot="title"
                          slot-scope="{ title }"
                        >
                          <span v-if="title.indexOf(step2data.searchValue3_2) > -1">
                            {{ title.substr(0, title.indexOf(step2data.searchValue3_2)) }}
                            <span style="color: #00B2A8">{{ step2data.searchValue3_2 }}</span>
                            {{ title.substr(title.indexOf(step2data.searchValue3_2) + step2data.searchValue3_2.length) }}
                          </span>
                          <span v-else>{{ title }}</span>
                        </template>
                      </a-tree>
                    </template>
                  </a-transfer>
                  <!-- 品牌 穿梭 -->
                  <a-transfer
                    class="tree-transfer tree-transfer1"
                    :target-keys="step2data.targetKeysBrands_2"
                    :render="item => item.title"
                    v-if="form.rule_service_type==3"
                    :data-source="step2data.mockDataBrands"
                    :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
                    :show-select-all="false"
                    @change="event=> onlimitsChange2(event,3)"
                    :titles="['品牌名称','品牌名称']"
                    :rowKey="record => record.key"
                  >
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
                    >
                      <a-tree
                        v-if="direction === 'left'"
                        blockNode
                        checkable
                        checkStrictly
                        defaultExpandAll
                        :checkedKeys="[...selectedKeys, ...step2data.targetKeysBrands_2]"
                        :treeData="product_types_tree4_2"
                        @check="  
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysBrands_2], itemSelect);
                        }
                      "
                        @select="
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysBrands_2], itemSelect);
                        }
                      "
                      >
                        <template
                          slot="title"
                          slot-scope="{ title }"
                        >
                          <span v-if="title.indexOf(step2data.searchValueBrands_2) > -1">
                            {{ title.substr(0, title.indexOf(step2data.searchValueBrands_2)) }}
                            <span style="color: #00B2A8">{{ step2data.searchValueBrands_2 }}</span>
                            {{ title.substr(title.indexOf(step2data.searchValueBrands_2) + step2data.searchValueBrands_2.length) }}
                          </span>
                          <span v-else>{{ title }}</span>
                        </template>
                      </a-tree>
                    </template>
                  </a-transfer>
                </div>
              </div>

              <!-- 适用方案 *  -->
              <div class="prostep2h flex align-item-center">
                <i
                  @click=";step2data.isfold3=!step2data.isfold3"
                  v-if="!step2data.isfold3"
                  class="meiye-icon meiye-xiangyou   font-18"
                ></i>
                <i
                  v-else
                  @click=";step2data.isfold3=!step2data.isfold3"
                  class="meiye-icon meiye-xiangxia  meiye-jiantou2 color11  font-18"
                ></i>
                <span class="sypro mgr-24">适用方案
                  <span
                    class="xing"
                    style="color:#F35E67"
                  >*</span>
                </span>
                <a-switch
                  class="mgr-6"
                  default-checked
                  :checked="step2data.changepro3"
                  @change=";step2data.changepro3=!step2data.changepro3;changepopways3(step2data.changepro3)"
                />
                <span>{{step2data.changepro3?'适用':'不适用'}}</span>
              </div>
              <!-- 是否全部 -->
              <div
                class="isprosBox"
                v-if="step2data.isfold3"
              >
                <div
                  class="ispros"
                  v-if="step2data.changepro3 && step2data.isfold3"
                >
                  <div class=" ">
                    <a-radio-group
                      v-model="step2data.allgoodsorsingle3"
                      class="lable_radiogroup"
                      style="width:240px;"
                    >
                      <a-radio-button
                        value="1"
                        style="width:120px;text-align: center;"
                        @click="form.rule_proposal_type='1'"
                      >
                        全部方案
                      </a-radio-button>
                      <a-radio-button
                        value="2"
                        style="width:120px;text-align: center;"
                        @click="loadProduct3([8,9]);form.rule_proposal_type='4'"
                      >
                        部分方案
                      </a-radio-button>
                    </a-radio-group>
                  </div>
                </div>
                <!-- 选择产品  选择产品2 选择分类3 选择品牌4 -->
                <!-- 选择服务  选择服务 选择分类 选择品牌 -->
                <!-- 选择分类  选择分类 选择分类 选择品牌 -->
                <div
                  class="step2Choose"
                  v-if="step2data.changepro3 && step2data.allgoodsorsingle3==2"
                >
                  <!-- 选择框 -->
                  <div class="selectstep2 mgb-10 mgt-24">
                    <div class="anotherSelecgt flex align-item-center">
                      <a-select
                        placeholder="选择方案"
                        v-model="form.rule_proposal_type"
                        style="width: 180px"
                        :allowClear="true"
                        :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                        class="mgr-16"
                      >
                        <a-select-option
                          value="4"
                          key="4"
                          @click="loadProduct3([8,9]);"
                        >
                          选择卡项
                        </a-select-option>
                        <a-select-option
                          value="2"
                          key="2"
                          @click="step2data.types3='2'"
                        >
                          选择分类
                        </a-select-option>
                        <a-select-option
                          value="3"
                          key="3"
                        >
                          选择品牌
                        </a-select-option>
                      </a-select>
                      <a-select
                        v-if="form.rule_proposal_type==2"
                        placeholder="选择分类"
                        v-model="step2data.types3"
                        style="width: 180px"
                        :allowClear="true"
                        :getPopupContainer="(triggerNode) => (triggerNode.parentElement)"
                        class="mgr-16"
                      >
                        <a-select-option
                          value="2"
                          key="2"
                          @click="product_types_tree1 = treeData;"
                        >
                          财务分类
                        </a-select-option>
                        <a-select-option
                          value="6"
                          key="6"
                          @click="product_types_tree1 = treeData2;"
                        >
                          门店分类
                        </a-select-option>
                        <a-select-option
                          value="5"
                          key="5"
                          @click="product_types_tree1 = treeData3;"
                        >
                          商城分类
                        </a-select-option>
                      </a-select>
                    </div>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入卡项名称"
                      v-if="form.rule_proposal_type==4"
                      @change="changeentityOptions_3"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入分类"
                      v-model="step2data.searchValue_3"
                      v-if="form.rule_proposal_type==2 && step2data.types3==2"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入分类"
                      v-model="step2data.searchValue2_3"
                      v-if="form.rule_proposal_type==2 && step2data.types3==6"
                      @change="changeentityOptions3_3"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入分类"
                      v-model="step2data.searchValue3_3"
                      v-if="form.rule_proposal_type==2 && step2data.types3==5"
                      @change="changeentityOptions4_3"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>
                    <a-input
                      class="ant-input_2 float-r wid-240"
                      placeholder="请输入品牌"
                      v-model="step2data.searchValueBrands_3"
                      v-if="form.rule_proposal_type==3"
                    >
                      <i
                        slot="prefix"
                        class="meiye-icon meiye-sousuo pointer"
                      ></i>
                    </a-input>

                  </div>
                  <!-- 3个 穿梭框  产品/服务/方案2  分类3  品牌4-->
                  <a-transfer
                    v-if="form.rule_proposal_type==4"
                    :data-source="step2data.mockData3"
                    :target-keys="step2data.targetKeys_3"
                    :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
                    :show-select-all="false"
                    @change="event=> onlimitsChange3(event,1)"
                    class="ant-transfer_1"
                  >
                    <template
                      slot="children"
                      slot-scope="{
                            props: { direction, filteredItems, selectedKeys, disabled: listDisabled },
                            on: { itemSelectAll, itemSelect },
                          }"
                    >
                      <a-table
                        :pagination="false"
                        :row-selection="
                            getRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })"
                        :columns="direction === 'left' ? step2data.leftColumns3 : step2data.rightColumns3"
                        :data-source="filteredItems"
                        :scroll="{y:522}"
                        size="small"
                      >

                        <template
                          slot="titles"
                          slot-scope="action, record"
                        >
                          <a-tooltip>
                            <template slot="title">
                              <div class="">{{ record.title }}</div>
                            </template>
                            <div class="transferaPQ">{{ record.title }}</div>
                          </a-tooltip>
                          <a-tooltip>
                            <template slot="title">
                              <div class=" ">商品码：{{ record.goods_bn }}</div>
                            </template>
                            <div class="transferaPQ2">商品码：{{ record.goods_bn }}</div>
                          </a-tooltip>
                        </template>
                        <template
                          slot="finance_category_name"
                          slot-scope="action, record"
                        >
                          <a-tooltip>
                            <template slot="title">
                              <div class=" ">
                                {{ record.finance_category_name!='-'?record.finance_category_name+',':'' }}
                                {{record.goods_category_name!='-'?record.goods_category_name+',':'' }}
                                {{record.store_category_name!='-'?record.store_category_name+',':''  }}
                              </div>
                            </template>
                            <div
                              class="blockTypes "
                              style="margin-left:12px;"
                            >
                              {{ record.finance_category_name!='-'?record.finance_category_name+',':'' }}
                              {{record.goods_category_name!='-'?record.goods_category_name+',':'' }}
                              {{record.store_category_name!='-'?record.store_category_name+',':''  }}
                            </div>
                          </a-tooltip>
                        </template>
                        <template
                          slot="goods_type"
                          slot-scope="action, record"
                        >
                          <span style="margin-left:12px;">
                            {{ record.goods_type=='1'?'客装':record.goods_type=='2'?
                                '店装': record.goods_type=='3'?"客装":'店装' 
                                }}
                          </span>
                        </template>
                      </a-table>
                    </template>
                  </a-transfer>
                  <!--财务 分类 -->
                  <a-transfer
                    v-if="form.rule_proposal_type==2 && step2data.types3==2"
                    class="tree-transfer tree-transfer1"
                    :data-source="step2data.mockDataTypes"
                    :target-keys="step2data.targetKeysTypes_3"
                    :render="item => item.title"
                    :show-select-all="false"
                    @change="event=> onlimitsChange3(event,2)"
                    :titles="['分类名称','分类名称']"
                    :rowKey="record => record.key"
                  >
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
                    >
                      <a-tree
                        v-if="direction === 'left'"
                        blockNode
                        checkable
                        checkStrictly
                        defaultExpandAll
                        :checkedKeys="[...selectedKeys, ...step2data.targetKeysTypes_3]"
                        :treeData="product_types_tree_3"
                        @check="  
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes_3], itemSelect);
                        }
                      "
                        @select="
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes_3], itemSelect);
                        }
                      "
                      >
                        <template
                          slot="title"
                          slot-scope="{ title }"
                        >
                          <span v-if="title.indexOf(step2data.searchValue_3) > -1">
                            {{ title.substr(0, title.indexOf(step2data.searchValue_3)) }}
                            <span style="color: #00B2A8">{{ step2data.searchValue_3 }}</span>
                            {{ title.substr(title.indexOf(step2data.searchValue_3) + step2data.searchValue_3.length) }}
                          </span>
                          <span v-else>{{ title }}</span>
                        </template>
                      </a-tree>
                    </template>
                    <!--  product_types_tree -->
                  </a-transfer>
                  <!--门店 分类 -->
                  <a-transfer
                    class="tree-transfer "
                    :target-keys="step2data.targetKeysTypes2_3"
                    :render="item => item.title"
                    v-if="form.rule_proposal_type==2 && step2data.types3==6"
                    :data-source="step2data.mockDataTypes2_3"
                    :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
                    :show-select-all="false"
                    @change="event=> onlimitsChange3(event,2)"
                    :titles="['分类名称','分类名称']"
                    :rowKey="record => record.key"
                  >
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
                    >
                      <a-tree
                        v-if="direction === 'left'"
                        blockNode
                        checkable
                        checkStrictly
                        defaultExpandAll
                        :expanded-keys="step2data.expandedKeys2_3"
                        :auto-expand-parent="step2data.autoExpandParent2_3"
                        :replace-fields="replaceFields2"
                        :checkedKeys="[...selectedKeys, ...step2data.targetKeysTypes2_3]"
                        :treeData="product_types_tree2_3copy"
                        @check="  
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes2_3], itemSelect);
                        }
                      "
                        @select="
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes2_3], itemSelect);
                        }
                      "
                        @expand=" (_, props) => {
                          onExpand_3(_, props, [...selectedKeys, ...step2data.targetKeysTypes2_3], itemSelect);
                        }"
                      >
                        <!-- -->
                        <template
                          slot="title"
                          slot-scope="{ title }"
                        >
                          <span v-if="title.indexOf(step2data.searchValue2_3) > -1">
                            {{ title.substr(0, title.indexOf(step2data.searchValue2_3)) }}
                            <span style="color: #00B2A8">{{ step2data.searchValue2_3 }}</span>
                            {{ title.substr(title.indexOf(step2data.searchValue2_3) + step2data.searchValue2_3.length) }}
                          </span>
                          <span v-else>{{ title }}</span>
                        </template>
                      </a-tree>
                    </template>
                  </a-transfer>
                  <!--商城 分类 -->
                  <a-transfer
                    class="tree-transfer "
                    :target-keys="step2data.targetKeysTypes3_3"
                    :render="item => item.title"
                    v-if="form.rule_proposal_type==2 && step2data.types3==5"
                    :data-source="step2data.mockDataTypes3_3"
                    :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
                    :show-select-all="false"
                    @change="event=> onlimitsChange3(event,2)"
                    :titles="['分类名称','分类名称']"
                    :rowKey="record => record.key"
                  >
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
                    >
                      <a-tree
                        v-if="direction === 'left'"
                        blockNode
                        checkable
                        checkStrictly
                        defaultExpandAll
                        :expanded-keys="step2data.expandedKeys3_3"
                        :auto-expand-parent="step2data.autoExpandParent3_3"
                        :replace-fields="replaceFields"
                        :checkedKeys="[...selectedKeys, ...step2data.targetKeysTypes3_3]"
                        :treeData="product_types_tree3_3copy"
                        @check="  
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes3_3], itemSelect);
                        }
                      "
                        @select="
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysTypes3_3], itemSelect);
                        }
                      "
                        @expand=" (_, props) => {
                          onExpand2_3(_, props, [...selectedKeys, ...step2data.targetKeysTypes3_3], itemSelect);
                        }"
                      >
                        <template
                          slot="title"
                          slot-scope="{ title }"
                        >
                          <span v-if="title.indexOf(step2data.searchValue3_3) > -1">
                            {{ title.substr(0, title.indexOf(step2data.searchValue3_3)) }}
                            <span style="color: #00B2A8">{{ step2data.searchValue3_3 }}</span>
                            {{ title.substr(title.indexOf(step2data.searchValue3_3) + step2data.searchValue3_3.length) }}
                          </span>
                          <span v-else>{{ title }}</span>
                        </template>
                      </a-tree>
                    </template>
                  </a-transfer>
                  <!-- 品牌 穿梭 -->
                  <a-transfer
                    class="tree-transfer tree-transfer1"
                    :target-keys="step2data.targetKeysBrands_3"
                    :render="item => item.title"
                    v-if="form.rule_proposal_type==3"
                    :data-source="step2data.mockDataBrands"
                    :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
                    :show-select-all="false"
                    @change="event=> onlimitsChange3(event,3)"
                    :titles="['品牌名称','品牌名称']"
                    :rowKey="record => record.key"
                  >
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
                    >
                      <a-tree
                        v-if="direction === 'left'"
                        blockNode
                        checkable
                        checkStrictly
                        defaultExpandAll
                        :checkedKeys="[...selectedKeys, ...step2data.targetKeysBrands_3]"
                        :treeData="product_types_tree4_3"
                        @check="  
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysBrands_3], itemSelect);
                        }
                      "
                        @select="
                        (_, props) => {
                          onChecked(_, props, [...selectedKeys, ...step2data.targetKeysBrands_3], itemSelect);
                        }
                      "
                      >
                        <template
                          slot="title"
                          slot-scope="{ title }"
                        >
                          <span v-if="title.indexOf(step2data.searchValueBrands_3) > -1">
                            {{ title.substr(0, title.indexOf(step2data.searchValueBrands_3)) }}
                            <span style="color: #00B2A8">{{ step2data.searchValueBrands_3 }}</span>
                            {{ title.substr(title.indexOf(step2data.searchValueBrands_3) + step2data.searchValueBrands_3.length) }}
                          </span>
                          <span v-else>{{ title }}</span>
                        </template>
                      </a-tree>
                    </template>
                  </a-transfer>
                </div>
              </div>

            </div>

            <div class="last-btns last-btns-step2">
              <a-row
                class="flex "
                style="margin-bottom:42px;"
              >
                <a-button
                  type="primary"
                  @click="onStep(3)"
                  class="stepbtnnext1 bigheightbn wid-80 mgr-20 mgl-28"
                >下一步</a-button>
                <a-button
                  class="stepbackbtn bigheightbn wid-80  mgr-20"
                  @click=";step = 1;"
                >上一步</a-button>
                <a-button
                  class="stepbackbtn bigheightbn wid-80"
                  @click="cancel()"
                >取消</a-button>

              </a-row>

            </div>
          </div>
        </div>
        <!-- 第三步 -->
        <div
          class="three"
          v-if="step===3"
        >
          <div class="zengpinstep">
            <h2 class="twoh2 mgb-32">
              <span class="tpointer"></span>
              <span class="ttxt">其他配置</span>
            </h2>
            <div class=" ">
              <a-row
                class="flex "
                style="margin-bottom:42px;"
              >
                <a-col :span="4">
                  <div
                    class="textColor1"
                    style="margin-left: 14px;font-weight: 500;color: rgba(45,56,53,0.8);"
                  >轮播图 </div>
                </a-col>
                <a-col :span="19">
                  <div class=" ">
                    <upload-image
                      @getImageId="getDelImageId"
                      @delimageId="delDelimageId"
                      @getImageshow="getImageshow"
                      :multiple="5"
                      :img="form.goods_slider"
                      @draggable="handleDraggable"
                      ref="uploadCom"
                    ></upload-image>
                  </div>
                </a-col>
              </a-row>
              <a-row
                class="flex "
                style="margin-bottom:42px;"
              >
                <a-col :span="4">
                  <div
                    class="textColor1"
                    style="margin-left: 14px;font-weight: 500;color: rgba(45,56,53,0.8);"
                  >卡面 </div>
                </a-col>
                <a-col
                  :span="19"
                  class="flex"
                >
                  <div
                    class="span_toutu"
                    style="width:164px;"
                  >
                    <a-upload
                      name="avatar"
                      list-type="picture-card"
                      class="avatar-uploader2"
                      :show-upload-list="false"
                      :action="baseUrl+'/upload'"
                      :headers="headers"
                      :before-upload="beforeUpload"
                      :customRequest="ffuploadFiles"
                      @change="handleChange2"
                      style=""
                    >
                      <span
                        v-if="form.goods_image"
                        class="boldimgspan"
                      >
                        <i
                          @click.stop="form.goods_image='';permissionInfo.pic_url_loading=false"
                          class="meiye-icon meiye-close-bold meiye-close-boldimg"
                        ></i>
                        <img
                          :src="form.goods_image"
                          alt="avatar"
                          style="width:48px;height:48px;"
                        />
                      </span>

                      <div v-else>
                        <a-icon :type="permissionInfo.pic_url_loading ? 'loading' : 'plus'" />
                      </div>
                    </a-upload>
                    <span style="font-size: 12px;font-weight: 400;color: #9EA6A4;">建议尺寸750*750px</span>
                  </div>
                  <div class="desc_intor flex">
                    <span style="margin-right:36px;display:inline-block;font-size: 14px;font-weight: 400;color: rgba(45,56,53,0.8);">描述:</span>
                    <a-textarea
                      class="ant-input_1"
                      style="width: 365px;height: 72px;resize: none;"
                      v-model="form.goods_desc"
                      placeholder="请输入不超过200字描述"
                      :maxLength="200"
                      :auto-size="{ minRows: 3, maxRows: 5 }"
                    />
                  </div>

                </a-col>
              </a-row>
              <a-row
                class="flex "
                style="margin-bottom:40px;"
              >
                <a-col :span="4">
                  <div
                    class="textColor1"
                    style="margin-left: 14px;font-weight: 500;color: rgba(45,56,53,0.8);"
                  >储值卡详情 </div>
                </a-col>
                <a-col
                  :span="19"
                  class="flex"
                >
                  <shop-editor
                    class="goods_editor"
                    @transfer="getUser"
                    v-model="form.goods_content"
                  >
                  </shop-editor>
                </a-col>
              </a-row>
            </div>
            <div class="last-btns">
              <a-row
                class="flex "
                style="margin-bottom:42px;"
              >
                <a-col :span="4">

                </a-col>
                <a-col :span="19">
                  <a-space :size="20">
                    <a-button
                      type="primary"
                      @click="okclickmod()"
                      class="stepbtnnext1 bigheightbn wid-80"
                    >保存</a-button>
                    <a-button
                      class="stepbackbtn bigheightbn wid-80"
                      @click="step = 2"
                    >上一步</a-button>
                    <a-button
                      class="stepbackbtn bigheightbn wid-80"
                      @click="cancel()"
                    >取消</a-button>
                  </a-space>
                </a-col>
              </a-row>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>
<script>
import moment from 'moment';
import config from '../config';
import { pinyin } from 'pinyin-pro';
import {
  GetmanageGoodslist,
  GetgoodsoptionsList, //门店分类
  TreetypeFinanceList,//财务分类
  TreetypeMallList,//商城分类 
  CreatecardsProposal, UpdatcardsProposal, cardsInfoProposal, GetgoodsbrandsoptionsList
} from '@/api/commodity'
import UploadImage from '../modules/drag'
import ShopEditor from '../modules/wangeditor'
import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
import axios from 'axios'
import mockData1 from '../modules/mockdata'
import ComposeInput from '@/components/compose_input'
import difference from 'lodash/difference';
// const treeData_barnds = mockData1.mockdata 

const product_types_tree = [
  { key: '0-0', title: '0-0' },
  {
    key: '0-1',
    title: '0-1',
    children: [
      { key: '0-1-0', title: '0-1-0' },
      { key: '0-1-1', title: '0-1-1' },
    ],
  },
  { key: '0-2', title: '0-3' },
]
const transferDataSource = [];


function flatten(list = []) {
  list.forEach(item => {
    transferDataSource.push(
      item
    );
    flatten(item.children);
  });
}

function handleTreeData(data, targetKeys = []) {

  data.forEach(item => {
    item['disabled'] = targetKeys.includes(item.key);
    if (item.children) {
      handleTreeData(item.children, targetKeys);
    }
  });
  //  
  console.log(data, targetKeys);
  return data;
}

function isChecked(selectedKeys, eventKey) {
  return selectedKeys.indexOf(eventKey) !== -1;
}
const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
}
const leftTableColumns = [
  {
    dataIndex: 'titles',
    title: '产品名称',
    // key: 'titles',
    ellipsis: true,
    width: 130,
    scopedSlots: { customRender: 'titles' },
  },
  // {
  //   dataIndex: 'goods_bn',
  //   ellipsis: true,
  //   title: '商品码',
  // },
  {
    dataIndex: 'goods_unit',
    ellipsis: true,
    width: 80,
    title: '标准单价',
    scopedSlots: { customRender: 'goods_unit' },
  },
  {
    dataIndex: 'finance_category_name',
    title: '类别',
    width: 80,
    ellipsis: true,
    scopedSlots: { customRender: 'finance_category_name' },
  },
  {
    dataIndex: 'goods_type',
    title: '分类',
    width: 80,
    scopedSlots: { customRender: 'goods_type' },
  },
  {
    dataIndex: 'brand_name',
    ellipsis: true,
    width: 80,
    title: '品牌',
  },
];
const rightTableColumns = [
  {
    dataIndex: 'titles',
    title: '产品名称',
    width: 130,
    scopedSlots: { customRender: 'titles' },
  },
  // {
  //   dataIndex: 'goods_bn',
  //   ellipsis: true,
  //   title: '商品码',
  // },
  {
    dataIndex: 'goods_unit',
    ellipsis: true,
    title: '标准单价',
    width: 80,
  },
  {
    dataIndex: 'finance_category_name',
    ellipsis: true,
    title: '类别',
    width: 80,
    scopedSlots: { customRender: 'finance_category_name' },
  },
  {
    dataIndex: 'goods_type',
    title: '分类',
    width: 80,
    ellipsis: true,
    scopedSlots: { customRender: 'goods_type' },
  },
  {
    dataIndex: 'brand_name',
    ellipsis: true,
    width: 80,
    title: '品牌',
  },
];
const leftTableColumns2 = [
  {
    dataIndex: 'titles',
    title: '服务名称',
    // key: 'titles',
    ellipsis: true,
    width: 130,
    scopedSlots: { customRender: 'titles' },
  },
  // {
  //   dataIndex: 'goods_bn',
  //   ellipsis: true,
  //   title: '商品码',
  // },
  {
    dataIndex: 'goods_unit',
    ellipsis: true,
    width: 80,
    title: '标准单价',
    scopedSlots: { customRender: 'goods_unit' },
  },
  {
    dataIndex: 'finance_category_name',
    title: '类别',
    width: 80,
    ellipsis: true,
    scopedSlots: { customRender: 'finance_category_name' },
  },
  {
    dataIndex: 'goods_type',
    title: '分类',
    width: 80,
    scopedSlots: { customRender: 'goods_type' },
  },
  {
    dataIndex: 'brand_name',
    ellipsis: true,
    width: 80,
    title: '品牌',
  },
];
const rightTableColumns2 = [
  {
    dataIndex: 'titles',
    title: '服务名称',
    width: 130,
    scopedSlots: { customRender: 'titles' },
  },
  // {
  //   dataIndex: 'goods_bn',
  //   ellipsis: true,
  //   title: '商品码',
  // },
  {
    dataIndex: 'goods_unit',
    ellipsis: true,
    title: '标准单价',
    width: 80,
  },
  {
    dataIndex: 'finance_category_name',
    ellipsis: true,
    title: '类别',
    width: 80,
    scopedSlots: { customRender: 'finance_category_name' },
  },
  {
    dataIndex: 'goods_type',
    title: '分类',
    width: 80,
    ellipsis: true,
    scopedSlots: { customRender: 'goods_type' },
  },
  {
    dataIndex: 'brand_name',
    ellipsis: true,
    width: 80,
    title: '品牌',
  },
];
const leftTableColumns3 = [
  {
    dataIndex: 'titles',
    title: '卡项名称',
    // key: 'titles',
    ellipsis: true,
    width: 130,
    scopedSlots: { customRender: 'titles' },
  },
  // {
  //   dataIndex: 'goods_bn',
  //   ellipsis: true,
  //   title: '商品码',
  // },
  {
    dataIndex: 'goods_unit',
    ellipsis: true,
    width: 80,
    title: '标准单价',
    scopedSlots: { customRender: 'goods_unit' },
  },
  {
    dataIndex: 'finance_category_name',
    title: '类别',
    width: 80,
    ellipsis: true,
    scopedSlots: { customRender: 'finance_category_name' },
  },
  {
    dataIndex: 'goods_type',
    title: '分类',
    width: 80,
    scopedSlots: { customRender: 'goods_type' },
  },
  {
    dataIndex: 'brand_name',
    ellipsis: true,
    width: 80,
    title: '品牌',
  },
];
const rightTableColumns3 = [
  {
    dataIndex: 'titles',
    title: '卡项名称',
    width: 130,
    scopedSlots: { customRender: 'titles' },
  },
  // {
  //   dataIndex: 'goods_bn',
  //   ellipsis: true,
  //   title: '商品码',
  // },
  {
    dataIndex: 'goods_unit',
    ellipsis: true,
    title: '标准单价',
    width: 80,
  },
  {
    dataIndex: 'finance_category_name',
    ellipsis: true,
    title: '类别',
    width: 80,
    scopedSlots: { customRender: 'finance_category_name' },
  },
  {
    dataIndex: 'goods_type',
    title: '分类',
    width: 80,
    ellipsis: true,
    scopedSlots: { customRender: 'goods_type' },
  },
  {
    dataIndex: 'brand_name',
    ellipsis: true,
    width: 80,
    title: '品牌',
  },
];
export default {
  inject: ['reload'],
  components: { UploadImage, ShopEditor, ComposeInput },
  data() {
    return {
      config,
      setId: '',
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        "Authorization": localStorage.getItem("token"),
        "Content-Type": 'multipart/form-data'
      },
      treeData: [],
      treeData1: [],
      treeData2: [],
      treeData3: [],
      treeDataA: [],
      treeDataA1: [],
      treeDataA2: [],
      treeDataA3: [],
      selectreeObj: {
        children: 'children',
        label: 'category_name',
        name: 'category_name',
        title: 'category_name',
        key: 'category_id',
        value: 'category_id'
      },
      selectreeObj2: {
        children: 'children',
        label: 'category_name',
        name: 'category_name',
        title: 'category_name',
        key: 'finance_category_id',
        value: 'finance_category_id'
      },
      selectreeObj3: {
        children: 'children',
        label: 'category_name',
        name: 'category_name',
        title: 'category_name',
        key: 'store_category_id',
        value: 'store_category_id'
      },
      brandsList: [],//品牌列表
      typesList: [
        { name: '客装', id: '1' },
        { name: '院装', id: '2' },
      ],//类别
      isactive: false,
      ischangeGoodsSpecs1: true, //是否显示下拉框所属的规格
      showaddGoodsprivatespec: false,//自定义新增规格弹框
      singelguigeadd: {
        attr_name: '',
        attr_values: [],
      },
      isShowGuigeAddSelect: true,
      form: {//表单数据   
        goods_image: undefined,
        goods_bn: undefined,
        goods_attr_val: [{ product_price: '', product_bn: '' }],
        goods_supplier: 1,
        goods_brand: undefined,
        goods_type: '7',
        store_category_id: undefined,
        goods_category: undefined,
        finance_category_id: undefined,
        goods_shou_type: "1",
        goods_shou_end_time: null,
        unit_price: undefined,
        face_value: undefined,//
        is_edit_face_value: 1,
        rule_entity_type: '1',  //0不适用、1适用全部实物、4指定分类下实物、3指定品牌 
        rule_service_type: '1',
        rule_proposal_type: '1',
        entity_ids: [],//实物限制对应ID
        service_ids: [],//服务限制对应ID
        proposal_ids: [],//方案限制对应ID
      },
      is_edit_face_value: true,
      step: 1,
      imgflag: false,
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        isDisabled: false,
        info: {
          permission_id_list: [],
          goods_type: undefined,//商品类型
          goods_category: undefined,//商品分类
          goods_brand: undefined,//商品品牌
          goods_bn: undefined,
          goods_id: undefined,
          goods_name: undefined,
          goods_supplier: 1,
          goods_unit: undefined,// 单位
          is_show: 1,// 商品状态
          is_del: 0,//是否删除
          goods_image: undefined,
          goods_slider: undefined,//轮播图
          goods_attr: [],//商品规格
          goods_attr_val: [],//商品规格值 
          goods_content: '',//商品描述
          goods_desc: undefined,//商品简介
        }
      },
      // 规格 modal
      consumable: {
        visible: false,
        productPage: 1,
        ztotal: undefined,
        title: '选择分类',//选择分类  选择品牌 选择服务 选择产品 选择方案
        value: '0',//0不适用 1全部 2分类 3品牌 4部分 
        value2: '0',//0不适用 1全部 2分类 3品牌 4部分 
        value3: '0',//0不适用 1全部 2分类 3品牌 4部分 
        type: '1',//1产品 2服务 3方案
        selectArr: [
          { name: '财务分类', id: '1' },
          { name: '门店分类', id: '2' },
          { name: '商城分类', id: '3' },
        ],
        originalmockData: undefined,
        num: '',//判断提交时候是单双规格的确定
        record: '',//暂存的规格列
        mockData: [], //产品 
        mockData2: [],//服务
        mockData3: [],//方案 
        targetKeys: [],
        targetKeys2: [],
        targetKeys3: [],
        treetypeValue: [],
        treetypeValue2: [],
        treetypeValue3: [],
        goods_brand: undefined,
        goods_brand2: undefined,
        goods_brand3: undefined,
        permission_id_list: [],
        permission_id_list2: [],
        permission_id_list3: [],
        selectedRowKeys: undefined,
        leftColumns: leftTableColumns,
        rightColumns: rightTableColumns,
        limitsreplaceFields: {
          key: "goods_id",
          title: 'title'
        },
        selectArr: [
          { name: '商品名称', id: '1' },
          { name: '商品码', id: '2' },
        ],
        transferShow: true,

      },
      typestyles3: {
        width: '500px',
        height: "38px"
      },
      selectArr: [
        { name: '商品名称', id: '1' },
        { name: '商品码', id: '2' },
      ],
      selectArrP: [
        { name: '产品名称', id: '1' },
        { name: '商品码', id: '2' },
      ],
      selectArr2: [
        { name: '财务分类', id: '1' },
        { name: '门店分类', id: '2' },
        { name: '商城分类', id: '3' },
      ],
      selectArr3: [
        { name: '机构简称', id: '1' },
        { name: '机构名称', id: '2' },
        { name: '机构代码', id: '3' },
      ],
      typestyles: {
        width: '334px',
        height: "32px"
      },
      typestyles2: {
        width: '506px',
        height: "32px"
      },
      // 适用产品
      goods_select: [
        { value: '不适用', key: '0' },
        { value: '全部产品', key: '1' },
        { value: '部分产品', key: '4' },
        { value: '部分分类', key: '2' },
        { value: '部分品牌', key: '3' },
      ],
      goods_obj: {
        openOrFold: false,//展开 关闭
        visible: false,//穿梭框显示影藏
        originalmockData: undefined,
        value: '0',//  0不适用 1全部 2分类 3品牌 4部分产品 
        record: '',//暂存的规格列
        mockData: [],
        targetKeys: ['1', '3', '16', '30'],
        leftColumns: leftTableColumns,
        rightColumns: rightTableColumns,
        limitsreplaceFields: {
          key: "id",
          title: 'title'
        },
      },
      // 适用服务
      service_select: [
        { value: '不适用', key: '0' },
        { value: '全部服务', key: '1' },
        { value: '部分服务', key: '4' },
        { value: '部分分类', key: '2' },
        { value: '部分品牌', key: '3' },
      ],
      service__obj: {
        openOrFold: false,//展开 关闭
        visible: false,//穿梭框显示影藏
        originalmockData: mockData1.mockdata,
        value: '0',//  0不适用 1全部 2分类 3品牌 4部分产品 
        record: '',//暂存的规格列
        mockData: [],
        targetKeys: ['1', '3', '16', '30'],
        leftColumns: leftTableColumns,
        rightColumns: rightTableColumns,
        limitsreplaceFields: {
          key: "id",
          title: 'title'
        },
      },
      // 适用方案
      plan_select: [
        { value: '不适用', key: '0' },
        { value: '全部方案', key: '1' },
        { value: '部分方案', key: "4" },
        { value: '部分分类', key: '2' },
        { value: '部分品牌', key: '3' },
      ],
      plan__obj: {
        openOrFold: false,//展开 关闭
        visible: false,//穿梭框显示影藏
        originalmockData: mockData1.mockdata,
        value: '0',//  0不适用 1全部 2分类 3品牌 4部分产品 
        record: '',//暂存的规格列
        mockData: [],
        targetKeys: ['1', '3', '16', '30'],
        leftColumns: leftTableColumns,
        rightColumns: rightTableColumns,
        limitsreplaceFields: {
          key: "id",
          title: 'title'
        },
      },
      // 分类选择框
      typesModel: {
        visible: false,
        originalmockData: [],
        mockData: [],
        targetKeys: [],
        leftColumns: leftTableColumns,
        rightColumns: rightTableColumns,
        dataSource: [],
      },
      replaceFields: {
        // key: "store_category_id",
        // title: "category_name",
        key: "key",
        title: "title",
        children: 'children'
      },
      replaceFields2: {
        // key: "category_id",
        // title: "category_name",
        key: "key",
        title: "title",
        children: 'children'
      },

      autoExpandParent: false,
      backupsExpandedKeys: [],
      titleList: [],//分类筛选名字 //财务 
      expandedKeys: [],
      selectedKeys: [],
      flitList: [
        { key: '类别', value: [], paramas: 'goods_type' },
        { key: '分类', value: [], paramas: 'goods_category' },
        { key: '状态', value: [], paramas: 'is_show' },
        { key: '品牌', value: [], paramas: 'goods_brand' }
      ],
      flitList2: [
        { key: '类别', value: [], paramas: 'goods_type' },
        { key: '分类', value: [], paramas: 'goods_category' },
        { key: '状态', value: [], paramas: 'is_show' },
        { key: '品牌', value: [], paramas: 'goods_brand' }
      ],
      flitList3: [
        { key: '类别', value: [], paramas: 'goods_type' },
        { key: '分类', value: [], paramas: 'goods_category' },
        { key: '状态', value: [], paramas: 'is_show' },
        { key: '品牌', value: [], paramas: 'goods_brand' }
      ],
      isAllLength: '',
      CheckSureObj: {
        checkedKeys: undefined,
        info: undefined,
        tabs: 1,//财务1 商城3 门店2
      },
      profuctTreeObj: {
        valueList: ['一级分类', "二级分类", "三级分类"],
        visible: false,
        visible2: false,
        visible3: false,
        active: 1,
      },
      searchValue: '',
      goods_specs: {
        isSingle: 1,//1  1单规格 2多规格
        barcode: undefined,//批量设置货品码
        price: undefined,//批量设置单价
        product_market_price: undefined,//批量市场价
        product_image: undefined,//批量设置图片
        ischooseModehow: true,//选择规格model
        goods_attr: [
          // { attr_name: '', attr_values: '' ,id:0}
        ],
        goods_specs_id: undefined,
        goods_attr_val: [
        ],
        goods_specs_list: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
          {
            attr_name: '新增规格', attr_values: [], id: 9999
          },
        ],
        goods_specs_list0: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
          {
            attr_name: '新增规格', attr_values: [], id: 9999
          },
        ],
        // 总共的 总规格list
        new_goods_specs_list: [
        ],
        goods_specs_values_list: [],
        goods_specs_keys_list: [],
        // select暂存的规格 和规格值 确认之后才push
        goods_specs_selectobj: {

        },
      },
      query: {
        store_category_id: undefined,
        goods_category: undefined,
        finance_category_id: undefined,
      },
      step2data: {
        allgoodsorsingle: '1',
        changepro: true,
        isfold: true,
        types: '2',
        mockData: [],//产品

        mockDataTypes: [],// 财务分类
        searchValue: '', //搜索框 

        mockDataTypes2: [],//门店分类
        searchValue2: '',//门店搜索框 
        expandedKeys: [],//
        autoExpandParent: false,

        mockDataTypes3: [],//商城分类
        searchValue3: '',//商城 搜索框
        expandedKeys2: [],
        autoExpandParent2: false,

        mockDataBrands: [],//品牌
        searchValueBrands: '',// 品牌搜索框

        targetKeys: [],     //产品 entity_ids rule_entity_type2
        targetKeysTypes: [],// 财务分类 entity_ids    rule_entity_type=3&& step2data.types=1 
        targetKeysTypes2: [],//门店分类 entity_ids    rule_entity_type=3&& step2data.types=2
        targetKeysTypes3: [],//商城分类 entity_ids    rule_entity_type=3&& step2data.types=3
        targetKeysBrands: [],//品牌分类 entity_ids    rule_entity_type=4
        leftColumns: leftTableColumns,
        rightColumns: rightTableColumns,
        leftColumns2: leftTableColumns2,
        rightColumns2: rightTableColumns2,
        leftColumns3: leftTableColumns3,
        rightColumns3: rightTableColumns3,
        productPage: 1,



        allgoodsorsingle2: '1',
        changepro2: true,
        isfold2: true,
        types2: '2',
        mockData2: [],//产品
        mockDataTypes_2: [],// 财务分类
        searchValue_2: '', //搜索框 

        mockDataTypes2_2: [],//门店分类
        searchValue2_2: '',//门店搜索框 
        expandedKeys2_2: [],//
        autoExpandParent2_2: false,

        mockDataTypes3_2: [],//商城分类
        searchValue3_2: '',//商城 搜索框
        expandedKeys3_2: [],
        autoExpandParent3_2: false,

        mockDataBrands_2: [],//品牌
        searchValueBrands_2: '',

        targetKeys_2: [],     //产品 entity_ids rule_entity_type2
        targetKeysTypes_2: [],// 财务分类 entity_ids    rule_entity_type=3&& step2data.types=1 
        targetKeysTypes2_2: [],//门店分类 entity_ids    rule_entity_type=3&& step2data.types=2
        targetKeysTypes3_2: [],//商城分类 entity_ids    rule_entity_type=3&& step2data.types=3
        targetKeysBrands_2: [],//品牌分类 entity_ids    rule_entity_type=4
        leftColumns_2: leftTableColumns,
        rightColumns_2: rightTableColumns,
        productPage_2: 1,



        allgoodsorsingle3: '1',
        changepro3: true,
        isfold3: true,
        types3: '2',
        mockData3: [],//产品
        mockDataTypes_3: [],// 财务分类
        searchValue_3: '', //搜索框 

        mockDataTypes2_3: [],//门店分类
        searchValue2_3: '',//门店搜索框 
        expandedKeys2_3: [],//
        autoExpandParent2_3: false,

        mockDataTypes3_3: [],//商城分类
        searchValue3_3: '',//商城 搜索框
        expandedKeys3_3: [],
        autoExpandParent3_3: false,

        mockDataBrands_3: [],//品牌
        searchValueBrands_3: '',

        targetKeys_3: [],     //产品 entity_ids rule_entity_type2
        targetKeysTypes_3: [],// 财务分类 entity_ids    rule_entity_type=3&& step2data.types=1 
        targetKeysTypes2_3: [],//门店分类 entity_ids    rule_entity_type=3&& step2data.types=2
        targetKeysTypes3_3: [],//商城分类 entity_ids    rule_entity_type=3&& step2data.types=3
        targetKeysBrands_3: [],//品牌分类 entity_ids    rule_entity_type=4
        leftColumns_3: leftTableColumns,
        rightColumns_3: rightTableColumns,
        productPage_3: 1,
      },
      targetKeys: [],//财务
      targetKeys2: [],//门店
      targetKeys3: [],//商城 
      totalChecked: [],//
      product_types_tree2: [],
      product_types_tree2_2: [],
      product_types_tree2_3: [],

      product_types_tree3: [],
      product_types_tree3_2: [],
      product_types_tree3_3: [],
      brandsListcopy: [],//品牌列表

    };
  },
  created() {
    this.getCompanyoptionList()
    this.getCompanyoptionList2()
    this.getCompanyoptionList3()
    this.getBrands()
    if (this.$route.query.type == 1) {
      this.addorupdateSpecFlag = 'add'
    }
    if (this.$route.query.type == 3) {
      this.addorupdateSpecFlag = 'update'
      this.openDatails()
    }

  },
  methods: {
    moment,
    flatten(list = []) {
      let _this = this
      if (list) {
        list.forEach(item => {
          _this.consumable.mockData.push({
            key: item.id.toString(),
            title: item.title ? item.title : 'we' + item.id,
            component: item.component ? item.component : 'component',
            pid: item.pid,
            disabled: false,
            description: 'xxx',
            icon: item.icon ? item.icon : 'meiye-routeicon-modular'
          });
          _this.flatten(item.children);
        });
      }
    },
    // 获取详情信息
    getPartnerDetail(id) {
      partnerDetail({ id: id }).then(res => {
        console.log(res.data, "data");
        let list_no = [];
        let list_yes = [];
        res.data.commission_rule.forEach(element => {
          if (element.product_type == 1) {
            list_no.push(element);
          }
          if (element.product_type == 2) {
            list_yes.push(element);
          }
        });
        this.form = {
          ...res.data,
          share_price: res.data.subscription_setting.share_price,
          min_shares: res.data.subscription_setting.min_shares,
          premium_rate: res.data.subscription_setting.premium_rate,
          commission_rule_no: list_no,
          commission_rule_yes: list_yes,
        };
        // this.timeData = [res.data.start_date, res.data.end_date];
        this.application_timeData = [res.data.application_start_date, res.data.application_end_date]
        // console.log('this.timeData :>> ', this.timeData);
        console.log('this.form :>> ', this.form);
      })
    },
    // 获取方案 id
    getPartnerGenerated() {
      partnerGenerated().then((res) => {
        this.form.scheme_identifier = res.data.scheme_identifier;
      })
    },
    // 富文本
    getUser(e) {
      console.log(e);
      this.form.goods_content = e
    },
    //上传
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.permission.url_loading = true
        return
      }
      if (info.file.status === 'done') {
        const { response = {} } = info.file
        this.form.image = response?.data?.file_url
        this.permission.url_loading = false
      }
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    // 方案期效
    // onChangeTime(rec) {
    //   console.log('rec :>> ', rec);
    //   this.form.start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
    //   this.form.end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
    //   console.log('rec :>> ', this.form.start_date);
    //   console.log('rec :>> ', this.form.end_date);
    // },
    // 开放时间
    onChangeApplication(rec) {
      console.log('rec :>> ', rec);
      this.form.application_start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
      this.form.application_end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
    },
    onChangeIdentify(rec) {
      console.log('rec :>> ', rec);
      this.form.identify_end_date = rec ? rec.format('YYYY-MM-DD') : '';
    },
    handleOpenChange(status) {
      this.yearPickShow = status;
    },
    onChangeYear(rec) {
      console.log('rec :>> ', rec.format('YYYY'));
      this.form.dividend_ratio_year = rec ? rec.format('YYYY') : '';
      this.yearPickShow = false;
    },

    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    onStep(key) {
      if (key == 2) {
        if (!this.form.goods_name) return this.$message.warning('请填写储值卡名称！')
        if (!this.form.goods_bn) return this.$message.warning('请填写商品码！')
        if (!this.form.goods_attr_val[0].product_price) return this.$message.warning('请填写标准单价')
        // if (!this.form.face_value) return this.$message.warning('请填写面额！')
        // if ((this.form.goods_attr_val[0].product_price - this.form.face_value) > 0) {
        //   return this.$message.warning('面额应大于或等于标准单价')
        // }
        if (!this.form.finance_category_id) return this.$message.warning('请选择财务分类！')

        // if (!this.form.face_value) return this.$message.warning('请填写面额！')
        if (this.form.store_category_id && this.form.store_category_id.length) {
          if (this.form.store_category_id.length < 3) {
            return this.$message.warning('必须选择三级，请完善商城分类再选择！')
          }

        }
        if (this.form.goods_category && this.form.goods_category.length) {
          if (this.form.goods_category.length < 3) {
            return this.$message.warning('必须选择三级，请完善门店分类再选择！')
          }
        }
        this.step = key
        // this.loadProduct()
      }
      if (key == 3) {

        let flag = false
        let msg = ''
        if (this.form.rule_entity_type > 1) {
          if (!this.step2data.targetKeys.length && !this.step2data.targetKeysTypes.length && !this.step2data.targetKeysTypes2.length && !this.step2data.targetKeysTypes3.length && !this.step2data.targetKeysBrands.length) {
            flag = true
            msg = '请选择适用产品！'
            // return this.$message.warning('请选择适用产品！')
          }
          if (this.form.rule_entity_type == 4) {
            this.form.entity_ids = this.step2data.targetKeys
          }

          if (this.form.rule_entity_type == 2 && this.step2data.types == 2) {
            this.form.entity_ids = this.step2data.targetKeysTypes
          }
          if (this.form.rule_entity_type == 2 && this.step2data.types == 6) {
            this.form.entity_ids = this.step2data.targetKeysTypes2
            this.form.rule_entity_type = "6"
          }
          if (this.form.rule_entity_type == 2 && this.step2data.types == 5) {
            this.form.entity_ids = this.step2data.targetKeysTypes3
            this.form.rule_entity_type = "5"
          }
          if (this.form.rule_entity_type == 3) {
            this.form.entity_ids = this.step2data.targetKeysBrands
          }
        }
        if (this.form.rule_service_type > 1) {
          if (!this.step2data.targetKeys_2.length && !this.step2data.targetKeysTypes_2.length && !this.step2data.targetKeysTypes2_2.length && !this.step2data.targetKeysTypes3_2.length && !this.step2data.targetKeysBrands_2.length) {
            flag = true
            msg = '请选择适用服务！'
          }
          if (this.form.rule_service_type == 4) {
            this.form.service_ids = this.step2data.targetKeys_2
          }
          if (this.form.rule_service_type == 2 && this.step2data.types2 == 2) {
            this.form.service_ids = this.step2data.targetKeysTypes_2

          }
          if (this.form.rule_service_type == 2 && this.step2data.types2 == 6) {
            this.form.service_ids = this.step2data.targetKeysTypes2_2
            this.form.rule_service_type = "6"

          }
          if (this.form.rule_service_type == 2 && this.step2data.types2 == 5) {
            this.form.service_ids = this.step2data.targetKeysTypes3_2
            this.form.rule_service_type = "5"

          }
          if (this.form.rule_service_type == 3) {
            this.form.service_ids = this.step2data.targetKeysBrands_2
          }
        }
        if (this.form.rule_proposal_type > 1) {
          if (!this.step2data.targetKeys_3.length && !this.step2data.targetKeysTypes_3.length && !this.step2data.targetKeysTypes2_3.length && !this.step2data.targetKeysTypes3_3.length && !this.step2data.targetKeysBrands_3.length) {
            flag = true
            msg = '请选择适用方案！'
          }
          if (this.form.rule_proposal_type == 4) {
            this.form.proposal_ids = this.step2data.targetKeys_3
          }
          if (this.form.rule_proposal_type == 2 && this.step2data.types3 == 2) {
            this.form.proposal_ids = this.step2data.targetKeysTypes_3

          }
          if (this.form.rule_proposal_type == 2 && this.step2data.types3 == 6) {
            this.form.proposal_ids = this.step2data.targetKeysTypes2_3
            this.form.rule_proposal_type = "6"
          }
          if (this.form.rule_proposal_type == 2 && this.step2data.types3 == 5) {
            this.form.proposal_ids = this.step2data.targetKeysTypes3_3
            this.form.rule_proposal_type = "5"
          }
          if (this.form.rule_proposal_type == 3) {
            this.form.proposal_ids = this.step2data.targetKeysBrands_3
          }

        }

        if (flag) {
          return this.$message.warning(msg)
        }
        this.step = key
      }
      if (key == 4) {
        this.step = key
      }
      this.step = key


    },
    //取消
    cancel() {
      let self = this
      self.$confirm({
        closable: true,
        title: '提示',
        content: '当前已填写数据尚未保存，确定取消保存吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          self.$router.push({ path: '/shop/storedvaluecard/' })
          Msg.$emit('changethirdaryShow', true)
        },
        onCancel() { },
      })
    },
    handlePreview(src, type) { },
    addTableItem(key, type) {
      if (this.form[key].some((it) => it.editable)) {
        this.$message.warning('请完成正在编辑的条目操作！')
        return
      }
      this['edit_item'] = undefined
      if (key == 'dividend_ratio_list') {
        this.form[key].splice(0, 0, {
          editable: true,
          operate: this.operate,
          dividend_business: undefined,
          dividend_rate: undefined,
          id: new Date().getTime(),
        })
      }
      if (key == 'commission_rule_no' || key == 'commission_rule_yes') {
        this.form[key].splice(0, 0, {
          editable: true,
          operate: this.operate,
          first_purchase_rate: undefined,
          payment_method: undefined,
          repurchase_rate: undefined,
          product_type: type,
          id: new Date().getTime(),
        })
      }
      console.log('this.form[key] :>> ', this.form[key]);
    },
    saveItem(record, index, key) {
      let self = this
      if (key == 'dividend_ratio_list') {
        console.log(record, index, key)
        const { dividend_rate } = record
        const specValueAntDElText = self.$refs['SpecValueRef_' + index].$el.innerText
        console.log(specValueAntDElText)
        let msg
        if (specValueAntDElText.includes('选择类型')) {
          msg = '请选择类型！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!dividend_rate) {
          msg = '分红比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
      }
      if (key == 'commission_rule_no' || key == 'commission_rule_yes') {
        const { first_purchase_rate, repurchase_rate } = record
        const specValueAntDElText = self.$refs['productTypeRef_' + index].$el.innerText
        console.log(specValueAntDElText)
        let msg
        if (specValueAntDElText.includes('选择类型')) {
          msg = '请选择类型！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!first_purchase_rate) {
          msg = '首购付款分佣比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!repurchase_rate) {
          msg = '再次付款分佣比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
      }
      setTimeout(() => {
        self.form[key][index].editable = false
      }, 400)
    },
    editItem(id, key) {
      if (this.form[key].some((it) => it.editable)) {
        this.$message.warning('请完成正在编辑的条目操作！')
        return
      }
      this.form[key] = this.form[key].map((it) => {
        if (it.id == id) {
          this['edit_item'] = it
          it.editable = true
        }
        return it
      })
    },
    cancelItem(index, key) {
      if (!this['edit_item']) {
        this.form[key].splice(index, 1)
        return
      }
      this.form[key] = this.form[key].map((it, ind) => {
        if (index == ind) {
          it.editable = false
        }
        return it
      })
    },
    deleteItem(record, index, key) {
      this.form[key].splice(index, 1)
    },
    editItemQy(record) {
      this.modal.form = record;
      this.qyStep = 1;
      this.modal.visible = true;
    },
    deleteItemQy(index) {
      let self = this
      self.$confirm({
        closable: true,
        title: '提示',
        content: '是否删除？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          self.form.benefit.splice(index, 1)
        },
        onCancel() { },
      })
    },
    // 添加权益
    handleOk() {
      if (!this.modal.form.name) return this.$message.warning('请填写权益名称！')
      if (!this.modal.form.goods_content) return this.$message.warning('请填写权益详情！')
      this.modal.form.count = this.modal.form.count_type == 1 ? 0 : this.modal.form.count;
      this.form.benefit.push(this.modal.form);
      this.modal.visible = false;
      this.qyStep = 1
    },
    handleCancel() {
      this.qyStep = 1;
      this.modal.visible = false
    },
    concatFunction(arr1, arr2) {
      //不要直接使用var arr = arr1，这样arr只是arr1的一个引用，两者的修改会互相影响  
      var arr = arr1.concat();
      //或者使用slice()复制，var arr = arr1.slice(0)  
      for (var i = 0; i < arr2.length; i++) {
        arr.indexOf(arr2[i]) === -1 ? arr.push(arr2[i]) : 0;
      }
      console.log('concatFunction', arr)
      return arr
    },
    submit() {

      if (this.form.is_all_shop == 2) {
        if (!this.withBrandCheck.length) return this.$message.warning('请选择指定门店！')
      }
      console.log('this.withBrandCheck :>> ', this.withBrandCheck);
      this.form.institution_ids = this.is_all_shop == 1 ? 'all' : this.withBrandCheck.toString();
      const { commission_rule_no, commission_rule_yes } = this.form;
      console.log('commission_rule_no :>> ', commission_rule_no);
      console.log('commission_rule_yes :>> ', commission_rule_yes);
      let arr = this.concatFunction(commission_rule_no, commission_rule_yes)
      let data = {
        ...this.form,
        commission_rule: arr,
      }
      delete data.commission_rule_no;
      delete data.commission_rule_yes;
      const hide = this.$message.loading('正在保存..', 0)
      if (this.$route.query.type == 2) {
        partnerSave(data).then((res) => {
          this.cancel()
        }).finally(r => {
          hide()
        })
      }
      if (this.$route.query.type == 1) {
        partnerAdd(data).then((res) => {
          this.cancel()
        }).finally(r => {
          hide()
        })
      }
    },
    goStep(e) {
      if (this.$route.query.type != 1) {
        this.step = e
      }
    },
    changeRoute() {
      this.$router.replace({ path: this.$route.fullpath ? this.$route.fullpath : this.$route.path });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)

      this.$forceUpdate();
    },

    //  门店分类 树形列表
    getCompanyoptionList(e) {

      GetgoodsoptionsList().then(res => {

        this.treeRecursion(res.data, 'category_id')
        this.treeData2 = JSON.parse(JSON.stringify(res.data))
        this.treeDataA2 = JSON.parse(JSON.stringify(res.data))
        // this.treeRecursion2(this.treeDataA2, 'disabled')
        this.treeRecursion3(this.treeDataA2, 'category_id')
        console.log(this.treeDataA2, '>>>this.treeDataA2门店分类');
        console.log(this.treeData2, '>>>this.treeData2门店分类');
        this.step2data.mockDataTypes2 = [];
        this.step2data.mockDataTypes2_2 = [];
        this.step2data.mockDataTypes2_3 = [];

        this.product_types_tree2 = JSON.parse(JSON.stringify(this.treeDataA2))
        this.product_types_tree2_2 = JSON.parse(JSON.stringify(this.treeDataA2))
        this.product_types_tree2_3 = JSON.parse(JSON.stringify(this.treeDataA2))

        let that = this
        function flattenmd(list = []) {
          list.forEach(item => {
            that.step2data.mockDataTypes2.push(item);
            that.step2data.mockDataTypes2_2.push(item);
            that.step2data.mockDataTypes2_3.push(item);
            flattenmd(item.children);
          });
        }
        flattenmd(JSON.parse(JSON.stringify(this.treeDataA2)));
        // that.step2data.mockDataTypes2r = that.step2data.mockDataTypes2.filter(function (item, index) {
        //   return that.step2data.mockDataTypes2.indexOf(item) === index;  // 因为indexOf 只能查找到第一个  
        // });

        console.log(this.step2data.mockDataTypes2, "门店分类...........1-----mockDataTypes2");
      })
    },
    //  财务分类 列表
    getCompanyoptionList2(e) {
      TreetypeFinanceList().then(res => {
        this.treeRecursion(res.data, 'finance_category_id')
        this.treeData = JSON.parse(JSON.stringify(res.data))
        this.treeDataA = JSON.parse(JSON.stringify(res.data))
        this.treeData1 = JSON.parse(JSON.stringify(res.data))
        this.step2data.mockDataTypes = [];
        this.step2data.mockDataTypes_2 = [];
        this.step2data.mockDataTypes_3 = [];
        let that = this
        this.treeRecursion3(this.treeDataA, 'finance_category_id')

        function flattenmd(list = []) {
          list.forEach(item => {
            that.step2data.mockDataTypes.push(item);
            that.step2data.mockDataTypes_2.push(item);
            that.step2data.mockDataTypes_3.push(item);

            flattenmd(item.children);
          });
        }
        flattenmd(JSON.parse(JSON.stringify(this.treeDataA)));
        console.log(this.treeData, '>>>this.treeData财务分类');
      })
    },
    //  商城分类 树形列表
    getCompanyoptionList3(e) {
      TreetypeMallList().then(res => {
        this.treeRecursion(res.data, 'store_category_id');
        this.treeData3 = JSON.parse(JSON.stringify(res.data))
        this.treeDataA3 = JSON.parse(JSON.stringify(res.data))
        this.treeRecursion3(this.treeDataA3, 'store_category_id')
        // this.treeDataA3 = JSON.parse(JSON.stringify(res.data))
        // this.treeRecursion2(this.treeDataA3, 'disabled')
        console.log(this.treeDataA3, '>>>this.treeDataA3商城分类');
        this.step2data.mockDataTypes3 = [];
        this.step2data.mockDataTypes3_2 = [];
        this.step2data.mockDataTypes3_3 = [];


        this.product_types_tree3 = JSON.parse(JSON.stringify(this.treeDataA3))
        this.product_types_tree3_2 = JSON.parse(JSON.stringify(this.treeDataA3))
        this.product_types_tree3_3 = JSON.parse(JSON.stringify(this.treeDataA3))
        let that = this
        function flattenmd(list = []) {
          list.forEach(item => {
            that.step2data.mockDataTypes3.push(item);
            that.step2data.mockDataTypes3_2.push(item);
            that.step2data.mockDataTypes3_3.push(item);

            flattenmd(item.children);
          });
        }
        flattenmd(JSON.parse(JSON.stringify(this.treeDataA3)));
        console.log(this.step2data.mockDataTypes3);

      })
    },

    // 递归  disabled   disableCheckbox
    treeRecursion2(data, i) {
      data.forEach(el => {
        // 把数字类型转为字符串  
        if (el.category_parent_path.includes(',')) {
          el[i] = false
        } else {
          el[i] = true
        }
        if (el.children && el.children.length && !el.category_parent_path.includes(',')) this.treeRecursion2(el.children, 'disableCheckbox');
      })
    },
    treeRecursion(data, str) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el[str]) == 'number') {
          el[str] = el[str].toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children, str);
      })
    },
    // 递归改变id key number
    treeRecursion3(data, str) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        el['key'] = el[str] + ''
        el['title'] = el.category_name ? el.category_name : el.brand_name
        delete el.category_name
        delete el.brand_name
        delete el.brand_icon
        delete el.brand_sort
        delete el.brand_status
        delete el.brand_type
        delete el.created_at
        delete el.updated_at
        delete el[str]
        delete el.category_icon
        delete el.category_status
        delete el.category_parent_path
        delete el.category_parent
        // el = { key: el[str] + '', title: el.category_name ? el.category_name : el.brand_name }
        if (el.children && el.children.length) {
          this.treeRecursion3(el.children, str);
        }
      })
      // return data
    },
    getBrands() {
      GetgoodsbrandsoptionsList().then(res => {
        console.log(res, "getBrands");
        if (res.error_code == 0) {
          this.brandsList = res.data
          this.brandsListcopy = JSON.parse(JSON.stringify(res.data))
          this.treeRecursion(this.brandsList, 'brand_id');
          this.treeRecursion3(this.brandsListcopy, 'brand_id');
          let that = this
          that.step2data.mockDataBrands = []
          that.step2data.mockDataBrands_2 = []
          that.step2data.mockDataBrands_3 = []
          function flattenmd(list = []) {
            list.forEach(item => {
              that.step2data.mockDataBrands.push(item);
              that.step2data.mockDataBrands_2.push(item);
              that.step2data.mockDataBrands_3.push(item);
              flattenmd(item.children);
            });
          }
          flattenmd(JSON.parse(JSON.stringify(this.brandsListcopy)));


        }
      })
    },
    openDatails() {
      cardsInfoProposal({ goods_id: this.$route.query.id }).then(res => {
        this.form = Object.assign(this.form, res.data)
        // this.goods_name = this.form.goods_name
        // this.form.goods_type = '7'
        this.is_edit_face_value = this.form.is_edit_face_value ? true : false
        this.form.goods_brand = this.form.goods_brand ? this.form.goods_brand + '' : undefined
        this.form.goods_supplier = this.form.goods_supplier + ''
        this.form.goods_slider = JSON.parse(this.form.goods_slider)
        this.form.goods_attr_val.forEach((el, idx) => {
          el.product_id_add = idx
        })

        if (this.form.goods_category) {
          this.form.goods_category = (res.data.category_info.category_parent_path + ',' + (res.data.category_info.category_id + '')).split(',')

        } else if (this.form.goods_category == 0) {
          this.form.goods_category = undefined
        }
        if (this.form.store_category_id) {
          this.form.store_category_id = (res.data.store_category_info.category_parent_path + ',' + (res.data.store_category_info.store_category_id + '')).split(',')

        } else if (this.form.store_category_id == 0) {
          this.form.store_category_id = undefined
        }

        this.form.finance_category_id = [res.data.finance_category_id + ""]

        let fileList = []
        // 回显轮播图
        this.form.goods_slider.forEach((val, key) => {
          fileList.push({
            uid: '-' + key,
            // name: '' + val.name, // 文件名
            status: 'done',
            url: '' + val
          })
        })

        if (this.$refs.uploadCom && this.$refs.uploadCom.fileList) {
          this.$refs.uploadCom.fileList = fileList
        }
        //单规格
        this.goods_specs.isSingle = 1
        this.goodSpecsSingletableData = this.form.goods_attr_val
        //回显 产品  服务 方案 rule_goods_type rule_goods_type
        //   rule_entity_type   0不适用、1适用全部实物、
        //  2 指定财务分类下实物、3指定品牌下实物、4指定实物、5指定商城分类下实物、
        //  6 指定门店分类下实物
        this.form.entity_ids = []
        this.form.service_ids = []
        this.form.proposal_ids = []
        if (this.form.rule_list && this.form.rule_list.length) {
          this.form.rule_list.forEach(el => {
            if (el.rule_goods_type == 1) { //产品
              this.form.entity_ids.push(el.rel_id + '')
            }
            if (el.rule_goods_type == 2) { //服务
              this.form.service_ids.push(el.rel_id + '')
            }
            if (el.rule_goods_type == 3) {//方案
              this.form.proposal_ids.push(el.rel_id + '')
            }
          })

        }
        if (this.form.rule_entity_type == 0) {
          this.step2data.changepro = false
          this.step2data.allgoodsorsingle = undefined
        } else if (this.form.rule_entity_type == 1) {
          this.step2data.changepro = true
          this.step2data.isfold = true
          this.step2data.allgoodsorsingle = '1'
        } else if (this.form.rule_entity_type == 4) { //指定
          this.loadProduct([1, 2, 3, 4])
          this.step2data.changepro = true
          this.step2data.isfold = true
          this.step2data.allgoodsorsingle = '2'
          this.form.rule_entity_type = '4'
          this.step2data.targetKeys = this.form.entity_ids
        } else if (this.form.rule_entity_type == 2) { //财务分类
          this.step2data.changepro = true
          this.step2data.isfold = true
          this.step2data.allgoodsorsingle = '2'
          this.form.rule_entity_type = '2'
          this.step2data.types = '2'
          this.step2data.targetKeysTypes = this.form.entity_ids
          // 其实这里还要分 财务 门店 商城 
        } else if (this.form.rule_entity_type == 6) { //门店分类
          console.log(this.form.entity_ids, 'this.form.entity_ids...');
          this.step2data.changepro = true
          this.step2data.isfold = true
          this.step2data.allgoodsorsingle = '2'
          this.form.rule_entity_type = '2'
          this.step2data.types = '6'
          this.step2data.targetKeysTypes2 = this.form.entity_ids
          //   门店   
        } else if (this.form.rule_entity_type == 5) { //商城分类
          this.step2data.changepro = true
          this.step2data.isfold = true
          this.step2data.allgoodsorsingle = '2'
          this.form.rule_entity_type = '2'
          this.step2data.types = '5'
          this.step2data.targetKeysTypes3 = this.form.entity_ids
          // 商城 
        } else if (this.form.rule_entity_type == 3) {
          this.step2data.changepro = true
          this.step2data.isfold = true
          this.step2data.allgoodsorsingle = '2'
          this.form.rule_entity_type = '3'
          this.step2data.targetKeysBrands = this.form.entity_ids
          console.log(this.step2data.mockDataBrands, '.step2data.mockDataBrands');
        }


        if (this.form.rule_service_type == 0) {
          this.step2data.changepro2 = false
          this.step2data.allgoodsorsingle2 = undefined
        } else if (this.form.rule_service_type == 1) {
          this.step2data.changepro2 = true
          this.step2data.isfold2 = true
          this.step2data.allgoodsorsingle2 = '1'
        } else if (this.form.rule_service_type == 4) {
          this.loadProduct2([5])
          this.step2data.changepro2 = true
          this.step2data.isfold2 = true
          this.step2data.allgoodsorsingle2 = '2'
          this.form.rule_service_type = '4'
          this.step2data.targetKeys_2 = this.form.service_ids
        } else if (this.form.rule_service_type == 2) {
          this.step2data.changepro2 = true
          this.step2data.isfold2 = true
          this.step2data.allgoodsorsingle2 = '2'
          this.form.rule_service_type = '2'
          this.step2data.types2 = '2'
          this.step2data.targetKeysTypes_2 = this.form.service_ids
          // 其实这里还要分 财务 门店 商城 
        } else if (this.form.rule_service_type == 6) {
          this.step2data.changepro2 = true
          this.step2data.isfold2 = true
          this.step2data.allgoodsorsingle2 = '2'
          this.form.rule_service_type = '2'
          this.step2data.types2 = '6'
          this.step2data.targetKeysTypes2_2 = this.form.service_ids
          // 其实这里还要分 财务 门店 商城 
        } else if (this.form.rule_service_type == 5) {
          this.step2data.changepro2 = true
          this.step2data.isfold2 = true
          this.step2data.allgoodsorsingle2 = '2'
          this.form.rule_service_type = '2'
          this.step2data.types2 = '5'
          this.step2data.targetKeysTypes3_2 = this.form.service_ids
          // 其实这里还要分 财务 门店 商城 
        } else if (this.form.rule_service_type == 3) {
          this.step2data.changepro2 = true
          this.step2data.isfold2 = true
          this.step2data.allgoodsorsingle2 = '2'
          this.form.rule_service_type = '3'
          this.step2data.targetKeysBrands_2 = this.form.service_ids
          console.log(this.step2data.targetKeysBrands_2, '.step2data.targetKeysBrands_2');
        }


        if (this.form.rule_proposal_type == 0) {
          this.step2data.changepro3 = false
          this.step2data.allgoodsorsingle3 = undefined
        } else if (this.form.rule_proposal_type == 1) {
          this.step2data.changepro3 = true
          this.step2data.isfold3 = true
          this.step2data.allgoodsorsingle3 = '1'
        } else if (this.form.rule_proposal_type == 4) {
          this.loadProduct3([8, 9])
          this.step2data.changepro3 = true
          this.step2data.isfold3 = true
          this.step2data.allgoodsorsingle3 = '2'
          this.form.rule_proposal_type = '4'
          this.step2data.targetKeys_3 = this.form.proposal_ids
        } else if (this.form.rule_proposal_type == 2) {
          this.step2data.changepro3 = true
          this.step2data.isfold3 = true
          this.step2data.allgoodsorsingle3 = '2'
          this.form.rule_proposal_type = '2'
          this.step2data.types3 = '2'
          this.step2data.targetKeysTypes_3 = this.form.proposal_ids
          // 其实这里还要分 财务 门店 商城 
        } else if (this.form.rule_proposal_type == 6) {
          this.step2data.changepro3 = true
          this.step2data.isfold3 = true
          this.step2data.allgoodsorsingle3 = '2'
          this.form.rule_proposal_type = '2'
          this.step2data.types3 = '6'
          this.step2data.targetKeysTypes2_3 = this.form.proposal_ids
          // 其实这里还要分 财务 门店 商城 
        } else if (this.form.rule_proposal_type == 5) {
          this.step2data.changepro3 = true
          this.step2data.isfold3 = true
          this.step2data.allgoodsorsingle3 = '2'
          this.form.rule_proposal_type = '2'
          this.step2data.types3 = '5'
          this.step2data.targetKeysTypes3_3 = this.form.proposal_ids
          // 其实这里还要分 财务 门店 商城 
        } else if (this.form.rule_proposal_type == 3) {
          this.step2data.changepro3 = true
          this.step2data.isfold3 = true
          this.step2data.allgoodsorsingle3 = '2'
          this.form.rule_proposal_type = '3'
          this.step2data.targetKeysBrands_3 = this.form.proposal_ids
          console.log(this.step2data.targetKeysBrands_3, '.step2data.targetKeysBrands_3');
        }

      })
    },
    onChangeTimes(date, dateString) {
      this.form.goods_shou_end_time = dateString
    },
    onChange() { },
    onChange1() { },
    getPopupContainer(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })

      return document.querySelectorAll('.styledatas')[0]
    },
    getPopupContainer2(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas2')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })

      return document.querySelectorAll('.styledatas2')[0]
    },
    getPopupContainer3(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas3')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })

      return document.querySelectorAll('.styledatas3')[0]
    },
    // 单规格 上传规格图
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.goodSpecsSingletableData[0].product_image = res.data.ur
      } else {
        this.$message.error(res.error.message)
      }
    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    // 选择规格
    changeGoodsSpecs(e, i) {
      let self = this
      if (e == 9999) {
        this.goods_specs.goods_specs_id = undefined
        this.showaddGoodsprivatespec = true //自定义新增规格弹框
        this.ischangeGoodsSpecs1 = false
        return false
      }
      this.goods_specs.goods_specs_list.forEach(el => {
        if (el.id == e) {
          self.goods_specs.goods_specs_values_list = el.attr_values
          //   //
          // self.goods_specs.new_goods_specs_list.push(el)
          this.goods_specs.goods_specs_selectobj = el
        }

      })
      this.ischangeGoodsSpecs1 = true
      console.log(this.goods_specs.goods_specs_values_list, "goods_specs_values_list");
      // console.log(self.goods_specs.goods_specs_keys_list, " self.goods_specs.goods_specs_keys_list");

    },
    // 确认规格
    changeGoodsSpecs1() {
      // 确认没有空的规格值  然后push

      let flag = false
      let self = this
      this.goods_specs.goods_specs_values_list.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.goods_specs_values_list.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后进入下一步。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
            // self.qystep = 2
          },
        });
        return false
      }
      console.log(this.goods_specs.goods_specs_values_list);
      // this.goods_specs.goods_specs_values_list.push('')
      console.log(this.goods_specs.goods_specs_selectobj);
      this.goods_specs.new_goods_specs_list.push(this.goods_specs.goods_specs_selectobj)
      this.goods_specs.goods_specs_list.forEach((el, idx) => {
        if (el.id == this.goods_specs.goods_specs_selectobj.id) {

          this.goods_specs.goods_specs_list.splice(idx, 1)
          this.goods_specs.goods_specs_id = undefined

        }
      })
      console.log(this.goods_specs.goods_specs_list);
      // 生成多规格列表
      this.hideModal()
      this.ischangeGoodsSpecs1 = false
      this.isShowGuigeAddSelect = false
    },
    // 
    onqyChange(e, it, idex) {
      let self = this
      let a = self.goods_specs.goods_specs_values_list[idex]
      console.log(e, self.goods_specs.goods_specs_values_list[idex]);
      console.log(this.goods_specs.goods_specs_values_list);

      self.goods_specs.goods_specs_values_list[idex] = e.target.innerText
    },
    closegoodsSpecsValues(e) {
      this.goods_specs.goods_specs_values_list.splice(e, 1)
      console.log(this.goods_specs.goods_specs_values_list, "this.goods_specs.goods_specs_values_list");
    },
    legalObjadd() {
      let flag = false
      let self = this
      this.goods_specs.goods_specs_values_list.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.goods_specs_values_list.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后进入下一步。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
            // self.qystep = 2
          },
        });
        return false
      }
      console.log(this.goods_specs.goods_specs_values_list);
      this.goods_specs.goods_specs_values_list.push('')
    },
    // 删除新增的规格名 规格值
    deleteNewspecs(e, it) {
      let self = this
      // delete this.goods_specs.new_goods_specs_list[e]
      this.goods_specs.new_goods_specs_list.splice(e, 1)
      // 恢复 goods_specs_list 的值
      this.goods_specs.goods_specs_list0.forEach(el => {
        if (el.id == it.id) {
          self.goods_specs.goods_specs_list.unshift(it)
        }
      })
      // 重新 生成多规格列表
      this.hideModal()
      console.log(this.goods_specs.new_goods_specs_list);
    },
    closegoodsSpecsValues(e) {
      this.goods_specs.goods_specs_values_list.splice(e, 1)
      console.log(this.goods_specs.goods_specs_values_list, "this.goods_specs.goods_specs_values_list");
    },
    deletenewGoodsSpecs(idx, n) {
      // 调取 如果

      let obj = {}
      this.goods_specs.goods_specs_list0.forEach((el, index) => {
        if (el.attr_name == this.goods_specs.new_goods_specs_list[idx].attr_name) {
          obj = el
        }
      })
      this.goods_specs.new_goods_specs_list[idx].attr_values.splice(n, 1)
      if (this.goods_specs.new_goods_specs_list[idx].attr_values.length == 0) {
        this.goods_specs.new_goods_specs_list.splice(idx, 1)
        if (obj.length) this.goods_specs.goods_specs_list.unshift(obj)
      }
      // console.log(this.goods_specs.new_goods_specs_list[idx].attr_values);
      // 选择框 加入 删除的选项

      this.hideModal()
    },
    // 批量生成规格表格  设置 单价 和 货品码
    createdSpecsTable() {
      let self = this
      this.goodSpecstableData.forEach((el, idx) => {
        self.goodSpecstableData[idx].product_price = self.goods_specs.price
        self.goodSpecstableData[idx].product_bn = self.goods_specs.barcode
        self.goodSpecstableData[idx].product_image = self.goods_specs.product_image
        self.goodSpecstableData[idx].product_consumable = self.consumable.zcSelectedTitles

      })
      //   //
      console.log(this.goodSpecstableData, "vvvthis.goodSpecstableData");
    },
    // 改变 某一个单价/条码
    changeSingeSpecIpt(record, i, flag) {
      console.log(record, i, flag, "val,record");
      //   //
      console.log(this.goodSpecstableData, 'goodSpecstableData')
      if (flag) {
        this.goodSpecsSingletableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      } else {
        this.goodSpecstableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      }

    },

    addnewgoodsspecs(idx, n) {
      let flag = false
      let self = this
      this.goods_specs.new_goods_specs_list[idx].attr_values.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.new_goods_specs_list[idx].attr_values.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后操作。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
          },
        });
        return false
      }
      console.log(this.goods_specs.new_goods_specs_list[idx].attr_values);
      this.goods_specs.new_goods_specs_list[idx].attr_values.push('')
    },
    addnewgoodsspecs1() {
      let flag = false
      let self = this
      if (this.singelguigeadd.attr_values.length) {
        this.singelguigeadd.attr_values.forEach(el => {
          if (!el) {
            flag = true
          }
        })
        if (flag && self.singelguigeadd.attr_values.length > 0) {
          this.$warning({
            title: '提示',
            content: h => {
              return h('div', [
                h(
                  'span',
                  [' 信息尚未填写，请完善内容后操作。']
                ),
              ])
            },

            okText: '好的',
            okType: 'primary',
            // cancelText: '取消',
            onOk() {
            },
          });
          return false
        }
      } else {
        // this.singelguigeadd[idx].attr_values.push('')
      }

      console.log(this.singelguigeadd.attr_values);
      this.singelguigeadd.attr_values.push('')
    },
    addguigeokFn(e) {

      console.log(e, 'addguigeokFn');
      console.log(this.singelguigeadd, 'singelguigeadd');
      this.goods_specs.new_goods_specs_list.push(this.singelguigeadd)
      this.singelguigeadd = {
        attr_name: '',
        attr_values: [],
      }
      this.hideModal()
      this.showaddGoodsprivatespec = false
      this.isShowGuigeAddSelect = false
    },
    // 生成多规格table
    hideModal() {
      console.log('往表格里面加规格列表');

      var arr = []
      var arr_goods_attr = []
      this.goods_specs.new_goods_specs_list
      this.goods_specs.goods_specs_list
      //   //
      arr = [...this.goods_specs.new_goods_specs_list, ...this.goods_specs.goods_specs_keys_list]
      // todo 以后这里需要把规格列表传给后台 成功之后 1，2
      // 1 在这里就需要渲染table的数据和columns操作
      let arri1 = []
      // coluns
      let columns = []
      let specs_clomns = [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          title: '标准单价',
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          title: '货品码',
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
        // {
        //   // title: '耗材',
        //   slots: { title: 'consumable' },
        //   dataIndex: 'consumable',
        //   key: 'consumable',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'consumable' },
        // },
      ]
      arr.forEach((el, index) => {
        let object1 = {
          attr_name: el.attr_name,
          attr_values: el.attr_values,
        }
        let object2 = {
          title: el.attr_name,
          dataIndex: el.attr_name,
          key: el.attr_name,
          ellipsis: true,
          scopedSlots: { customRender: el.attr_name },
        }
        arri1.push(object1)
        columns.push(object2)
      })
      this.form.goods_attr = arri1 //规格
      // 动态生成表头
      this.goods_specs_clomns = [...columns, ...specs_clomns]
      let lastcunobj = {
        // title: '耗材',
        slots: { title: 'consumable' },
        dataIndex: 'product_consumable',
        key: 'consumable',
        ellipsis: true,
        scopedSlots: { customRender: 'product_consumable' },
      }
      let actionObj = {
        title: '操作',
        dataIndex: 'action',
        key: '_7',
        width: 268,
        scopedSlots: { customRender: '_7' },
        fixed: 'right'
      }
      // 添加耗材表单头

      //  //
      let newResult2 = []
      let newResult = arri1.reduce((a, b, c) => {
        let res = []
        a.map(x => {
          b.attr_values.map(y => {
            res.push({ ...x, [b.attr_name]: y })
          })
        })
        return res
      }, [{}])
      // newResult2 = Object.assign(newResult2, newResult)
      console.log(newResult, 1, newResult2)
      // 动态生成 商品规格表

      // 编辑条件下 比较与旧版的商品规格表 把数据贴上去
      if (this.addorupdateSpecFlag == 'update') {
        newResult.forEach((el, idx) => {
          this.form.goods_attr_val.forEach((m, n) => {
            //   //
            let reg = /\\"/g;
            let a = JSON.stringify(m.product_attr).replace(reg, '');
            console.log(JSON.stringify(el) == a)
            if (JSON.stringify(el) == a) {
              newResult[idx] = Object.assign(m, el)
              // newResult[idx] = m
            }

          })

        })

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: '',
            product_consumable: '耗材一，耗材二、、、'
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        console.log(newResult, 2)

        this.goodSpecstableData = newResult2
      } else {

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: '',
            product_consumable: '耗材一，耗材二、、、'
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        this.goodSpecstableData = newResult2
      }
      this.goods_specs_clomns.push(lastcunobj)

      this.goods_specs_clomns.push(actionObj)


      // 2
      this.goods_specs.ischooseModehow = false

    },
    // 批量 规格图
    async batchSetuploadspecFiles(info, record, flag) {
      // console.log(info)
      // console.log(record); 
      // console.log(this.goodSpecstableData);
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.goods_specs.product_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 列表 规格图
    // 规格图
    async uploadspecFiles(info, record, flag) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        if (flag) {
          this.goodSpecsSingletableData[0].product_image = res.data.url
        } else {
          this.goodSpecstableData.forEach(el => {

            if (record.product_id >= 0) {
              if (el.product_id == record.product_id) {
                el.product_image = res.data.url
              }
            }
            if (record.product_id_add >= 0) {
              if (el.product_id_add == record.product_id_add) {
                el.product_image = res.data.url
              }
            }

          })
        }


        // this.form.goods_image = res.data.url
        console.log(this.goodSpecstableData);
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 轮播图
    getDelImageId(val) {
      if (this.form.goods_slider && this.form.goods_slider.length > 0) {

      } else {
        this.form.goods_slider = []
      }
      this.form.goods_slider.push(val)
    },
    getImageshow(val) {
      this.pulsshow = val
    },
    delDelimageId(index) {
      this.form.goods_slider.forEach((val, key) => {
        if (index === key) {
          this.form.goods_slider.splice(key, 1)
        }
      })
    },
    // 获取到重新排序后的图片
    handleDraggable(e) {
      const imgDrag = []
      for (var i = 0; i < e.length; i++) {
        // var a = e[i].url.split('/')
        imgDrag.push(e[i].url)
      }
      this.form.goods_slider = imgDrag
    },
    // 商品 f封面图
    async ffuploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      //
      if (res.error_code == 0) {
        this.form.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 提交按钮
    okclickmod(e) {
      this.form.goods_attr_val[0].product_bn = this.form.goods_bn
      // rule_entity_type: '0',  //0不适用、1适用全部实物、2指定分类下实物、3指定品牌下实物、44指定实物
      // rule_service_type: '0',
      // rule_proposal_type: '0',
      // entity_ids: [],//实物限制对应ID
      // service_ids: [],//服务限制对应ID
      // proposal_ids: [],//方案限制对应ID

      // this.consumable.type  1产品  2服务   3方案
      // this.consumable.value 0不适用 1全部产品 2部分产品 3部分分类 4部分品牌


      let data_pa = {
        ...this.form
      }
      if (this.form.finance_category_id && this.form.finance_category_id.length) {
        data_pa.finance_category_id = this.form.finance_category_id.length ? this.form.finance_category_id[0] : ''
      }
      if (this.form.goods_category && this.form.goods_category.length) {
        data_pa.goods_category = this.form.goods_category.length ? this.form.goods_category.pop() + '' : undefined
      } else {
        data_pa.goods_category = 0
      }
      if (this.form.store_category_id && this.form.store_category_id.length) {
        data_pa.store_category_id = this.form.store_category_id.length ? this.form.store_category_id.pop() + '' : undefined
      } else {
        data_pa.store_category_id = 0
      }
      // 区分新增 修改接口
      if (this.$route.query.type == 3) {
        delete this.form.created_at
        delete this.form.updated_at

        UpdatcardsProposal(data_pa).then(res => {
          if (res.error_code == 0) {
            this.$message.success("修改成功")
            this.changeRoute()
          }
        })
      } else {

        CreatecardsProposal(data_pa).then(res => {
          if (res.error_code == 0) {
            this.$message.success('创建成功')
            this.permissionInfo.visible = false
            this.changeRoute()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    // 打开耗材弹框 
    openconsumable(e, record) {
      if (e != 3 && !this.consumable.zcSelectedTitles.length) {
        this.consumable.targetKeys = []
      }
      this.consumable.num = e //单规格1 多规格批量2 多规格单个3
      if (e != 2) {
        this.consumable.record = record
      } else {
        this.consumable.record = ''
      }
      this.consumable.visible = true
      let obj = {
        activeStatus1: {
          id: "1",
          name: "机构简称"
        },
        value: ''
      }

      let timers = setTimeout(() => {
        let o = document.querySelectorAll('.ant-transfer-list-header-selected')[0]
        if (o.innerHTML.includes('全部耗材')) {
          clearTimeout(timers)
          timers = ''
          return false
        }
        let o1 = document.querySelectorAll('.ant-transfer-list-header-selected')[1]
        let a = document.querySelectorAll('.ant-transfer-list-header-selected>span')[0]
        let b = document.querySelectorAll('.ant-transfer-list-header-selected>span')[2]
        var ospan = document.createElement("span");
        ospan.className = 'baseColor11'
        var ospan2 = document.createElement("span");
        ospan2.className = 'baseColor11'
        var oTxt = document.createTextNode("全部耗材 ");
        var oTxt2 = document.createTextNode("已选耗材 ");
        ospan.appendChild(oTxt);
        o.insertBefore(ospan, a);
        ospan2.appendChild(oTxt2);
        o1.insertBefore(ospan2, b);
        console.log(document.querySelectorAll('.ant-transfer-list-header-selected>span'));
        clearTimeout(timers)
        timers = ''
      }, 500)

      this.getListSearchlimits(obj)
    },
    // 授权机构 搜索
    getListSearchlimits(e) {
      console.log(e);
      // 根据条件 判断是机构简称1 机构代码2 机构名称3 的搜索条件
      // this.checkLimitsListfn()

    },
    checkLimitsListfn(e) {

    },
    //  穿梭框相关 产品
    onlimitsChange(nextTargetKeys, i) {
      nextTargetKeys.forEach(el => {
        el = el + ''
      })
      if (i == 1) {
        this.step2data.targetKeys = nextTargetKeys;
      }
      if (i == 2) {

        if (this.step2data.types == 2) {//

          this.step2data.targetKeysTypes = nextTargetKeys
        }
        if (this.step2data.types == 6) {//

          this.step2data.targetKeysTypes2 = nextTargetKeys;
        }
        if (this.step2data.types == 5) {//
          this.step2data.targetKeysTypes3 = nextTargetKeys
        }

        // this.$forceUpdate();
      }
      if (i == 3) {
        this.step2data.targetKeysBrands = nextTargetKeys;
      }
    },
    //  穿梭框相关 服务
    onlimitsChange2(nextTargetKeys, i) {
      nextTargetKeys.forEach(el => {
        el = el + ''
      })
      if (i == 1) {
        this.step2data.targetKeys_2 = nextTargetKeys;
      }
      if (i == 2) {
        if (this.step2data.types2 == 2) {//
          this.step2data.targetKeysTypes_2 = nextTargetKeys
        }
        if (this.step2data.types2 == 6) {//
          this.step2data.targetKeysTypes2_2 = nextTargetKeys;
        }
        if (this.step2data.types2 == 5) {//
          this.step2data.targetKeysTypes3_2 = nextTargetKeys
        }

        // this.$forceUpdate();
      }
      if (i == 3) {
        this.step2data.targetKeysBrands_2 = nextTargetKeys;
      }
    },
    //  穿梭框相关 方案
    onlimitsChange3(nextTargetKeys, i) {
      nextTargetKeys.forEach(el => {
        el = el + ''
      })

      if (i == 1) {
        this.step2data.targetKeys_3 = nextTargetKeys;
      }
      if (i == 2) {
        if (this.step2data.types3 == 2) {//
          this.step2data.targetKeysTypes_3 = nextTargetKeys
        }
        if (this.step2data.types3 == 6) {//
          this.step2data.targetKeysTypes2_3 = nextTargetKeys;
        }
        if (this.step2data.types3 == 5) {//
          this.step2data.targetKeysTypes3_3 = nextTargetKeys
        }

        // this.$forceUpdate();
      }
      if (i == 3) {
        this.step2data.targetKeysBrands_3 = nextTargetKeys;
      }
    },

    onChecked(_, e, checkedKeys, itemSelect) {
      const { eventKey } = e.node;
      itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
    },
    triggerDisable(disabled) {
      this.disabled = disabled;
    },

    triggerShowSearch(showSearch) {
      this.showSearch = showSearch;
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      //
      let that = this
      return {
        // getCheckboxProps: item => ({ props: { disabled: disabled || item.disabled } }),
        onSelectAll(selected, selectedRows) {
          //
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          //
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
        onChange(record, selected, selectedRows, nativeEvent) {
          //
          record, selected, selectedRows, nativeEvent
          that.consumable.selectedRowKeys = selectedRows
        }
      };
    },

    // 树形结构 转 平铺 从外到内依次递归，有 children 则继续递归
    treeToArr(data, pid = null, res = []) {
      data.forEach(item => {
        res.push({ pid: pid, id: item.id, name: item.title });
        if (item.children && item.children.length !== 0) {
          this.treeToArr(item.children, item.id, res);
        }
      });
      return res;
      // 
    },
    // 逆向 平铺-转-树形结构 ，有 children 则继续递归
    arrToTree(arr, pid = null) {
      const res = [];
      arr.forEach(item => {
        if (item.pid === pid) {
          // 这样每次都需要遍历整个数组，因此时间复杂度为 n*n
          // const children = arrToTree(arr, item.id)   

          // 往下递归时，每次数组的大小都在减小，每次都筛选掉父代元素，最终的时间复杂度为 n*logn
          const children = this.arrToTree(arr.filter(v => v.pid !== pid), item.id);
          if (children.length) {
            res.push({ ...item, children })
          } else {
            res.push({ ...item })
          }
        }
      });
      return res;
    },
    // 添加耗材 确定ok
    addconsumable() {
      //  单规格的编辑耗材
      //  多规格的编辑耗材  批量  单个

      let mocdata = this.treeToArr(this.consumable.originalmockData)
      console.log(this.consumable.targetKeys);
      let arr = []
      this.consumable.targetKeys.forEach(el => {
        mocdata.forEach((m) => {
          if (m.id == el) {
            arr.push(m.name)
          }
        })

      })
      if (this.goods_specs.isSingle == 1) {
        this.form.skuTable[0].consumable = arr
      } else {
        if (this.consumable.num == 2) {  //多规格的编辑耗材  批量
          // 遍历所有的规格值表 加上规格值
          // 暂存 等批量设置时候渲染 this.goodSpecstableData
          this.consumable.zcSelectedTitles = arr
        }
        if (this.consumable.num == 3) {  //  多规格的编辑耗材   单个
          // 找到单个规格值改变
          console.log(this.consumable.record);
          this.goodSpecstableData.forEach(el => {
            if (el.product_id_add == this.consumable.record.product_id_add) {
              el.product_consumable = arr
            }
          })
        }
      }
      this.consumable.visible = false
    },
    // 
    changeconsumableValue(value, i) {
      // i  1产品 2服务 3方案
      //value 0无 ,1全部  2指定分类 3指定品牌  4部分4-1 4-2 4-3 
      this.consumable.value = value
      this.consumable.type = i
      if (i == 1) {
        this.form.rule_entity_type = i
        // this.form.entity_ids
      }
      if (i == 2) {
        this.form.rule_service_type = i
        // this.form.service_ids
      }
      if (i == 3) {
        this.form.rule_proposal_type = i
        // this.form.rule_proposal_type
      }

      if (this.consumable.value > 1) {
        this.consumable.visible = true
        this.consumable.targetKeys = []
      }
      // 分类
      if (this.consumable.value == 2) {
        this.consumable.title = '选择分类'
        // 得到分类列表
        this.consumable.mockData3 = this.transferDataSource
        // this.consumable.mockData = mockData1.mockdata

      }
      // 品牌
      if (this.consumable.value == 3) {
        this.consumable.title = '选择品牌'
        this.consumable.selectArr = []
        this.consumable.leftColumns = [
          {
            dataIndex: 'title',
            title: '品牌名称',
          }]
        this.consumable.rightColumns = [
          {
            dataIndex: 'title',
            title: '品牌名称',
          },]
      }
      //  部分产品 
      if (this.consumable.value == 4 && i == 1) {
        //  this.consumable.mockData=?
        this.consumable.title = '选择服务'
        this.consumable.selectArr = [
          { name: '商品名称', id: '1' },
          { name: '商品码', id: '2' },
        ]
        this.consumable.leftColumns = [
          {
            dataIndex: 'title',
            title: '服务名称',
          },
          {
            dataIndex: 'component',
            title: '商品码',
          },]
        this.consumable.rightColumns = [
          {
            dataIndex: 'title',
            title: '服务名称',
          },
          {
            dataIndex: 'component',
            title: '商品码',
          },]
      }
      //  部分服务 
      if (this.consumable.value == 4 && i == 2) {
        //  this.consumable.mockData=?
        this.consumable.title = '选择服务'
        this.consumable.selectArr = [
          { name: '商品名称', id: '1' },
          { name: '商品码', id: '2' },
        ]
        this.consumable.leftColumns = [
          {
            dataIndex: 'title',
            title: '服务名称',
          },
          {
            dataIndex: 'component',
            title: '商品码',
          },]
        this.consumable.rightColumns = [
          {
            dataIndex: 'title',
            title: '服务名称',
          },
          {
            dataIndex: 'component',
            title: '商品码',
          },]
      }
      //  部分方案
      if (this.consumable.value == 4 && i == 3) {
        //  this.consumable.mockData=?
        this.consumable.title = '选择服务'
        this.consumable.selectArr = [
          { name: '商品名称', id: '1' },
          { name: '商品码', id: '2' },
        ]
        this.consumable.leftColumns = [
          {
            dataIndex: 'title',
            title: '服务名称',
          },
          {
            dataIndex: 'component',
            title: '商品码',
          },]
        this.consumable.rightColumns = [
          {
            dataIndex: 'title',
            title: '服务名称',
          },
          {
            dataIndex: 'component',
            title: '商品码',
          },]
      }
      // 产品
      if (this.consumable.value == 7) {
        //  this.consumable.mockData=?
        this.consumable.title = '选择产品'
        this.consumable.selectArr = [
          { name: '商品名称', id: '1' },
          { name: '商品码', id: '2' },
        ]
        this.consumable.leftColumns = [
          {
            dataIndex: 'title',
            title: '产品名称',
          },
          {
            dataIndex: 'component',
            title: '商品码',
          },]
        this.consumable.rightColumns = [
          {
            dataIndex: 'title',
            title: '产品名称',
          },
          {
            dataIndex: 'component',
            title: '商品码',
          },]
      }
      // 方案
      if (this.consumable.value == 8) {
        //  this.consumable.mockData=?
        this.consumable.title = '选择方案'
        this.consumable.selectArr = [
          { name: '商品名称', id: '1' },
          { name: '商品码', id: '2' },
        ]
        this.consumable.leftColumns = [
          {
            dataIndex: 'title',
            title: '卡项名称',
          },
          {
            dataIndex: 'component',
            title: '商品码',
          },]
        this.consumable.rightColumns = [
          {
            dataIndex: 'title',
            title: '卡项名称',
          },
          {
            dataIndex: 'component',
            title: '商品码',
          },]
      }
    },
    callback(e) {
      console.log(e); // 1产品  2服务   3方案
      this.consumable.type = e
      this.treeDataA = this.treeDataA1
      if (e == 1) {
        // this.form.rule_entity_type = '0'
        // this.form.entity_ids = []
      }
      if (e == 2) {
        // this.form.rule_service_type = '0'
        // this.form.service_ids = []
      }
      if (e == 3) {
        // this.form.rule_proposal_type =' 0'
        // this.form.proposal_ids = []
      }

    },
    onChangeProduct(e, i) {
      if (i == 1) this.consumable.value = e.target.value
      if (i == 2) this.consumable.value = e.target.value2
      if (i == 3) this.consumable.value = e.target.value3
    },

    // 搜索框配合 分类 树形
    // getTypestreeListSearch(e) {
    //   console.log(e);
    //   //
    //   var _this = this;
    //   //添加这行代码是为了只点击搜索才触发
    //   _this.searchValue = e.value;
    //   let type = e.activeStatus1.id == 3 ? 'store_category_id' : e.activeStatus1.id == 2 ? 'category_id' : 'finance_category_id'    //3 "商城分类" 2门店分类 1财务分类
    //   //如果搜索值为空，则不展开任何项，expandedKeys置为空
    //   //如果搜索值不位空，则expandedKeys的值要为搜索值的父亲及其所有祖先
    //   if (_this.searchValue === '') {
    //     _this.expandedKeys = [];
    //   } else {
    //     //首先将展开项与展开项副本置为空
    //     _this.expandedKeys = [];
    //     _this.backupsExpandedKeys = [];
    //     //获取所有存在searchValue的节点
    //     let candidateKeysList = _this.getkeyList(_this.searchValue, _this.treeDataA, [], type);
    //     //遍历满足条件的所有节点
    //     candidateKeysList.map(
    //       item => {
    //         //获取每个节点的母亲节点
    //         var key = _this.getParentKey(item, _this.treeDataA, type);
    //         //当item是最高一级，父key为undefined，将不放入到数组中
    //         //如果母亲已存在于数组中，也不放入到数组中
    //         if (key && !_this.backupsExpandedKeys.some(item => item === key))
    //           _this.backupsExpandedKeys.push(key);
    //       }
    //     )
    //     let length = this.backupsExpandedKeys.length;
    //     for (let i = 0; i < length; i++) {
    //       _this.getAllParentKey(_this.backupsExpandedKeys[i], _this.treeDataA, type)
    //     }
    //     this.backupsExpandedKeys.forEach(el => el + '')
    //     _this.expandedKeys = _this.backupsExpandedKeys.slice();
    //   }
    // },
    //获取节点中含有value的所有key集合
    getkeyList(value, tree, keyList, type) {
      //遍历所有同一级的树
      for (let i = 0; i < tree.length; i++) {
        let node = tree[i];
        //如果该节点存在value值则push
        if (node.category_name.indexOf(value) > -1) {
          keyList.push(node[type]);
        }
        //如果拥有孩子继续遍历
        if (node.children) {
          this.getkeyList(value, node.children, keyList, type);
        }
      }
      //因为是引用类型，所有每次递归操作的都是同一个数组
      return keyList;
    },
    //该递归主要用于获取key的父亲节点的key值
    getParentKey(key, tree, type) {
      let parentKey, temp;
      //遍历同级节点
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          //如果该节点的孩子中存在该key值，则该节点就是我们要找的父亲节点
          //如果不存在，继续遍历其子节点
          if (node.children.some(item => item[type] === key)) {
            parentKey = node[type];
          } else if (temp = this.getParentKey(key, node.children, type)) {
            //parentKey = this.getParentKey(key,node.children)
            //改进，避免二次遍历
            parentKey = temp;
          }
        }
      }
      return parentKey;
    },
    //获取该节点的所有祖先节点
    getAllParentKey(key, tree, type) {
      var parentKey;
      if (key) {
        //获得父亲节点
        parentKey = this.getParentKey(key, tree);
        if (parentKey) {
          //如果父亲节点存在，判断是否已经存在于展开列表里，不存在就进行push
          if (!this.backupsExpandedKeys.some(item => item === parentKey)) {
            this.backupsExpandedKeys.push(parentKey);
          }
          //继续向上查找祖先节点
          this.getAllParentKey(parentKey, tree);
        }
      }
    },
    onExpand(expandedKeys) {
      console.log(expandedKeys);
      // //用户点击展开时，取消自动展开效果
      this.step2data.expandedKeys = expandedKeys;
      this.step2data.autoExpandParent = false;
    },
    onExpand2(expandedKeys) {
      console.log(expandedKeys);
      // //用户点击展开时，取消自动展开效果
      this.step2data.expandedKeys2 = expandedKeys;
      this.step2data.autoExpandParent2 = false;
    },
    onExpand_2(expandedKeys) {
      console.log(expandedKeys);
      // //用户点击展开时，取消自动展开效果
      this.step2data.expandedKeys2_2 = expandedKeys;
      this.step2data.autoExpandParent2_2 = false;
    },
    onExpand2_2(expandedKeys) {
      console.log(expandedKeys);
      // //用户点击展开时，取消自动展开效果
      this.step2data.expandedKeys3_2 = expandedKeys;
      this.step2data.autoExpandParent3_2 = false;
    },
    onExpand_3(expandedKeys) {
      console.log(expandedKeys);
      // //用户点击展开时，取消自动展开效果
      this.step2data.expandedKeys2_3 = expandedKeys;
      this.step2data.autoExpandParent2_3 = false;
    },
    onExpand2_3(expandedKeys) {
      console.log(expandedKeys);
      // //用户点击展开时，取消自动展开效果
      this.step2data.expandedKeys3_3 = expandedKeys;
      this.step2data.autoExpandParent3_3 = false;
    },
    onCheck(checkedKeys, info) {
      console.log(info.node.dataRef);
      console.log('onCheck', checkedKeys);
      this.CheckSureObj = {
        checkedKeys: checkedKeys,
        info: info,
        tabs: this.CheckSureObj.tabs
      }
      // this.form.permission_id_list = checkedKeys;
      // this.flitList[1].value = []
      // if (checkedKeys.length) {
      //   this.getTitleList(checkedKeys, this.treeData)
      // }
      // console.log(this.flitList[1].value, 'this.flitList[1].value');
    },
    onCheckSure() {
      let { checkedKeys, info, tabs } = this.CheckSureObj
      let that = this
      that.permissionInfo.info.permission_id_list = []
      that.flitList[1].value = []
      that.flitList[1].paramas = 'finance_category_id'

      that.CheckSureObj.tabs
      info.checkedNodes.forEach(el => {

        let a = that.CheckSureObj.tabs == 1 ? el.data.props.finance_category_id : that.CheckSureObj.tabs == 2 ? el.data.props.category_id : el.data.props.store_category_id
        that.permissionInfo.info.permission_id_list.push(a)
        that.flitList[1].value.push(el.data.props.category_name)
      })

      if (that.CheckSureObj.tabs == 1) {
        if (that.query.goods_category) that.query.goods_category = undefined
        if (that.query.store_category_id) that.query.store_category_id = undefined
        that.flitList[1].paramas = 'finance_category_id'
        that.query.finance_category_id = that.permissionInfo.info.permission_id_list
      }
      if (that.CheckSureObj.tabs == 2) {
        if (that.query.finance_category_id) that.query.finance_category_id = undefined
        if (that.query.store_category_id) that.query.store_category_id = undefined
        that.flitList[1].paramas = 'goods_category'
        that.query.goods_category = that.permissionInfo.info.permission_id_list
      }
      if (that.CheckSureObj.tabs == 3) {

        if (that.query.finance_category_id) that.query.finance_category_id = undefined
        if (that.query.goods_category) that.query.goods_category = undefined
        that.flitList[1].paramas = 'store_category_id'
        that.query.store_category_id = that.permissionInfo.info.permission_id_list
      }
      that.statusvisible = false;
      that.profuctTreeObj.visible = false;
      // that.getList()
    },
    getTitleList(arr, tree) {
      let newArr = this.treeToArray(tree)
      arr.forEach((el, idx) => {
        newArr.forEach((m, n) => {
          if (m.category_id == el) {
            this.flitList[1].value.push(m.category_name)
          }
        })
      })
      return newArr
    },
    // 数据扁平化
    treeToArray(tree) {
      return tree.reduce((res, item) => {
        const { children, ...i } = item
        return res.concat(i, children && children.length ? this.treeToArray(children) : [])
      }, [])
    },
    onSelect(selectedKeys, info) {
      console.log('onSelect', info);
      this.selectedKeys = selectedKeys;
    },
    getbrandsItem(i, v) {
      //
      if (i == 'catagory') {
        console.log(this.activeStatus_catagory);
        this.flitList[0].value = [v.text]
        let a = ''
        a = v.text == '客装' ? [1, 3] : v.text == '院装' ? [2, 4] : []
        if (v.text == '全部') {
          let arr = []
          this.filters_categorys.forEach(el => {
            if (el.text != '全部') {
              arr.push(el.text)
            }
          })
          // this.activeStatus_catagory = arr
          this.flitList[0].value = arr
          a = [1, 2, 3, 4]
        }

        this.query.goods_type = a
      }
      if (i == 'status') {
        console.log(this.activeStatus_status);
        // this.activeStatus_status = [v.text]
        this.flitList[2].value = [v.text]
        let a = ''
        a = v.text == '在售' ? 1 : 0
        if (v.text == '全部') {
          let arr = []
          this.filters_status.forEach(el => {
            if (el.text != '全部') {
              arr.push(el.text)
            }
          })
          // this.activeStatus_status = arr
          this.flitList[2].value = arr
          a = undefined
        }
        this.query.is_show = a
      }
      if (i == 'brands') {
        // this.activeStatus_brand = [v.brand_name]
        this.flitList[3].value = [v.brand_name]
        console.log(v, this.activeStatus_brand);
        if (v.brand_name == '全部') {
          let arr = []
          this.brandsList.forEach(el => {
            if (el.brand_name != '全部') {
              arr.push(el.brand_name)
            }
          })
          this.flitList[3].value = arr
          v.brand_id = undefined
        }
        this.query.goods_brand = v.brand_id ? v.brand_id : undefined
      }
      // 发送请求 
      // this.getList()
    },
    giveLimits(report) {
      this.limitsModel.visible = true
      let obj = {
        activeStatus1: {
          id: "1",
          name: "机构简称"
        },
        value: ''
      }

      let timers = setTimeout(() => {
        let o = document.querySelectorAll('.ant-transfer-list-header-selected')[0]
        if (o.innerHTML.includes('未授权机构')) {
          clearTimeout(timers)
          timers = ''
          return false
        }
        let o1 = document.querySelectorAll('.ant-transfer-list-header-selected')[1]
        let a = document.querySelectorAll('.ant-transfer-list-header-selected>span')[0]
        let b = document.querySelectorAll('.ant-transfer-list-header-selected>span')[2]
        var ospan = document.createElement("span");
        ospan.className = 'baseColor11'
        var ospan2 = document.createElement("span");
        ospan2.className = 'baseColor11'
        var oTxt = document.createTextNode("未授权机构 ");
        var oTxt2 = document.createTextNode("已授权机构 ");
        ospan.appendChild(oTxt);
        o.insertBefore(ospan, a);
        ospan2.appendChild(oTxt2);
        o1.insertBefore(ospan2, b);
        console.log(document.querySelectorAll('.ant-transfer-list-header-selected>span'));
        clearTimeout(timers)
        timers = ''
      }, 500)

      this.getListSearchlimits(obj)
    },


    async loadProduct(goods_type) {
      let that = this;
      let data = {
        "filter": {
          // "goods_type": [1, 2, 3, 4]
          "goods_type": goods_type
        },
        "limit": 500,
        "page": that.step2data.productPage
      }

      GetmanageGoodslist(data).then(res => {
        let productList = res.data.list ? res.data.list : [];
        // that.consumable.ztotal = res.data.total;
        if (productList.length > 0) {
          res.data.list.forEach(el => {
            that.step2data.mockData.push({
              key: el.goods_id.toString(),
              title: el.goods_name,
              goods_name: el.goods_name,
              goods_bn: el.goods_bn,
              goods_id: el.goods_id,
              finance_category_name: el.finance_category_name,
              goods_category_name: el.goods_category_name,
              store_category_name: el.store_category_name,
              goods_unit: el.goods_unit,
              brand_name: el.brand_name,
              goods_type: el.goods_type,
            })
          })
          that.step2data.productPage++;
          that.loadProduct(goods_type);
        } else {
          // 
          console.log(that.step2data.mockData, "------------mockData");//全部门店或者商城数据

        }

      })

    },
    async loadProduct2(goods_type) {
      let that = this;
      let data = {
        "filter": {
          "goods_type": goods_type
        },
        "limit": 500,
        "page": that.step2data.productPage_2
      }

      GetmanageGoodslist(data).then(res => {
        let productList = res.data.list ? res.data.list : [];
        // that.consumable.ztotal = res.data.total;
        if (productList.length > 0) {
          res.data.list.forEach(el => {
            that.step2data.mockData2.push({
              key: el.goods_id.toString(),
              title: el.goods_name,
              goods_name: el.goods_name,
              goods_bn: el.goods_bn,
              goods_id: el.goods_id,
              finance_category_name: el.finance_category_name,
              goods_category_name: el.goods_category_name,
              store_category_name: el.store_category_name,
              goods_unit: el.goods_unit,
              brand_name: el.brand_name,
              goods_type: el.goods_type,
              // company_parent_path: el.company_parent_path,
              // company_name_short: el.company_name_short,
              // isParent: el.company_parent_path.split(',')[1]
            })
          })
          that.step2data.productPage_2++;
          that.loadProduct2(goods_type);
        } else {
          // 
          console.log(that.step2data.mockData2, "------------mockData2");//全部门店或者商城数据
          // that.consumable.mockData2 = that.consumable.mockData3 = that.consumable.mockData


          // that.limitsModel.originalmockData = that.getCompanyData() //全部 数据

        }

      })

    },
    async loadProduct3(goods_type) {
      let that = this;
      let data = {
        "filter": {
          // "goods_type": [1, 2, 3, 4]
          "goods_type": goods_type
        },
        "limit": 100,
        "page": that.step2data.productPage_3
      }

      GetmanageGoodslist(data).then(res => {
        let productList = res.data.list ? res.data.list : [];
        // that.consumable.ztotal = res.data.total;
        if (productList.length > 0) {
          res.data.list.forEach(el => {
            that.step2data.mockData3.push({
              key: el.goods_id.toString(),
              title: el.goods_name,
              goods_name: el.goods_name,
              goods_bn: el.goods_bn,
              goods_id: el.goods_id,
              finance_category_name: el.finance_category_name,
              goods_category_name: el.goods_category_name,
              store_category_name: el.store_category_name,
              goods_unit: el.goods_unit,
              brand_name: el.brand_name,
              goods_type: el.goods_type,
              // company_parent_path: el.company_parent_path,
              // company_name_short: el.company_name_short,
              // isParent: el.company_parent_path.split(',')[1]
            })
          })
          that.step2data.productPage_3++;
          that.loadProduct3(goods_type);
        } else {
          // 
          console.log(that.step2data.mockData3, "------------mockData3");//全部门店或者商城数据
          // that.consumable.mockData2 = that.consumable.mockData3 = that.consumable.mockData


          // that.limitsModel.originalmockData = that.getCompanyData() //全部 数据

        }

      })

    },
    getGoodsname(e) {
      let a = pinyin(e.target.value, { toneType: 'none' })
      let b = a.split(' ')
      let arr = []
      b.forEach(el => {
        arr.push(el.slice(0, 1))
      })
      arr = arr.join('')
      this.form.goods_bn = arr
    },
    changepopways1(changepro3) {
      if (changepro3) {
        this.form.rule_entity_type = 1
        this.step2data.allgoodsorsingle = '1'
      } else {
        this.form.rule_entity_type = 0
      }
    },
    changepopways2(changepro3) {
      if (changepro3) {
        this.form.rule_service_type = 1
        this.step2data.allgoodsorsingle2 = '1'
      } else {
        this.form.rule_service_type = 0

      }
    },

    changepopways3(changepro3) {
      if (changepro3) {
        this.form.rule_proposal_type = 1
        this.step2data.allgoodsorsingle3 = '1'
      } else {
        this.form.rule_proposal_type = 0
      }
    },
    changefaceisedit(e) {
      this.form.is_edit_face_value = e ? 1 : 0
    },
    changeentityOptions(e) {
      let str = 'goods_name'
      let arr = []
      this.step2data.mockData.forEach((el, idx) => {
        if (el[str].includes(e.target.value)) {
          arr.push(el)
          this.step2data.mockData.splice(idx, 1);
        }
      })
      this.step2data.mockData = arr.concat(this.step2data.mockData)
      console.log(e.target.value, 'changeentityOptions');
    },
    changeentityOptions3(e) {
      console.log(e.target.value, 'changeentityOptions2');
      this.step2data.searchValue
      const value = e.target.value;
      let obj = this.step2data.mockDataTypes2.filter(item => { if (item && item.title.includes(value)) return item })
      let expandedKeys = []

      if (obj.length) {
        obj.forEach(el => {
          expandedKeys.push(el.key)
        })
      }
      // 
      Object.assign(this.step2data, {
        expandedKeys: expandedKeys,
        searchValue: value,
        autoExpandParent: true,
      });

    },
    changeentityOptions4(e) {
      console.log(e.target.value, 'changeentityOptions2');
      this.step2data.searchValue
      const value = e.target.value;
      let obj = this.step2data.mockDataTypes3.filter(item => { if (item && item.title.includes(value)) return item })
      let expandedKeys = []

      if (obj.length) {
        obj.forEach(el => {
          expandedKeys.push(el.key)
        })
      }
      // 
      Object.assign(this.step2data, {
        expandedKeys2: expandedKeys,
        searchValue: value,
        autoExpandParent2: true,
      });


    },
    changeentityOptions_2(e) {
      let str = 'goods_name'
      let arr = []
      this.step2data.mockData2.forEach((el, idx) => {
        if (el[str].includes(e.target.value)) {
          arr.push(el)
          this.step2data.mockData2.splice(idx, 1);
        }
      })
      this.step2data.mockData2 = arr.concat(this.step2data.mockData2)
      console.log(e.target.value, 'changeentityOptions_2');
    },
    changeentityOptions3_2(e) {
      console.log(e.target.value, 'changeentityOptions2');
      this.step2data.searchValue
      const value = e.target.value;
      let obj = this.step2data.mockDataTypes2_2.filter(item => { if (item && item.title.includes(value)) return item })
      let expandedKeys = []

      if (obj.length) {
        obj.forEach(el => {
          expandedKeys.push(el.key)
        })
      }
      // 
      Object.assign(this.step2data, {
        expandedKeys2_2: expandedKeys,
        searchValue2_2: value,
        autoExpandParent2_2: true,
      });

    },
    changeentityOptions4_2(e) {
      console.log(e.target.value, 'changeentityOptions2');
      this.step2data.searchValue
      const value = e.target.value;
      let obj = this.step2data.mockDataTypes3_2.filter(item => { if (item && item.title.includes(value)) return item })
      let expandedKeys = []

      if (obj.length) {
        obj.forEach(el => {
          expandedKeys.push(el.key)
        })
      }
      // 
      Object.assign(this.step2data, {
        expandedKeys3_2: expandedKeys,
        searchValue3_2: value,
        autoExpandParent3_2: true,
      });


    },
    changeentityOptions_3(e) {
      let str = 'goods_name'
      let arr = []
      this.step2data.mockData3.forEach((el, idx) => {
        if (el[str].includes(e.target.value)) {
          arr.push(el)
          this.step2data.mockData3.splice(idx, 1);
        }
      })
      this.step2data.mockData3 = arr.concat(this.step2data.mockData3)
      console.log(e.target.value, 'changeentityOptions_2');
    },
    changeentityOptions3_3(e) {

      console.log(e.target.value, 'changeentityOptions2');
      this.step2data.searchValue
      const value = e.target.value;
      let obj = this.step2data.mockDataTypes2_3.filter(item => { if (item && item.title.includes(value)) return item })
      let expandedKeys = []

      if (obj.length) {
        obj.forEach(el => {
          expandedKeys.push(el.key)
        })
      }
      // 
      Object.assign(this.step2data, {
        expandedKeys2_3: expandedKeys,
        searchValue2_3: value,
        autoExpandParent2_3: true,
      });

    },
    changeentityOptions4_3(e) {
      console.log(e.target.value, 'changeentityOptions2');
      this.step2data.searchValue
      const value = e.target.value;
      let obj = this.step2data.mockDataTypes3_3.filter(item => { if (item && item.title.includes(value)) return item })
      let expandedKeys = []

      if (obj.length) {
        obj.forEach(el => {
          expandedKeys.push(el.key)
        })
      }
      // 
      Object.assign(this.step2data, {
        expandedKeys3_3: expandedKeys,
        searchValue3_2: value,
        autoExpandParent3_3: true,
      });


    },

  },
  watch: {
    $route() {
      //  //
      // const { path, fullPath, meta, query, params, matched } = this.$route

    }
  },

  computed: {
    // product_types_tree3
    product_types_tree() {
      let that = this
      // console.log(that.mockDataTypes);
      return handleTreeData(that.treeDataA, that.step2data.targetKeysTypes);
    },
    product_types_tree2copy() {
      // console.log(this.step2data.mockDataTypes2,''); 
      return handleTreeData(this.product_types_tree2, this.step2data.targetKeysTypes2);

    },
    product_types_tree3copy() {
      return handleTreeData(this.product_types_tree3, this.step2data.targetKeysTypes3);
    },
    product_types_tree4() {
      return handleTreeData(this.brandsListcopy, this.step2data.targetKeysBrands);
    },
    // 2
    product_types_tree_2copy() {
      return handleTreeData(this.treeDataA, this.step2data.targetKeysTypes_2);
    },
    product_types_tree2_2copy() {
      return handleTreeData(this.product_types_tree2_2, this.step2data.targetKeysTypes2_2);
    },
    product_types_tree3_2copy() {
      return handleTreeData(this.product_types_tree3_2, this.step2data.targetKeysTypes3_2);
    },
    product_types_tree4_2() {
      return handleTreeData(this.brandsListcopy, this.step2data.targetKeysBrands_2);
    },
    // 3
    product_types_tree_3() {
      return handleTreeData(this.treeDataA, this.step2data.targetKeysTypes_3);
    },
    product_types_tree2_3copy() {
      return handleTreeData(this.product_types_tree2_3, this.step2data.targetKeysTypes2_3);
    },
    product_types_tree3_3copy() {
      return handleTreeData(this.product_types_tree3_3, this.step2data.targetKeysTypes3_3);
    },
    product_types_tree4_3() {
      return handleTreeData(this.brandsListcopy, this.step2data.targetKeysBrands_3);
    },

    treeData_barnds() {
      return handleTreeData(treeData, this.targetKeys);
    },
    treeDatas() {
      return handleTreeData(treeDatas, this.typesModel.targetKeys);
    },


  },
};
</script>
<style lang="less" scoped>
.add {
  display: flex;
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 14px 12px 13px;
  flex: 1;
  position: relative;
  .addleft {
    margin-right: 18px;
    width: 56px;
    height: 300px;
    z-index: 10;
    position: fixed;
    .onetxt {
      width: 16px;
      height: 16px;
      background: @stepDbgColor;
      border-radius: 16px;
      text-align: center;
      line-height: 16px;
      margin-bottom: 3px;
      font-size: 12px;
      font-family: Helvetica;
      color: #ffffff;
      margin-left: 33px;
    }
    .active {
      background: @stepAbgColor;
    }
    .base {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: @stepDfontColor;
      line-height: 20px;
      margin-bottom: 4px;
    }
    .active1 {
      color: @stepAfontColor;
      font-size: 14px;
      font-weight: 600;
    }
    .line {
      width: 1.5px;
      height: 32px;
      background: @steplineColor;
      margin: 0 5px 6px 40px;
    }
  }
  .addright {
    margin-left: 66px;
    padding-bottom: 20px;
    flex: 1;
    background: #ffffff;
    border-radius: 4px;
    position: relative;
    overflow: auto;
    /deep/.ant-col-4 {
      width: 136px !important;
    }
    .zengpinstep {
      padding: 31px 0 26px 28px;
      background: #fff;
      overflow: hidden;
      height: 100%;
      overflow-y: auto;
      width: 100%;
    }
    .mgl-28 {
      margin-left: 28px;
    }
    .zengpinstep2 {
      padding: 31px 0 26px 0px;
      position: relative;
      width: 100%;
    }
    .twoh1 {
      display: flex;
      margin-bottom: 20px;
      .tpointer {
        display: inline-block;
        width: 3px;
        height: 10px;
        background: @primaryColor;
        border-radius: 1px;
        margin: 4px 5px 0 0;
      }
      .ttxt {
        height: 22px;
        font-size: 14px;
        font-weight: 500;
        color: @fontColor1;
        line-height: 19px;
      }
    }
    .twoh2 {
      display: flex;
      margin-bottom: 10px;
      .tpointer {
        display: inline-block;
        width: 3px;
        height: 10px;
        background: @primaryColor;
        border-radius: 2px;
        margin: 7px 8px 0 0;
      }
      .ttxt {
        height: 22px;
        font-size: 18px;
        font-weight: 600;
        color: @fontColor1;
        line-height: 25px;
      }
    }
    .twoh3 {
      display: flex;
      margin-bottom: 12px;
      .tpointer {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: @primaryColor;
        border-radius: 1px;
        margin: 7px 8px 0 0;
      }
      .ttxt {
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: @fontColor1;
        line-height: 20px;
      }
    }
    .one {
      height: calc(100vh - 156px);
    }
    .two {
      height: calc(100vh - 156px);
      overflow-y: auto;
      // padding-bottom: 60px;
    }
    .three {
      height: calc(100vh - 156px);
    }
    .four {
      height: 100%;
    }
    .textColor1 {
      height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #131010;
      line-height: 22px;
      // margin-bottom: 14px;
      .xing {
        color: #f35e67;
        font-weight: 500;
      }
    }
    .textSmall {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @primaryColor;
      line-height: 26px;
      height: 26px;
      background: @gray-border-background;
      border-radius: 4px;
      opacity: 0.65;
      padding-left: 8px;
      margin-top: 13px;
    }
    .a-center {
      display: flex;
      align-items: flex-start;
    }
    .three-btns {
      padding-left: 50px;
      padding-bottom: 20px;
      margin-left: 2px;
      // position: fixed;
      // bottom: 23px;
      background: #ffffff;
      // padding-top: 20px;
      width: 83%;
      z-index: 10;
      // border-top: 1px solid @dropdownBs5a;
      border-radius: 0 0 4px 4px;
    }
    .textSmalls {
      font-size: 15px;
      font-weight: 400;
      color: #819190;
    }
  }
  /deep/ .ant-table-footer {
    background: @gray-border-background;
  }
}
.row-body {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    .text-title {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 400;
      color: @btnColorfont1;
    }
    .yuan-blue {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: #ffffff;
      border-radius: 100%;
      background: @primaryColor;
      border: 1px solid @dropdownBs5a;
    }
    .dian {
      color: #e5e8e5;
      margin: 0 10px;
    }
    .yuan {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: @fontColor7;
      border-radius: 100%;
      background: #fdfdff;
      border: 1px solid @btnColorshodew;
    }
  }
}
.goods_editor {
  width: 600px;
  height: 259px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #cdd5d8;
  overflow: hidden;
  overflow-y: auto;
}
.font-22 {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor1;
}
// 面包屑相关
.mrj-layout-tabs-custom {
  border-bottom: 1px solid @breadcrumbdownlineColor;
}
.leftarrow {
  color: @breadcrumbbackiconColor !important;
  border: 1px solid @breadcrumbbackborderColor !important;
  outline: none;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
  line-height: 10px;
  margin-top: 1px;
  padding-top: 1.2px;
  &:hover {
    color: @breadcrumbbackiconHColor !important;
    border: 1px solid @breadcrumbbackborderHColor!important;
    outline: none;
  }
  .arrleft {
    margin-top: -1px;
    &:hover {
      color: @baseColor70 !important;
    }
  }
  .meiye-fanghui {
    font-size: 14px;
    margin-left: -1px;
  }
}
.leftarrow_hover {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-index span {
  color: @baseColor11;
  font-size: 14px;
}
.mrj-layout-tabs-custom-index:hover .anticon {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-fenge {
  color: @breadcrumblineColor;
}
.mrj-layout-tabs-custom-inner-page {
  color: @fontColor4;
  font-size: 14px;
}
.samerow {
  width: 100%;
  /deep/.ant-form-item {
    display: flex;
    align-items: center;
    width: 100%;
  }
  /deep/ .ant-cascader-picker-label {
    display: inline-block;
  }
}
.samerow1 {
  /deep/ .ant-form-item-children {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
  }
}
.samerow2 {
  /deep/.ant-form-item-label-left {
    margin-left: 68px;
  }
}
#styledatas {
  div {
    width: auto !important;
  }
}
// 单选另一种框
.checkqystatus {
  width: 180px;
  height: 32px;
  line-height: 34px;
  background: @labelDbgColor;
  // box-shadow: inset 0px 3px 0px 0px @labelDinsertColor;
  border-radius: 4px;
  border: 1px solid @labelDborderColor;
  box-sizing: border-box;
  padding: 3px;
  font-size: 16px;
  .cqt1 {
    width: 90px;
    height: 28px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: @labelDfontColor;
    cursor: pointer;
    border: 1px solid transparent;
    text-align: center;
    line-height: 26px;
    &:hover {
      color: @labelHfontColor;
    }
  }
  .cqt1A {
    width: 90px;
    height: 28px;
    background: @labelAbgColor;
    box-shadow: inset 0px -2px 0px 0px @labelAinsertColor;
    // box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: 1px solid @labelAborderColor;
    color: @labelAfontColor;
    text-align: center;
    line-height: 26px;
  }
}
//单规格
.sigleguige {
  .tabelHeader {
    // width: 1605px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: rgba(241, 246, 245, 0.6);
    border-radius: 4px;
    position: relative;
    padding-left: 25px;
    display: flex;
    align-items: center;
    &::after {
      content: " ";
      width: 100%;
      height: 2px;
      background: #e8edec;
      position: absolute;
      top: -4px;
      left: 0;
    }
    .tabelHeader_items {
      width: 206px;
      text-align: left;
      display: inline-block;
    }
    .tabelHeader_items2 {
      width: 264px;
      text-align: left;
      display: inline-block;
    }
  }
  .tableBody {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    .tabelBody_items {
      width: 206px;
      text-align: left;
    }
  }
}
/deep/ .avatar-uploader .ant-upload {
  width: 48px !important;
  height: 48px !important;
}
/deep/.uploaderguige .ant-upload-picture-card-wrapper .ant-upload {
  width: 48px !important;
  height: 48px !important;
  padding: 0;
  margin: 0;
}
.baseColor11 {
  color: @baseColor11;
}
.htmlboxshops {
  &:focus-visible {
    border: none !important;
  }
}
.addGuigeRules {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #2d3835;
  i {
    color: #2d3835;
  }
}
.guigeline {
  width: 2px;
  height: 31px;
  background: #e8edec;
  margin: 0 0 0 48px;
}
.batchSet {
  height: 73px;
  background: #f1fafa;
  line-height: 73px;
  font-size: 14px;
  font-weight: 400;
  color: #2d3835;
  .batchSet_h2 {
    font-size: 14px;
    font-weight: 500;
    color: @fontColor4;
    margin: 0 52px 0 10px;
  }
  .guigeImg {
    height: 73px;
    line-height: 73px;
    .guigeImg_txt {
      font-size: 14px;
      display: inline-block;
      font-weight: 400;
      color: #2d3835;
      margin-right: 10px;
      width: 64px;
      height: 73px;
      line-height: 73px;
    }
    /deep/ .ant-upload-picture-card-wrapper .ant-upload {
      margin: 0;
      width: 48px !important;
      height: 48px !important;
      border-radius: 4px;
      // border: 1px dashed #9ba7a7;
    }
    /deep/.ant-upload.ant-upload-select-picture-card:hover {
      border-color: @primaryColor;
    }
  }
}
.singlePrice {
}
.guigeTablebox {
  margin-top: 8px;
}
.goods_specx_add {
  position: relative;
  .guigeline {
    position: absolute;
    top: 0;
    right: 0;
  }
}
/deep/ .avatar-uploader2.ant-upload-picture-card-wrapper .ant-upload {
  width: 66px !important;
  height: 66px !important;
  background: #fff;
}
/deep/.add_input {
  height: 32px;
  input {
    height: 32px;
  }
}
// 适用产品
.allselectgoods {
  .bot_box {
    // width: 600px;
    // overflow: hidden;
    // height: 40px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // margin-right: 40px;
    // position: relative;
    // padding-right: 40px;

    .bot_length {
    }
  }
  .bot_item {
    padding: 2px 8px;
    font-size: 14px;
    font-weight: 400;
    color: @baseColor11;
    margin: 0 12px 12px 0;
    background: #f3fafa;
  }
  .bot_open {
    color: @fontColor4;
  }
  .bot_box1 {
    // width: 100%;
    .bot_item {
      white-space: nowrap;
    }
  }
}
// 小页签
.zengpinstep .zengpinBody .ant_card_tabs {
  // 基本信息  基础配置
  width: 100%;
  background: #fff;
  & /deep/ .ant-tabs-bar // ,

// &.ant-tabs-tab
  {
    height: 49px !important;
    line-height: 49px !important;
    padding: 20px 0 0 0px;
    background: #fff !important;
    margin: 0px;
  }

  & /deep/ .ant-tabs-tab {
    height: 29px !important;
    line-height: 29px !important;
    background: #fff !important;
    border: 1px solid #d3dedc !important;
    border-color: #d3dedc !important;
    // border-bottom: 1px solid #fff !important;
    background: transparent !important;
    font-size: 15px !important;
  }

  & /deep/ .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
  & /deep/ .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
    margin: 0 !important;
    height: 29px !important;
    font-weight: 600 !important;
    line-height: 29px !important;
    background: #fff;
    border-color: #e8e8e8;
    border-bottom: 1px solid #fff;
    font-size: 15px !important;
    color: #5b5a62;
    background: transparent !important;
    // padding: 20px 0 0 30px;
    // background: #fff !important;
  }

  & /deep/ .ant-tabs-tab {
    &::before {
      content: "";
      display: none;
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      bottom: -0.5px;
      background: url(~@/assets/images/tabs-unselected-1.png) no-repeat 0 0;
      background-size: 100% 100%;
      opacity: 1;
    }

    &::after {
      content: "";
      display: none;
      position: absolute;
      z-index: -2;
      left: 0;
      width: 100%;
      height: 1px;
      bottom: 0px;
      background: rgb(244, 245, 247);
      background-size: 100% 100%;
      opacity: 1;
    }
  }

  & /deep/ .ant-tabs-tab-active {
    color: @baseColor11 !important;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #fff !important;
    &::before {
      content: "";
      display: none;
      position: absolute;
      z-index: -1;
      top: -2px;
      left: 0;
      right: 0;
      bottom: -0.5px;
      background: url(~@/assets/images/tabs-unselected-1.png) no-repeat 0 0;
      background-size: 100% 100%;
      opacity: 1;
    }
  }

  .basic_name {
    color: @fontColor4;
    p {
      margin-bottom: 0;
      padding: 0;
    }
    .ba_p2 {
      color: @fontColor5;
      margin-top: 3px;
    }
  }

  .basicSet {
  }
}
.typetoselect {
  width: 260px;
  height: 32px;
  padding: 0 24px 0 12px;
  line-height: 32px;
  border-radius: 4px;
  color: @selectfontAColor;
  background: @selectbgDColor;
  border: 1px solid @selectborderDColor;
  box-shadow: 0px 2px 3px 0px @selectoutDColor,
    inset 0px -2px 0px 0px @selectinsertDColor;
  position: relative;
  &:hover,
  &:focus {
    color: @selectfontAColor;
    background: @selectbgHColor;
    border: 1px solid @selectborderHColor;
    box-shadow: 0px 2px 3px 0px @selectoutHColor,
      inset 0px -2px 0px 0px @selectinsertHColor;
    .meiye-moren1 {
      color: @baseColor11;
    }
  }
  .meiye-moren1 {
    position: absolute;
    right: 9px;
    top: 1px;
  }
  .meiye-moren2 {
    display: inline-block;
    transform: rotate(180deg);
  }
  .typetoselect_txt {
    display: inline-block;
    width: 100%;
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.proructSelectd {
  top: 37px;
  left: 154px;
  width: 358px;
  height: 368px;
  text-align: center;
  padding: 12px 0px 0;
  background: #ffffff;
  box-shadow: 0px 6px 8px 0px rgba(99, 161, 148, 0.08),
    0px 6px 10px 0px rgba(0, 0, 0, 0.08),
    inset 0px -3px 0px 0px rgba(205, 231, 231, 0.49);
  border-radius: 6px;
  border: 1px solid #e4e9e9;
  z-index: 1;
}
.textColor1 {
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #131010;
  line-height: 22px;
  .xing {
    color: #f35e67;
    font-weight: 500;
  }
}
.typesHead {
  margin: 0 12px 12px;
  /deep/ .owninputbox_search {
    text-align: left;
  }
}
.typesBody {
  flex: 1;
  overflow: auto;
  margin: -9px 12px 0;
}
.typesFooter {
  width: 356px;
  height: 58px;
  line-height: 58px;
  border-top: 1px solid #e8edec;
  background: #ffffff;
  padding: 0px 12px;
  align-items: center;
  justify-content: space-between;
}
/deep/.owninputbox .owninputbox_search {
  width: 100%;
}
.placeholercolor {
  color: @fontColor3;
}
.bfComposeInput {
  position: absolute;
  width: 500px;
  height: 38px;
  top: -34px;
  right: 0;
}
/deep/ .limits_body .ant-table-placeholder {
  width: 164px;
}
.transferBox {
  position: relative;
}
.transferPro {
  .transferProTable {
    border: 1px solid #ccdada;
    border-right: none;
  }

  /deep/ .ant-table-thead > tr:first-child > th:last-child {
    border-radius: 0 0px 0 0 !important;
  }
}

.transferPro_tip {
  color: @fontColor4;
  font-weight: 600;
  font-size: 16px;
  height: 40px;
}
.transferPro_tip_sp {
  font-size: 16px;
  font-weight: 500;
  color: #9ea6a4;
  margin-left: 8px;
}
.transferPro_tip_po {
  display: inline-block;
  width: 3px;
  height: 10px;
  background: @primaryColor;
  border-radius: 2px;
  margin-right: 6px;
}
.pdr-32 {
  padding-right: 32px;
}
.ant-transfer_1 {
  // margin-top: 38px;
  padding-right: 32px;
  /deep/ .ant-transfer-list-header {
    display: none;
  }
  /deep/ .ant-transfer-list-body {
    margin-top: -38px;
    height: 569px;
  }
  /deep/.addBox .add .ant-transfer-list {
    width: 575px !important;
  }
  /deep/ .ant-table-placeholder {
    // width: 572px;
  }
  /deep/ .ant-table-small > .ant-table-content .ant-table-header {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: 1px solid #ccdada;
    overflow: hidden !important;
  }
  /deep/ .ant-transfer-list .ant-transfer-list-body {
    border: 1px solid #ccdada;
    border-radius: 4px;
    height: 569px;
  }
  /deep/.ant-table-small > .ant-table-content .ant-table-header {
    background-color: transparent;
    border-radius: 0;
  }
}
/deep/.ant-transfer-list-body-not-found {
  position: absolute;
  top: 4%;
}
.transfer_selected_tips {
  position: absolute;
  // left: 845px;
  left: 652px;
  top: 0;
}
.transferaPQ {
  color: @fontColor4;
  font-size: 14px;
  font-weight: 500;
  color: #2d3835;
  height: 20px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  width: 114px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.transferaPQ2 {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor5;
  height: 17px;
  line-height: 17px;
  margin: 0;
  padding: 0;
  width: 114px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.transferBox {
  /deep/ .ant-table-body {
    tr > td {
      height: 64px;
      line-height: 64px;
    }
  }
}
.blockTypes {
  width: 80px;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.typesbox2 {
  padding-bottom: 375px;
}
.boldimgspan {
  position: relative;
  .meiye-close-boldimg {
    position: absolute;
    top: -21px;
    right: -8px;
    font-size: 12px;
  }
}
.font-12 {
  font-size: 12px;
}

.prostep2h {
  margin: 27px 0 24px 0;
  .sypro {
    font-size: 16px;
    font-weight: 600;
    color: @fontColor4;
    margin-left: 6px;
  }
  .mgr-6 {
    margin-right: 6px;
  }
  .meiye-jiantou {
    margin: 0 2px 0 9px;
  }
  .meiye-jiantou2 {
    transform: rotate(180deg);
  }
  /deep/ .ant-table-placeholder {
    width: 100% !important;
  }
}
.mgb-10 {
  margin-right: 10px;
}
.color11 {
  color: @baseColor11;
}
/deep/.ant-transfer_1 .ant-transfer-list {
  border: none !important;
  // width: 575px !important;
}

/deep/.ant-transfer_1 .ant-transfer-list .ant-transfer-list-body {
  // border: none;
  border-radius: 4px;
}
/deep/.ant-transfer-customize-list .ant-transfer-list-body-customize-wrapper {
  padding: 0 0 12px;
}
/deep/.ant-transfer-list-header-selected {
  position: relative;
}

/deep/.ant-transfer-list-header-title {
  position: absolute;
  display: inline-block;
  width: 100px;
  left: 0px;
  top: 2px;
  background: #ebf7f7;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor4;
  display: inline-block;
  padding-left: 12px;
}
/deep/.ant-transfer-list {
  width: 575px !important;
}
/deep/.ant-transfer-list-header {
  background: #ebf7f7;
  border-color: #ccdada;
}
/deep/.ant-transfer-list-body {
  border: 1px solid #ccdada;
  border-radius: 0;
  height: 569px;
  // overflow: hidden;
  // overflow-y: auto;
}
/deep/ .ant-transfer-list-body .ant-table-thead > tr > th:first-child {
  border-radius: 4px 0 0 0 !important;
}
/deep/ .ant-transfer-list-body .ant-table-thead > tr > th:last-child {
  border-radius: 0px 4px 0 0 !important;
}
/deep/ .ant-transfer-list-body .ant-table-thead > tr > th {
  padding: 13px 10px !important;
  background: #ebf7f7 !important;
  border-bottom: none !important;
  box-shadow: none;
}

/deep/.ant-transfer-list-body .ant-table-thead > tr > th:first-child {
  padding-left: 13px !important;
}
/deep/.ant-table-placeholder {
  border-color: #ccdada;
}
/deep/.tree-transfer .ant-transfer-list {
  width: 575px !important;
}
/deep/ .tree-transfer1 .ant-tree li {
  // border-bottom: 1px solid #d2e6e4;
}
/deep/ .tree-transfer1 .ant-transfer-list-content-item {
  // border-bottom: 1px solid #d2e6e4;
}
.tree-transfer {
  padding-right: 32px;
}
/deep/.tree-transfer .ant-transfer-list-body {
  border: none !important;
  overflow: auto;
}
/deep/.mgb-18.ant-form-item {
  margin-bottom: 18px;
}
.wid-132 {
  width: 132px;
}
.mgb10 {
  margin-bottom: 10px;
}
/deep/.ant-table-row > td:nth-child(1),
.ant-table-thead > tr:first-child > th:first-child {
  padding-left: 13px !important;
}
/deep/ .ant-tree li span.ant-tree-checkbox {
  margin-left: 0px;
}
/deep/.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
  border: 1px solid @btn2mianboderDColor !important;
  background-color: @btn2mianbgDColor !important;
  box-shadow: 0 2px 4px 0 @btn2mianOutDColor,
    inset 0 -2px 0 0 @btn2mianInsertDColor !important;

  box-shadow: inset;
  color: #414041 !important;
}
.float-r {
  float: right;
}
.wid-180 {
  width: 180px;
}
.wid-240 {
  width: 240px;
}
.selectstep2 {
  height: 32px;
  margin-right: 32px;
  .float-r {
    margin-top: -32px;
  }
}
/deep/ .ant-input_1 .ant-input:not(:first-child) {
  padding-left: 22px !important;
}
/deep/ .ant-transfer-list-content-item {
  padding: 6px 24px;
}
.addBox .add .mgb-32 {
  margin-bottom: 32px;
}
/deep/.ant-upload.ant-upload-select-picture-card {
  background: #fff;
}
/deep/.stepbackbtn {
  border: 1px solid @btn2mianboderDColor !important;
  box-shadow: 0px 2px 4px 0px @btn2mianOutDColor,
    inset 0px -2px 0px 0px @btn2mianInsertDColor !important;
  background-color: @btn2mianbgDColor !important;
  color: @btn2mianfontDColor !important;

  &:active {
    color: @btn2mianfontFColor !important;
    border: 1px solid @btn2mianboderFColor !important;
    background-color: @btn2mianbgFColor !important;
    box-shadow: 0px 2px 4px 0px @btn2mianOutFColor,
      inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  }

  &:focus {
    color: @btn2mianfontFColor !important;
    border: 1px solid @btn2mianboderFColor !important;
    background-color: @btn2mianbgFColor !important;
    box-shadow: 0px 2px 4px 0px @btn2mianOutFColor,
      inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  }

  &:hover {
    color: @btn2mianfontHColor !important;
    border: 1px solid @btn2mianboderHColor !important;
    background-color: @btn2mianbgHColor;
    box-shadow: 0px 2px 4px 0px @btn2mianOutHColor,
      inset 0px -2px 0px 0px @btn2mianInsertHColor;
  }
}
.last-btns-step2 {
  height: 68px;
  width: calc(100vw - 278px);
  position: fixed;
  bottom: 21px;
  padding-top: 16px;
  background: #fff;
  border-top: 1px solid #d8eceb;
  z-index: 100;
}
/deep/.ant-table-scroll > ::-webkit-scrollbar {
  background: #fff;
}
/deep/ .ant-input_2 .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
/deep/.ant-input_2.ant-input-affix-wrapper .ant-input-prefix {
  left: 9px;
}
.font-18 {
  font-size: 18px;
}
/deep/.ant-tree li .ant-tree-node-content-wrapper {
  height: 32px;
  line-height: 32px;
}
/deep/.ant-tree li span.ant-tree-checkbox {
  top: initial;
  // height: 10px;
  margin: 4px 4px 0 2px;
  padding: 4px 0;
}
/deep/ .LazyLoad.is-visible {
  height: 40px !important;
  line-height: 40px;
}
/deep/.ant-transfer-list-content-item {
  padding: 0px 24px;
}
/deep/.ant-tree-child-tree > li:first-child {
  padding-top: 4px;
}
// /deep/.ant-tree-child-tree > li:last-child {
//   padding-bottom: 0px;
// }
</style>