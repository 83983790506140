<template>
  <div class="addBox grounding">
    <!--  面包屑  -->
    <div
      class="mrj-layout-tabs-custom flex align-item-center"
      style="z-index:1;"
    >
      <div
        @click="changeRoute"
        class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer"
      >
        <div
          type="left"
          class="leftarrow"
          :class="imgflag?'leftarrow_hover':''"
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >
          <i class="meiye-icon meiye-fanghui"></i>
        </div>

        <span
          @mouseenter="imgflag=true"
          @mouseleave="imgflag=false"
        >{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page">上架</span>
    </div>
    <!-- 内容区 -->
    <div
      class="add"
      v-if="!pushForm.isshowbloc"
    >
      <div class="addleft">
        <div class="leftbox">
          <div
            class="onetxt"
            @click="goStep(1)"
            :class="step===1?'active':''"
          >1</div>
          <div
            class="base"
            @click="goStep(1)"
            :class="step===1?'active1':''"
          >上架渠道</div>
          <div class="line"></div>
          <div
            class="onetxt"
            @click="goStep(2)"
            :class="step===2?'active':''"
          >2</div>
          <div
            class="base"
            @click="goStep(2)"
            :class="step===2?'active1':''"
          >上架范围</div>
          <div class="line"></div>
          <div
            class="onetxt"
            @click="goStep(3)"
            :class="step===3?'active':''"
          >3</div>
          <div
            class="base"
            @click="goStep(3)"
            :class="step===3?'active1':''"
          >上架数据</div>
          <!-- <div class="line"></div> -->
        </div>
      </div>
      <div class="addright">
        <!-- 第一步 -->
        <div
          class="one"
          v-if="step===1"
        >
          <div class="zengpinstep">
            <h2 class="twoh2">
              <span class="tpointer"></span>
              <span class="ttxt">选择上架渠道</span>
            </h2>
            <div
              class="card_i flex align-item-center"
              style="margin-bottom:50px;"
            >
              <div
                class="card_i_shop"
                @click="isShopOrBloc=3;"
                :class="isShopOrBloc==3?'card_i_shopA':''"
              >
                <img
                  class="card_i_shop-img"
                  src="../../../assets/images/shop.png"
                  alt=""
                >
                <div class="left_opacity"></div>
                <div class="card_i_names">门店</div>
                <div class="card_i_names2">上架到门店</div>
                <div class="right_opacity"></div>
              </div>
              <div
                class="card_i_shop card_i_mall"
                @click="isShopOrBloc=2;"
                :class="isShopOrBloc==2?'card_i_shopA':''"
              >
                <img
                  class="card_i_shop-img"
                  src="../../../assets/images/bloc.png"
                  alt=""
                >
                <div class="card_i_names">连锁商城</div>
                <div class="card_i_names2">上架到连锁商城</div>
              </div>
            </div>
            <p
              class="p_sh"
              v-if="$route.query.type == 2"
            >上架商品 {{ 1 }}/{{ 1 }}</p>
            <p
              class="p_sh"
              v-if="$route.query.type == 4"
            >上架商品 {{ plshopsList.length }}/{{ targetPlshopsList.length }}</p>
            <div
              class="g_shops"
              v-if="$route.query.type == 2"
            >
              <span class="g_shops_name">{{ form.goods_name }}</span>
              <span>商品码{{form.goods_bn}}</span>
            </div>
            <div
              class=""
              v-if="$route.query.type == 4"
            >
              <div
                class="g_shops"
                v-for="(it,idx) in plshopsList"
                :key="idx"
              >
                <span>
                  <a-checkbox
                    class="nomal_checkbox"
                    :checked="it.isCheced"
                    @change="event=>onChangeCard(event,it)"
                    style="margin-right:8px;"
                  >
                  </a-checkbox>
                  <span class="g_shops_name">{{it.goods_name}}</span>
                  <span>商品码{{it.goods_bn}}</span>
                </span>

              </div>

            </div>
          </div>
          <!-- <hl-transfer
            :mockData="mockData"
            title="company_name"
            id="company_id"
            :targetKeys="targetKeys"
          /> -->
          <div
            class="three-btns"
            style="padding-left: 28px;padding-top:8px;"
          >
            <a-space :size="20">
              <a-button
                type="primary"
                @click="onStep(2)"
                class="stepbtnnext1   bigheightbn wid-80"
              >下一步</a-button>
              <a-button
                class="stepbackbtn   bigheightbn wid-80"
                @click="cancel()"
              >取消</a-button>
            </a-space>

          </div>
        </div>
        <!-- 第二步 -->
        <div
          class="three"
          v-if="step===2"
        >
          <div class="zengpinstep zengpinstep2">
            <h2 class="twoh2 mgl-28">
              <span class="tpointer"></span>
              <span class="ttxt">选择上架范围</span>
            </h2>
            <div class="grpunding_transfer mgl-28">
              <div class="limits_body">
                <!-- 复合搜索框 -->
                <ComposeInput
                  :selectArr="selectArr3"
                  placeholderValue="输入关键字"
                  @getList="getListSearchlimits"
                  :styles="typestyles2"
                ></ComposeInput>
                <!--门店 穿梭框 -->
                <div
                  class="transferBox flex"
                  v-if="isShopOrBloc==3"
                >
                  <div class="transferPro">
                    <div class="transferPro_tip">
                      <span
                        style="margin-right:0px;"
                        class="transferPro_tip_po transferPro_tip"
                      ></span>
                      全部门店
                      <span class="transferPro_tip_sp">共{{ limitsModel.mockData.length  }}条</span>
                    </div>
                    <a-table
                      :columns="limitsModel.proColumns"
                      :data-source="limitsModel.provinceList"
                      :row-selection="rowSelection"
                      class="transferProTable"
                      :pagination='false'
                      :rowKey="record=>record.key"
                    >
                    </a-table>
                  </div>
                  <a-transfer
                    :data-source="limitsModel.mockData"
                    :target-keys="limitsModel.targetKeys"
                    :show-select-all="false"
                    :selected-keys="limitsModel.selectedRowKeys"
                    @change="onlimitsChange"
                    :replaceFields="limitsModel.limitsreplaceFields"
                    class="ant-transfer_1"
                    v-if="limitsModel.transferShow"
                  >
                    <!-- :list-style="{
                        width: '575px', 
                    }" -->
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, filteredItems,  selectedKeys, disabled: listDisabled }, on: { itemSelectAll, itemSelect }, }"
                    >
                      <a-table
                        :pagination="false"
                        :row-selection="getRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })"
                        :columns="direction === 'left' ? limitsModel.leftColumns : limitsModel.rightColumns"
                        :data-source="filteredItems"
                        :scroll="{y:522}"
                        size="small"
                        :style="{ pointerEvents: listDisabled ? 'none' : null }"
                        :rowKey="record=>record.key"
                      />
                    </template>
                  </a-transfer>
                  <div class="transfer_selected_tips transferPro_tip"><span class="transferPro_tip_po"></span> 已选门店 <span class="transferPro_tip_sp">共{{  limitsModel.targetKeys.length }}条</span> </div>
                </div>
                <!-- 连锁穿梭框 -->
                <div
                  class="transferBox flex"
                  v-if="isShopOrBloc==2"
                >
                  <div class="transferPro_tip lstransfertxt">
                    <span
                      style="margin-right:0px;"
                      class="transferPro_tip_po transferPro_tip"
                    ></span>
                    全部连锁商家
                    <span class="transferPro_tip_sp">共{{ lslimitsModel.mockData.length  }}条</span>
                  </div>

                  <a-transfer
                    :data-source="lslimitsModel.mockData"
                    :target-keys="lslimitsModel.targetKeys"
                    :show-select-all="false"
                    :selected-keys="lslimitsModel.selectedRowKeys"
                    @change="onlslimitsChange"
                    :replaceFields="lslimitsModel.limitsreplaceFields"
                    class="ant-transfer_1"
                    v-if="lslimitsModel.transferShow"
                  >
                    <template
                      slot="children"
                      slot-scope="{ props: { direction, filteredItems,  selectedKeys, disabled: listDisabled }, on: { itemSelectAll, itemSelect }, }"
                    >
                      <a-table
                        :pagination="false"
                        :row-selection="lsgetRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })"
                        :columns="direction === 'left' ? lslimitsModel.leftColumns : lslimitsModel.rightColumns"
                        :data-source="filteredItems"
                        :scroll="{y:522}"
                        size="small"
                        :style="{ pointerEvents: listDisabled ? 'none' : null }"
                        :rowKey="record=>record.key"
                      />
                    </template>
                  </a-transfer>
                  <div class="transfer_selected_tips transfer_selected_tips2 transferPro_tip"><span class="transferPro_tip_po"></span> 已选 <span class="transferPro_tip_sp">共{{  lslimitsModel.targetKeys.length }}条</span> </div>
                </div>
              </div>
            </div>
            <div class="last-btns  last-btns-step2">
              <a-row
                class="flex "
                style="margin-bottom:42px;"
              >
                <a-col :span="0">
                </a-col>
                <a-col :span="19">
                  <a-space :size="20">
                    <a-button
                      type="primary"
                      @click="onStep(3)"
                      class="stepbtnnext1  mgl-28  bigheightbn wid-80"
                    >下一步</a-button>
                    <a-button
                      class="stepbackbtn   bigheightbn wid-80"
                      :disabled="pushForm.isprev"
                      @click="onStep(1)"
                    >上一步</a-button>
                  </a-space>
                </a-col>
              </a-row>

            </div>
          </div>

        </div>
        <!-- 第三步 -->
        <div
          class="two"
          v-if="step===3"
        >
          <div class="zengpinstep">
            <h2
              class="twoh2"
              style="margin-bottom: 10px;"
            >
              <span class="tpointer"></span>
              <span class="ttxt">完善上架数据</span>
            </h2>
            <div class=" ">
              <!-- 基本信息 基础配置 -->
              <a-row
                class="flex "
                style="margin:28px 0 30px 0;"
              >
                <a-col :span="4">
                  <div class="textColor1">储蓄卡名称 </div>
                </a-col>
                <a-col :span="19">
                  <div class="flex">
                    <div class="basic_name">
                      <p class="ba_p1">{{form.goods_name}}</p>
                      <p class="ba_p2"><i class="meiye-icon meiye-fangan"></i> 商品码：{{ form.goods_bn }}</p>
                      <!-- <span
                        class="toeditoricon"
                        @click="goEditor($route.query.id)"
                      ><i class="meiye-icon meiye-bianji"></i></span> -->
                    </div>
                  </div>
                </a-col>
              </a-row>
              <a-row
                class="flex align-item-center"
                style="margin-bottom:30px;"
              >
                <a-col :span="4">
                  <div class="textColor1">
                    <span>商品别名<span
                        class="xing"
                        style="color:#F35E67"
                      >*</span></span>
                  </div>
                </a-col>
                <a-col :span="19">
                  <a-input
                    class="ant-input_1"
                    style="width:488px"
                    v-model="form.goods_alias_name"
                    placeholder="输入商品别名，20字内"
                    :maxLength="20"
                  ></a-input>
                </a-col>
              </a-row>
              <a-row
                class="flex align-item-center"
                style="margin-bottom:30px;"
                v-if="isShopOrBloc==2"
              >
                <a-col :span="4">
                  <div class="textColor1">
                    <span class=" textColor1">商城售价<span
                        class="xing"
                        style="color:#F35E67"
                      >*</span></span>
                  </div>
                </a-col>
                <a-col
                  :span="19"
                  class="flex align-item-center"
                >
                  <a-input
                    style="width:138px;"
                    prefix="￥"
                    class="ant-input_1"
                    v-model="form.goods_attr_val[0].product_price"
                    placeholder=""
                    oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>1000000)value=1000000;if(value<0)value=null;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
                  >
                  </a-input>
                </a-col>
              </a-row>
              <a-row
                class="flex align-item-center"
                style="margin-bottom:30px;"
                v-if="isShopOrBloc==2"
              >
                <a-col :span="4">
                  <div class="textColor1">
                    <span>虚拟销量</span>
                  </div>
                </a-col>
                <a-col
                  :span="19"
                  class="flex align-item-center"
                >
                  <a-input
                    v-model="form.product_show_sales"
                    style="width:154px;height:36px;line-height: 36px;margin-right: 12px;"
                    placeholder=""
                    class="add_input  ant-input_1"
                    :maxLength="7"
                    @change="changenumIpt"
                    @blur="changenumIpt"
                  />
                  <!-- oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>1000000)value=1000000;if(value<0)value=null;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);" -->

                  <div class="flex align-item-center">
                    <span
                      style="margin:0 57px 0 97px;"
                      class="textColor1"
                    >是否推荐<span
                        class="xing"
                        style="color:#F35E67"
                      >*</span></span>
                    <div
                      class="checkqystatus flex align-item-center"
                      style="width:214px;"
                    >
                      <span
                        class="cqt1"
                        style="width:103px"
                        @click="form.is_recommend=0"
                        :class="form.is_recommend==0? 'cqt1A':''"
                      >不推荐</span>
                      <span
                        class="cqt1"
                        style="width:103px"
                        @click="form.is_recommend=1"
                        :class="form.is_recommend==1? 'cqt1A':''"
                      >推荐</span>
                    </div>
                  </div>
                </a-col>
              </a-row>
              <a-row
                class="flex align-item-center"
                style="margin-bottom:30px;"
                v-if="isShopOrBloc==3"
              >
                <a-col :span="4">
                  <div class="textColor1">
                    <span>门店售价<span
                        class="xing"
                        style="color:#F35E67"
                      >*</span></span>
                  </div>
                </a-col>
                <a-col
                  :span="19"
                  class="flex align-item-center"
                >
                  <a-input
                    v-model="form.goods_attr_val[0].product_price"
                    style="width:154px;height:36px;line-height: 36px;margin-right: 12px;"
                    placeholder="请输入"
                    prefix="￥"
                    class="add_input ant-input_1"
                    :maxLength="7"
                    oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>1000000)value=1000000;if(value<0)value=null;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
                  />
                  <!-- oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>1000000)value=1000000;if(value<0)value=null;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);" -->

                </a-col>
              </a-row>
              <a-row
                class="flex align-item-center"
                style="margin-bottom:0px;"
              >
                <a-col :span="4">
                  <div class="textColor1">
                    <span>状态<span
                        class="xing"
                        style="color:#F35E67"
                      >*</span></span>
                  </div>
                </a-col>
                <a-col
                  :span="19"
                  class="flex align-item-center"
                >
                  <a-col :span="19">
                    <div class="flex">
                      <div class="basicPz_2 flex align-item-center">
                        <div class="checkqystatus flex align-item-center">
                          <span
                            class="cqt1"
                            @click="form.is_show=1;form.goods_shou_type=1"
                            :class="form.is_show==1? 'cqt1A':''"
                          >上架</span>
                          <span
                            class="cqt1"
                            @click="form.is_show=0"
                            :class="form.is_show==0? 'cqt1A':''"
                          >下架</span>
                        </div>
                        <span
                          style="min-width: 364px;display:inline-block;margin-left:24px;"
                          class="flex align-item-center"
                          v-if="form.is_show"
                        >
                          <a-radio-group
                            name="radioGroup"
                            class="nomal_radio radio_nomal1"
                            v-model="form.goods_shou_type"
                          >
                            <a-radio :value="1">
                              长期
                            </a-radio>
                            <a-radio :value="2">
                              截止
                            </a-radio>
                          </a-radio-group>
                          <!-- 日期选择器 -->
                          <a-date-picker
                            v-if="form.goods_shou_type==2"
                            v-model="form.goods_shou_end_time"
                            :class="form.goods_shou_type==2?'jzpicker1':'jzpicker'"
                            @change="onChangeTimes"
                            @openChange="isactive = !isactive"
                            format="YYYY-MM-DD HH:mm:ss"
                            :disabled-date="disabledDate"
                            :show-time="{}"
                            class="data_picker"
                          >
                            <i
                              slot="suffixIcon"
                              class="meiye-icon meiye-rili-moren "
                              :class="{ 't-c-g': isactive }"
                              style="font-size:12px;color: @baseColor40;background: @baseColor41;margin-top: -5px;"
                            ></i>
                          </a-date-picker>
                        </span>

                      </div>

                    </div>
                  </a-col>
                </a-col>
              </a-row>
              <a-row
                class="flex align-item-center"
                style="margin-top:30px;"
                v-if="isShopOrBloc == 2"
              >
                <a-col :span="4">
                  <div class="textColor1">
                    <span>商品标签</span>
                  </div>
                </a-col>
                <a-col :span="19">
                  <div class=" ">
                    <a-select
                      allow-clear
                      mode="multiple"
                      :maxTagCount="2"
                      class="pgb-20 selectGg"
                      placeholder="选择标签"
                      v-model="lslimitsModel.goods_id"
                      style="width: 260px;margin-right:20px;"
                      @change="changetagsSpecs"
                      dropdownClassName="selectGg"
                    >
                      <a-select-option
                        v-for="it in lslimitsModel.goods_is_list"
                        :key="it.goods_tag_id"
                        :value="it.goods_tag_id"
                      >
                        <i
                          class="meiye-icon meiye-routeicon-add-copy baseColor11"
                          v-if="it.goods_tag_id=='9999'"
                        ></i>

                        {{ it.tag_name }}
                      </a-select-option>
                    </a-select>
                  </div>

                </a-col>
              </a-row>
            </div>
          </div>
          <div class="three-btns">
            <a-space :size="20">
              <a-button
                v-if="pushForm.openDatails"
                type="primary"
                @click="okclickmod2();"
                class="stepbtnnext1 bigheightbn wid-80"
              >保存</a-button>
              <a-button
                v-else
                type="primary"
                @click="okclickmod();"
                class="stepbtnnext1 bigheightbn wid-80"
              >保存</a-button>
              <a-button
                :disabled="pushForm.isprev"
                class="stepbackbtn bigheightbn wid-80"
                @click="onStep(2);"
              >上一步</a-button>
              <a-button
                v-if="!pushForm.isprev"
                class="stepbackbtn bigheightbn wid-80"
                @click="cancel()"
              >取消</a-button>
              <a-button
                v-else
                class="stepbackbtn bigheightbn wid-80"
                @click="cancle3()"
              >取消</a-button>
            </a-space>

          </div>
        </div>
      </div>
      <a-modal
        title="新增规格"
        :visible="showaddGoodsprivatespec"
        @cancel=";qyStep = 2;showaddGoodsprivatespec=false;isShowGuigeAddSelect=false"
        @ok="addguigeokFn"
        :width="600"
      >
        <div>
          <a-form
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 18 }"
            labelAlign="left"
          >
            <a-form-item
              label="规格名称"
              style="margin-bottom:0;"
            >
              <a-input
                style="min-width: 427px;"
                class="ant-input_1"
                v-model="singelguigeadd.attr_name"
              ></a-input>
            </a-form-item>
            <a-form-item label="规格值">
              <div
                class="mgr-24 flex"
                style=" display: inline-block;position:relative; padding:2px 0px; margin-right:16px;"
                v-for="(m,n) in singelguigeadd.attr_values"
                :key="n+'u'"
              >
                <a-input
                  class="ant-input_1"
                  style="width:128px;"
                  v-model="singelguigeadd.attr_values[n]"
                />
              </div>
              <span
                class="pointer"
                style="display:inline-block;padding:0px 8px;height: 25px;line-height: 25px; background: #F8F8F8;color: #768483;"
                @click="addnewgoodsspecs1()"
              >添加值 + </span>
            </a-form-item>
          </a-form>
        </div>
      </a-modal>

      <a-modal
        title="切换规格"
        :visible="skModel.visible"
        @cancel="skModel.visible =false"
        @ok="skModelokFn"
        :width="600"
      >
        <div>
          <a-row
            class="flex "
            style="margin: 0;"
          >
            <a-col :span="4">
              <div class="textColor1">
                <span
                  style="margin-right:0px;"
                  class="transferPro_tip_po"
                ></span>产品规格
                <span class="redColor">*</span>
              </div>
            </a-col>
            <a-col :span="19">
              <div class="flex">
                <div
                  class="basicPz_2"
                  style="width:380px"
                >

                  <a-radio-group
                    v-model="skModel.isSingle"
                    class="lable_radiogroup"
                    style="width:324px;"
                  >
                    <a-radio-button
                      :value="1"
                      style="width:162px;text-align: center;"
                    >
                      单规格
                    </a-radio-button>
                    <a-radio-button
                      :value="2"
                      style="width:162px;text-align: center;"
                      @click="skModel.isShowGuigeAddSelect=true"
                    >
                      多规格
                    </a-radio-button>
                  </a-radio-group>
                </div>
              </div>
              <div
                style="margin-top:10px;"
                class="skuTips"
              >
                切换规格模式，将自动下架各渠道的全部规格，需重新上架至各渠道，请谨慎操作！
              </div>
            </a-col>
          </a-row>
        </div>
      </a-modal>
    </div>
    <!-- 新增  上架回显信息-->
    <div
      class="showPushBox"
      v-else
    >
      <div class="showPush">
        <div class="tophead flex align-item-center">
          <span class="linepoint"> </span>
          <span class="tophead_cot   mgr-12">{{$route.query.name}}</span>
          <span class="tophead_txt ">商品码：</span>
          <span class="tophead_txt "> {{$route.query.bn}}</span>
        </div>
        <!-- 渠道tabs -->
        <a-tabs
          class="ant_card_tabs mgb-24"
          default-active-key="1"
          @change="callbackqd"
          v-model="isShopOrMall"
        >
          <a-tab-pane
            key="1"
            tab="门店"
          >
          </a-tab-pane>
          <a-tab-pane
            key="2"
            tab="连锁商城"
            force-render
          >
          </a-tab-pane>
          <!-- <a-tab-pane
            key="3"
            tab="更多渠道"
          >
          </a-tab-pane> -->
        </a-tabs>
        <span class="mgl-26 uptxt"><span class="linepoint"></span> 已上架信息</span>
        <a-button
          type="primary"
          class="table-btn-clk mgl-12 mgb-16"
          style="height: 32px;letter-spacing: 0px;"
          @click="addCompany"
        >
          <i
            class="meiye-icon meiye-routeicon-add-copy"
            style="color:#fff;font-size:12px;margin-right:4px;"
          ></i>
          新增
        </a-button>

        <!-- 渠道下面的信息列表 -->
        <div class="tabsContentBox">
          <div class="tabsContent flex  ">
            <div class="tabsContent_left">
              <div class="tabsContent_serch">
                <a-input
                  class="ant-input_2"
                  placeholder="请输入"
                  v-model="pushForm.searchValue"
                  @search="onSearchcompany"
                >
                  <template slot="prefix">
                    <i
                      @click="onSearchcompany(pushForm.searchValue)"
                      class="meiye-icon meiye-sousuo pointer"
                    />
                  </template>
                </a-input>
              </div>
              <div class="tabsContent_company flex-1">
                <!-- <div class="tabsContent_cpmname flex align-item-center  mgt-12 mgb-12">
                <span class="mgl-12">渠道-门店</span>
                <i
                  @click="pushForm.ifFold=!pushForm.ifFold"
                  class="meiye-icon meiye-jiantou mgr-12  mgl-12"
                ></i>
                <i
                  class="meiye-icon meiye-routeicon-add"
                  @click="addCompany"
                ></i>
              </div> -->
                <ul
                  class="tabscompany mgl-12 mgt-12"
                  v-if="!pushForm.ifFold"
                >
                  <li
                    class="tabscompany_items flex align-item-center   "
                    v-for="(it,idx) in pushForm.showdata"
                    :key="idx"
                    :class="pushForm.activeItems == idx?'activeItems':''"
                  >
                    <span
                      class="mgr-12 pointer"
                      @click="clickgoodInfos(it,idx);"
                    >
                      <a-tooltip>
                        <template slot="title">
                          {{it.company_name || '--'}}
                        </template>
                        {{it.company_name_short || it.company_name || '--'}}
                      </a-tooltip>
                    </span>
                    <i
                      class="meiye-icon meiye-shanchu pointer"
                      @click="deleteCompany(it)"
                    ></i>
                    <!-- <a-icon
                      @click="deleteCompany(it)"
                      type="delete"
                      class="pointer"
                    /> -->
                  </li>
                </ul>
              </div>
            </div>
            <div class="tabsContent_rgt flex-1">
              <!-- 右侧的数据 pushForm.goodsInfo -->
              <div class="tabsContent_rgt_name mgt-24">
                <span class="tabsContent_rgt_nametxt">商品别名：</span>
                <span
                  class="tabsContent_rgt_nametxt2 mgr-12"
                  v-if="pushForm.goodsInfo"
                >{{ pushForm.goodsInfo.goods_alias_name||'--' }}</span>
                <span
                  class="tabsContent_rgt_nametxt3"
                  v-if="pushForm.goodsInfo && pushForm.goodsInfo.is_show==1"
                > 上架 </span>
                <span
                  class="tabsContent_rgt_nametxt3 tabsContent_rgt_nametxt4"
                  v-if="pushForm.goodsInfo && pushForm.goodsInfo.is_show==0"
                > 下架</span>
                <!-- <i
                  @click="gostep3"
                  class="meiye-icon meiye-bianji"
                ></i> -->
              </div>
              <div class="cpmSku flex align-item-center">
                <span class="linepoint"></span>
                <span class="tabsContent_rgt_skutxt mgr-12">产品规格：</span>
                <a-button
                  class="normalbtn"
                  @click="gostep3"
                >
                  <i class="meiye-icon meiye-bianji"></i>
                  编辑
                </a-button>
              </div>
              <!-- 规格表  pushForm.goodsInfo.product_list -->
              <div class="cpmSkuTable">
                <div class="tableheadline"></div>
                <a-table
                  class="guigeTable"
                  :pagination="false"
                  :rowKey="record=>record.pool_product_id"
                  :loading="pushForm.loading"
                  :columns="pushForm.skuColumns"
                  :data-source="pushForm.goodsInfo.product_list"
                  :scroll="{  y:getfullHeight(271)  }"
                >
                  <template
                    slot="product_image"
                    slot-scope="action, record"
                  >
                    <span v-if="!record.product_image"> 暂无图片</span>
                    <img
                      class="productImage"
                      v-else
                      :src="record.product_image"
                      alt=""
                    >
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- 新增 标签 -->
    <a-modal
      title="新增标签"
      :visible="lslimitsModel.isshowTagsModal"
      @cancel="lslimitsModel.isshowTagsModal=false; "
      @ok="addTagsokFn"
      :width="600"
      class="mrj_modal_normal "
    >
      <div>
        <a-form
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >
          <a-form-item
            required
            label="标签名称"
            style="margin-bottom:22px;"
          >
            <a-input
              style="min-width: 412px;"
              class="ant-input_1"
              v-model="lslimitsModel.tags_name"
            ></a-input>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>

</template>
<script>
import moment from 'moment';
import config from '../config';
import {
  GetmanageGoodslist,
  GetgoodsoptionsList, //门店分类
  TreetypeFinanceList,//财务分类
  TreetypeMallList,//商城分类
  CreateGoods, UpdateGoods,
  cardsInfoProposal, GetgoodsbrandsoptionsList,
  Siglegrouding, showPush, pushCompany, editPoolGoods, delCompanyGoods
  , Gettagoptions//标签
} from '@/api/commodity'
import { GetCompanylist } from '@/api/permission'
import UploadImage from '../modules/drag'
import ShopEditor from '../modules/wangeditor'
import skuSetting from '../../../components/intimate/skuSetting/index.vue'
import Msg from '@/components/mrj-menu/msg'
import ComposeInput from '@/components/compose_input'
import storage from 'store'
import axios from 'axios'
export default {
  inject: ['reload'],
  components: { UploadImage, ShopEditor, skuSetting, ComposeInput },
  data() {
    return {
      config,
      setId: '',
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        "Authorization": localStorage.getItem("token"),
        "Content-Type": 'multipart/form-data'
      },
      step: 1,
      isShopOrBloc: 3,//门店3 商城2
      plshopsList: [//批量选择的商品
        { goods_id: 1, goods_name: '圣莉斯歌芳华醒肤套', goods_bn: 'bn123455', isCheced: true, },
      ],
      targetPlshopsList: [
      ],//
      typestyles2: {
        width: '500px',
        height: "38px"
      },
      selectedRowKeys: [],
      // 权限弹框
      limitsModel: {
        transferShow: true,
        originalmockData: undefined,
        mockData: [],
        promockData: [],
        targetKeys: [],
        selectedRowKeys: [],
        selectedleftRowKeys: [],
        selectedRows: [],
        leftColumns: [
          {
            dataIndex: 'company_name_short',
            title: '机构简称',
            width: 140,
          },
          {
            dataIndex: 'company_name',
            title: '机构名称',
            // width:140,
          },
          {
            dataIndex: 'company_code',
            title: '机构代码',
          },
        ],
        rightColumns: [
          {
            dataIndex: 'company_name_short',
            title: '机构简称',
            width: 140,
          },
          {
            dataIndex: 'company_name',
            title: '机构名称',
            // width:140,
          },
          {
            dataIndex: 'company_code',
            title: '机构代码',
          },
        ],
        limitsreplaceFields: {
          key: "company_id",
          title: 'company_name'
        },
        provinceList: [],
        provinceCompanyList: [],
        proColumns: [
          {
            dataIndex: 'company_name',
            title: '地区',
          },
        ],
        ztotal: undefined, // 门店 连锁 数据
        productPage: 1,

        priTotal: undefined,// 省级数据   数据
        proventPage: 1,

      },
      treeData: [
      ],
      treeData2: [
      ],
      treeData3: [
      ],
      selectArr3: [
        { name: '机构简称', id: '1', key: 1 },
        { name: '机构名称', id: '2', key: 2 },
        { name: '机构代码', id: '3', key: 3 },
      ],
      selectreeObj: {
        children: 'children',
        label: 'category_name',
        value: 'category_id'
      },
      selectreeObj2: {
        children: 'children',
        label: 'category_name',
        value: 'finance_category_id'
      },
      selectreeObj3: {
        children: 'children',
        label: 'category_name',
        value: 'store_category_id'
      },
      brandsList: [],//品牌列表
      typesList: [
        { name: '客装', id: '1' },
        { name: '院装', id: '2' },
      ],//类别
      isactive: false,
      // 规格相关
      goods_specs: {
        isSingle: 1,//1  1单规格 2多规格
        barcode: undefined,//批量设置货品码
        price: undefined,//批量设置单价
        product_market_price: undefined,//批量市场价
        product_image: undefined,//批量设置图片
        pool_price: undefined,//批量设置门店售价
        product_show_sales: undefined,//批量设置门店虚拟单价
        ischooseModehow: true,//选择规格model
        goods_attr: [
          // { attr_name: '', attr_values: '' ,id:0}
        ],
        goods_specs_id: undefined,
        goods_attr_val: [
        ],
        goods_specs_list: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
          {
            attr_name: '新增规格', attr_values: [], id: 9999
          },
        ],
        goods_specs_list0: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
          {
            attr_name: '新增规格', attr_values: [], id: 9999
          },
        ],
        // 总共的 总规格list
        new_goods_specs_list: [
        ],
        goods_specs_values_list: [],
        goods_specs_keys_list: [],
        // select暂存的规格 和规格值 确认之后才push
        goods_specs_selectobj: {

        }
      },
      ischangeGoodsSpecs1: true, //是否显示下拉框所属的规格
      showaddGoodsprivatespec: false,//自定义新增规格弹框
      singelguigeadd: {
        attr_name: '',
        attr_values: [],
      },
      // 表单头
      goods_specs_clomns: [],
      goods_specs_clomnsAll: [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          title: '*标准单价',
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          title: '*市场价',
          dataIndex: 'product_market_price',
          key: 'product_market_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_market_price' },
        },
        {
          title: '货品码',
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
        {
          title: '门店售价',
          dataIndex: 'pool_price',
          key: 'pool_price',
          ellipsis: true,
          scopedSlots: { customRender: 'pool_price' },
        },
        {
          title: '虚拟销量',
          dataIndex: 'product_show_sales',
          key: 'product_show_sales',
          ellipsis: true,
          scopedSlots: { customRender: 'product_show_sales' },
        },
      ],
      goods_specs_clomns2: [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          title: '货品码',
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
        {
          title: '门店售价',
          dataIndex: 'pool_price',
          key: 'pool_price',
          ellipsis: true,
          scopedSlots: { customRender: 'pool_price' },
        },
      ],

      goodSpecstableData: [],
      goodSpecsSingletableData: [
        {
          product_price: undefined,
          product_market_price: undefined,
          product_image: undefined,
          product_bn: undefined,
          product_attr: '',
          product_is_def: 1,
          product_id: 0,
          product_cost_price: '',
          product_market_price: '',
          product_stock: ''
        }
      ],
      // 固定的规格和规格值
      forevergoodSpecsObj: {
        activegoodSpecs: undefined,
        forevergoodSpecsList: [
          { name: '部位', id: '1', children: [{ name: '全身', id: '2' }, { name: '背部', id: '3' }, { name: '面部', id: '4' }] },
          { name: '颜色', id: '5', children: [{ name: '红色', id: '6' }, { name: '白色', id: '7' }, { name: '黑色', id: '8' }] },
          { name: '尺寸', id: '9', children: [{ name: '大', id: '10' }, { name: '中', id: '11' }, { name: '小', id: '12' }] },
          { name: '新建规格', id: '9999' }
        ],
        // 
      },
      addorupdateSpecFlag: 'add',//是修改规格还是编辑 编辑的时候只能添加规格值
      isShowGuigeAddSelect: true,
      form: {//表单数据
        goods_name: '', //d 
        goods_bn: '',//d 
        goods_unit: undefined,// d
        is_give: 0,//0不允许赠送 d 
        goods_type_hz: 0,//合作项目 需要联动 goods_type   //d
        goods_attr: undefined, //d
        goods_attr_val: [{ product_image: '', product_price: '', product_bn: "" }], //d
        goods_desc: undefined, //d
        goods_image: undefined, //d
        goods_slider: undefined, //d
        goods_type: '8', //d
        goods_supplier: 1, //d
        goods_brand: undefined, //d
        finance_category_id: undefined, //d
        store_category_id: 0, //d
        goods_category: 0, //d
        goods_content: undefined, //d 

        goods_id: undefined,
        company_ids: [],//选择上架的公司数组
        is_recommend: 1,
        is_show: 1, // 
        goods_shou_type: 1, //
        goods_shou_end_time: null,
        goods_alias_name: undefined,//别名
        delivery_method: undefined,//快递类型
        delivery_money: 1,//快递模板
        product_list: [
          // { product_id: undefined, pool_price: undefined, product_show_sales: undefined },
        ]
      },
      psOptions: [
        {
          value: '1',
          label: "到店核销",
        },
        {
          value: '2',
          label: "物流配送",
        },
        {
          value: '3',
          label: "同城快送",
        },
        // '到店核销', '物流配送', '同城快送'
      ],
      imgflag: false,
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        url_loading: false,
        isDisabled: false,
        info: {
          permission_id_list: [],
          goods_type: undefined,//商品类型
          goods_category: undefined,//商品分类
          goods_brand: undefined,//商品品牌
          goods_bn: undefined,
          goods_id: undefined,
          goods_name: undefined,
          goods_supplier: 1,
          goods_unit: undefined,// 单位
          is_show: 1,// 商品状态
          is_del: 0,//是否删除
          goods_image: undefined,
          goods_slider: undefined,//轮播图
          goods_attr: [],//商品规格
          goods_attr_val: [],//商品规格值 
          goods_content: '<h1>q<strong>wqeddd</strong><span style=\"background-color: rgb(196, 29, 127);\">dxxxxx</span></h1><p>撒打 撒啊大</p>',//商品描述
          goods_desc: undefined,//商品简介
        }
      },
      skModel: {
        visible: false,
        isSingle: 1,//2
        isShowGuigeAddSelect: false,

      },
      goods_keys: 0,
      pushForm: {//回显的门店-商品-规格数据
        originalData: [],//原始数据 包括门店和商城
        company_id: '',
        orignData: [],
        showdata: [],
        shopDate: [],//门店数据
        mallDate: [],//商城数据
        activeItems: '0',
        isshowbloc: false,//是否显示已上架回显页面
        searchValue: undefined,//
        ifFold: false,//是否折叠
        goodsInfo: {},  // 右侧规格 和详情数据
        shopGoodsInfo: {},//门店
        mallGoodsInfo: {},//商城
        skuColumns: [
          {
            title: '规格图',
            dataIndex: 'product_image',
            key: 'product_image',
            ellipsis: true,
            scopedSlots: { customRender: 'product_image' },
          },
          {
            title: '货品码',
            dataIndex: 'product_bn',
            key: 'product_bn',
            ellipsis: true,
            scopedSlots: { customRender: 'product_bn' },
          },
          {
            title: '门店售价',
            dataIndex: 'pool_price',
            key: 'pool_price',
            ellipsis: true,
            scopedSlots: { customRender: 'pool_price' },
          },

        ],//生成表头
        loading: false,//
        isprev: false,
        checkedproductids: [],//回显第三步需要的已上架规格
        openDatails: false,//回下第三步了 保存走另一个接口了
      },
      isShopOrMall: '1',
      // 连锁
      lslimitsModel: {
        transferShow: true,
        originalmockData: undefined,
        mockData: [],
        promockData: [],
        targetKeys: [],
        selectedRowKeys: [],
        selectedleftRowKeys: [],
        selectedRows: [],
        leftColumns: [
          {
            dataIndex: 'company_name_short',
            title: '机构简称',
            width: 140,
          },
          {
            dataIndex: 'company_name',
            title: '机构名称',
            // width:140,
          },
          {
            dataIndex: 'company_code',
            title: '机构代码',
          },
        ],
        rightColumns: [
          {
            dataIndex: 'company_name_short',
            title: '机构简称',
            width: 140,
          },
          {
            dataIndex: 'company_name',
            title: '机构名称',
            // width:140,
          },
          {
            dataIndex: 'company_code',
            title: '机构代码',
          },
        ],
        limitsreplaceFields: {
          key: "company_id",
          title: 'company_name'
        },
        provinceList: [],
        provinceCompanyList: [],
        proColumns: [
          {
            dataIndex: 'company_name',
            title: '地区',
          },
        ],
        ztotal: undefined, //  连锁 数据
        productPage: 1,
        priTotal: undefined,// 省级数据   数据
        proventPage: 1,
        goods_id: [],//标签id
        goods_is_list: [],//标签库
        isshowTagsModal: false,
        tags_name: undefined,

      },

    };
  },
  created() {
    // this.getCompanyoptionList()
    // this.getCompanyoptionList2()
    // this.getCompanyoptionList3()
    if (this.$route.query.id) {
      this.form.goods_id = this.$route.query.id
      this.form.goods_bn = this.$route.query.goods_bn
      this.form.goods_name = this.$route.query.name
    }
    // this.openDatails()
    this.openPushinfos()
    this.getTagoptions()

  },
  methods: {
    moment,
    // 商品规格详情信息
    openDatails(e) {
      cardsInfoProposal({ goods_id: this.$route.query.id }).then(res => {
        this.form = Object.assign(this.form, res.data)
        this.form.goods_type = this.form.goods_type + ''
        this.form.goods_brand = this.form.goods_brand
        this.form.goods_supplier = this.form.goods_supplier + ''
        this.form.goods_slider = JSON.parse(this.form.goods_slider)
        this.form.goods_attr_val.forEach((el, idx) => {
          el.product_id_add = el.goods_id
        })
        if (this.form.goods_category) {
          this.form.goods_category = (res.data.category_info.category_parent_path + ',' + (res.data.category_info.category_id + '')).split(',')

        } else if (this.form.goods_category == 0) {
          this.form.goods_category = undefined
        }
        if (this.form.store_category_id) {
          this.form.store_category_id = (res.data.category_info.category_parent_path + ',' + (res.data.category_info.store_category_id + '')).split(',')

        } else if (this.form.store_category_id == 0) {
          this.form.store_category_id = undefined
        }
        // store_category_info
        // this.form.store_category_id = (res.data.store_category_info.category_parent_path + ',' + (res.data.store_category_info.store_category_id + '')).split(',')
        this.form.finance_category_id = [res.data.finance_category_id + ""]
        // 单规格 or 多规格
        let self = this
        if (this.form.goods_attr.length) {//多规格
          this.goods_specs.isSingle = 2
          this.goods_specs.new_goods_specs_list = []
          this.goods_specs.goods_specs_keys_list = []
          //  这里多规格 回显设置规格的值列表
          this.form.goods_attr.forEach((el, idx) => {
            let obj = {
              id: "p" + idx,
              attr_name: el.attr_name,
              attr_values: el.attr_values
            }

            self.goods_specs.new_goods_specs_list.push(obj)
          })

          console.log(this.goods_specs.new_goods_specs_list);

          // 还需要回显规格商品表
          this.goodSpecstableData = []

          // 处理 goods_specs_clomns
          let config_clomns = []
          // this.goods_specs_clomns = []
          let arri1 = []
          this.form.goods_attr.forEach((el, idx) => {
            let obj = {
              title: el.attr_name,
              dataIndex: el.attr_name,
              key: el.attr_name,
              ellipsis: true,
              scopedSlots: { customRender: el.attr_name },
            }
            let object1 = {
              attr_name: el.attr_name,
              attr_values: el.attr_values,
              product_id: el.product_id
            }
            config_clomns.push(obj)
            arri1.push(object1)
          })
          this.form.goods_attr = arri1
          console.log(this.goods_specs_clomns);


          this.goods_specs_clomns = [...config_clomns, ...this.goods_specs_clomnsAll]
          console.log(this.goods_specs_clomns2, "...this.goods_specs_clomns2");
          if (this.isShopOrBloc == 2) {
            this.goods_specs_clomns = [...config_clomns, ...this.goods_specs_clomnsAll]
            // this.goods_specs_clomns = this.goods_specs_clomns
          } else {
            this.goods_specs_clomns = [...config_clomns, ...this.goods_specs_clomns2]
            // this.goods_specs_clomns = this.goods_specs_clomns.filter(el => el.title !== '虚拟销量')
          }

          let newResult = arri1.reduce((a, b, c) => {
            let res = []
            a.map(x => {
              b.attr_values.map(y => {
                res.push({ ...x, [b.attr_name]: y })
              })
            })
            return res
          }, [{}])

          // var keyMap = (o) => fun => {
          //   var newObject = {}
          //   Object.keys(o).forEach(key => {
          //     newObject[fun(key)] = o[key]
          //   })
          //   return newObject
          // } 
          // newResult = keyMap(newResult)(e => `"${e}"`)
          newResult.forEach((el, idx) => {

            let obj = {
              product_id: this.form.goods_attr_val[idx].product_id,
              product_bn: this.form.goods_attr_val[idx].product_bn,
              product_attr: this.form.goods_attr_val[idx].product_attr,
              // product_attr: {"颜色": "白色", "量装": "瓶装", "套装": "25"}
              product_is_def: this.form.goods_attr_val[idx].product_is_def,
              product_image: this.form.goods_attr_val[idx].product_image,
              product_price: this.form.goods_attr_val[idx].product_price,
              product_market_price: this.form.goods_attr_val[idx].product_market_price,
              product_cost_price: this.form.goods_attr_val[idx].product_cost_price,
              product_market_price: this.form.goods_attr_val[idx].product_market_price,
              product_stock: this.form.goods_attr_val[idx].product_stock
            }

            // var keyMap = (o) => fun => {
            //   var newObject = {}
            //   Object.keys(o).forEach(key => {
            //     newObject[fun(key)] = o[key]
            //   })
            //   return newObject
            // } 
            // var d = keyMap(el.product_attr)(e => `"${e}"`)

            for (const i in el.product_attr) {
              el[j] = el.product_attr[i];
            }
            el = Object.assign(el, obj)
            delete el.product_attr
          })


          this.goodSpecstableData = newResult
          if (e && e.length) {
            e.forEach(el => {
              self.goodSpecstableData.forEach(m => {
                if (el.product_id == m.product_id) {
                  m['product_show_sales'] = el.product_show_sales
                }
              })
            })
          }
          console.log(this.goods_specs.new_goods_specs_list, "goods_specs.new_goods_specs_list");
          // this.goodSpecstableData = this.form.goods_attr_val
        } else {//单规格
          this.goods_specs.isSingle = 1
          if (e && e.length) {
            this.form.goods_attr_val[0].product_show_sales = e[0].product_show_sales
          }
          this.goodSpecsSingletableData = this.form.goods_attr_val
        }
        console.log(this.form.goods_attr_val, "/////goods_attr_valgoods_attr_val");
        this.form.product_list = [
          {
            product_bn: this.form.goods_attr_val[0].product_bn,
            pool_price: undefined,
          }
        ]
        // 是否需要回显选中项
        if (e) {
          this.selectedRowKeys = []
          e.forEach(el => {
            this.selectedRowKeys.push(el.product_id)
            this.goodSpecstableData.forEach(pro => {
              if (pro.product_id == el.product_id) {
                pro.pool_price = el.pool_price
              }
            })
          })
          if (self.goods_specs.isSingle == 1) {
            self.form.goods_attr_val[0].pool_price = e[0].pool_price
          }
          // this.selectedRowKeys = e
        }


      })
    },
    // 获取标签
    getTagoptions() {
      let self = this
      Gettagoptions().then(res => {
        if (res.error_code == 0) {
          res.data.forEach(el => {
            el.goods_tag_id = el.goods_tag_id + ''
          })
          self.lslimitsModel.goods_is_list = res.data
          let obj = { tag_name: '新增标签', tag_status: undefined, created_at: undefined, updated_at: undefined, goods_tag_id: '9999' }
          self.lslimitsModel.goods_is_list.push(obj)
        }
      })
    },
    // 商品上架详情 有数据已上架 无数据 未上架
    openPushinfos() {
      let that = this

      showPush({ goods_id: this.$route.query.id }).then(res => {

        if (res.error_code == 0) {
          that.pushForm.orignData = res.data
          that.pushForm.originalData = res.data
          that.pushForm.shopDate = []
          that.pushForm.mallDate = []
          if (that.pushForm.orignData && that.pushForm.orignData.length) {
            that.pushForm.isshowbloc = true
          } else {
            return false
          }
          //  区分数据 门店 商城 
          res.data.forEach(el => {
            if (el.company_type == 0) {
              that.pushForm.shopDate.push(el)
            }
            if (el.company_type == 1) {
              that.pushForm.mallDate.push(el)
            }
          })

          that.pushForm.showdata = that.isShopOrMall == 1 ? that.pushForm.shopDate : that.pushForm.mallDate
          that.pushForm.goodsInfo = that.isShopOrMall == 1 ? that.pushForm.shopDate[0] ? that.pushForm.shopDate[0].goods_info : {} : that.pushForm.mallDate[0] ? that.pushForm.mallDate[0].goods_info : {}
          that.pushForm.company_id = that.isShopOrMall == 1 ? that.pushForm.shopDate[0] ? that.pushForm.shopDate[0].company_id : '' : that.pushForm.mallDate[0] ? that.pushForm.mallDate[0].company_id : ''

          //  区分单规格
          // if (that.pushForm.goodsInfo.product_list.length == 0) {
          //   that.pushForm.skuColumns = that.goods_specs_clomns2
          // } else {
          //   //  表单头 
          //   let arr = Object.keys(that.pushForm.goodsInfo.product_list[0].product_attr)
          //   console.log(arr, '/////arr'); //["颜色", "量装"] 
          //   let arrconfig = []
          //   arr.forEach(el => {
          //     let obj = {
          //       title: el,
          //       dataIndex: el,
          //       key: el,
          //       ellipsis: true,
          //       scopedSlots: { customRender: el },
          //     }
          //     arrconfig.push(obj)
          //   })
          //   that.pushForm.skuColumns = [...arrconfig, ...that.goods_specs_clomns2]
          //   // 表单体数据重组加入 规格数据  
          //   that.pushForm.goodsInfo.product_list.forEach((el, idx) => {
          //     // el.product_attr
          //     for (let key in el.product_attr) {
          //       that.pushForm.goodsInfo.product_list[idx][key] = el.product_attr[key]
          //     }
          //   })

          // } 
          if (that.pushForm.showdata && that.pushForm.showdata.length) {
            that.pushForm.isshowbloc = true
          }
          that.pushForm.activeItems = '0'

        }
      })
    },
    // 获取详情信息
    getPartnerDetail(id) {
      partnerDetail({ id: id }).then(res => {
        console.log(res.data, "data");
        let list_no = [];
        let list_yes = [];
        res.data.commission_rule.forEach(element => {
          if (element.product_type == 1) {
            list_no.push(element);
          }
          if (element.product_type == 2) {
            list_yes.push(element);
          }
        });
        this.form = {
          ...res.data,
          share_price: res.data.subscription_setting.share_price,
          min_shares: res.data.subscription_setting.min_shares,
          premium_rate: res.data.subscription_setting.premium_rate,
          commission_rule_no: list_no,
          commission_rule_yes: list_yes,
        };
        // this.timeData = [res.data.start_date, res.data.end_date];
        this.application_timeData = [res.data.application_start_date, res.data.application_end_date]
        // console.log('this.timeData :>> ', this.timeData);
        console.log('this.form :>> ', this.form);
      })
    },
    getfullHeight(val) {
      console.log(document.body.offsetHeight - val);
      return document.body.offsetHeight - val - 159 + 16
    },
    // 获取方案 id
    getPartnerGenerated() {
      partnerGenerated().then((res) => {
        this.form.scheme_identifier = res.data.scheme_identifier;
      })
    },
    // 富文本
    getUser(e) {
      console.log(e);
      this.form.goods_content = e
    },
    //上传
    handleChange1(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.url_loading = true
        return
      }
      if (info.file.status === 'done') {
        const { response = {} } = info.file
        this.form.goods_attr_val[0].product_image = response?.data?.url
        this.permissionInfo.url_loading = false
      }
    },
    handleChange2(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.pic_url_loading = true
        return
      }
      if (info.file.status === 'done') {
        const { response = {} } = info.file
        this.form.goods_image = response?.data?.url
        this.permissionInfo.pic_url_loading = false
      }
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    // 方案期效
    // onChangeTime(rec) {
    //   console.log('rec :>> ', rec);
    //   this.form.start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
    //   this.form.end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
    //   console.log('rec :>> ', this.form.start_date);
    //   console.log('rec :>> ', this.form.end_date);
    // },
    // 开放时间
    onChangeApplication(rec) {
      console.log('rec :>> ', rec);
      this.form.application_start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
      this.form.application_end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
    },
    onChangeIdentify(rec) {
      console.log('rec :>> ', rec);
      this.form.identify_end_date = rec ? rec.format('YYYY-MM-DD') : '';
    },
    handleOpenChange(status) {
      this.yearPickShow = status;
    },
    onChangeYear(rec) {
      console.log('rec :>> ', rec.format('YYYY'));
      this.form.dividend_ratio_year = rec ? rec.format('YYYY') : '';
      this.yearPickShow = false;
    },

    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    onStep(key) {
      if (key == 1) {
        this.step = key
        this.goStep(1)
      }
      if (key == 2) {
        this.step = key
        this.goStep(2)
      }
      if (key == 3) {
        this.goStep(3)
      }
      if (key == 4) {
        this.step = key
        delete this.form.goods_type_hz
      }

    },
    //取消
    cancel() {
      this.$router.push({ path: '/shop/storedvaluecard/' })
      // this.$emit('onCancel');
      Msg.$emit('changethirdaryShow', true)
    },
    handlePreview(src, type) { },
    addTableItem(key, type) {
      if (this.form[key].some((it) => it.editable)) {
        this.$message.warning('请完成正在编辑的条目操作！')
        return
      }
      this['edit_item'] = undefined
      if (key == 'dividend_ratio_list') {
        this.form[key].splice(0, 0, {
          editable: true,
          operate: this.operate,
          dividend_business: undefined,
          dividend_rate: undefined,
          id: new Date().getTime(),
        })
      }
      if (key == 'commission_rule_no' || key == 'commission_rule_yes') {
        this.form[key].splice(0, 0, {
          editable: true,
          operate: this.operate,
          first_purchase_rate: undefined,
          payment_method: undefined,
          repurchase_rate: undefined,
          product_type: type,
          id: new Date().getTime(),
        })
      }
      console.log('this.form[key] :>> ', this.form[key]);
    },
    saveItem(record, index, key) {
      let self = this
      if (key == 'dividend_ratio_list') {
        console.log(record, index, key)
        const { dividend_rate } = record
        const specValueAntDElText = self.$refs['SpecValueRef_' + index].$el.innerText
        console.log(specValueAntDElText)
        let msg
        if (specValueAntDElText.includes('选择类型')) {
          msg = '请选择类型！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!dividend_rate) {
          msg = '分红比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
      }
      if (key == 'commission_rule_no' || key == 'commission_rule_yes') {
        const { first_purchase_rate, repurchase_rate } = record
        const specValueAntDElText = self.$refs['productTypeRef_' + index].$el.innerText
        console.log(specValueAntDElText)
        let msg
        if (specValueAntDElText.includes('选择类型')) {
          msg = '请选择类型！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!first_purchase_rate) {
          msg = '首购付款分佣比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!repurchase_rate) {
          msg = '再次付款分佣比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
      }
      setTimeout(() => {
        self.form[key][index].editable = false
      }, 400)
    },
    editItem(id, key) {
      if (this.form[key].some((it) => it.editable)) {
        this.$message.warning('请完成正在编辑的条目操作！')
        return
      }
      this.form[key] = this.form[key].map((it) => {
        if (it.id == id) {
          this['edit_item'] = it
          it.editable = true
        }
        return it
      })
    },
    cancelItem(index, key) {
      if (!this['edit_item']) {
        this.form[key].splice(index, 1)
        return
      }
      this.form[key] = this.form[key].map((it, ind) => {
        if (index == ind) {
          it.editable = false
        }
        return it
      })
    },
    deleteItem(record, index, key) {
      this.form[key].splice(index, 1)
    },
    editItemQy(record) {
      this.modal.form = record;
      this.qyStep = 1;
      this.modal.visible = true;
    },
    deleteItemQy(index) {
      let self = this
      self.$confirm({
        closable: true,
        title: '提示',
        content: '是否删除？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          self.form.benefit.splice(index, 1)
        },
        onCancel() { },
      })

    },
    // 添加权益
    handleOk() {
      if (!this.modal.form.name) return this.$message.warning('请填写权益名称！')
      if (!this.modal.form.goods_content) return this.$message.warning('请填写权益详情！')
      this.modal.form.count = this.modal.form.count_type == 1 ? 0 : this.modal.form.count;
      this.form.benefit.push(this.modal.form);
      this.modal.visible = false;
      this.qyStep = 1
    },
    handleCancel() {
      this.qyStep = 1;
      this.modal.visible = false
    },
    concatFunction(arr1, arr2) {
      //不要直接使用var arr = arr1，这样arr只是arr1的一个引用，两者的修改会互相影响  
      var arr = arr1.concat();
      //或者使用slice()复制，var arr = arr1.slice(0)  
      for (var i = 0; i < arr2.length; i++) {
        arr.indexOf(arr2[i]) === -1 ? arr.push(arr2[i]) : 0;
      }
      console.log('concatFunction', arr)
      return arr
    },

    goStep(e) {
      if (this.$route.query.type != 1) {
        if (e == 2) {
          if (this.isShopOrBloc == 2) {
            this.getCompany()
          } else {
            this.loadProduct()
          }
          let timers = setTimeout(() => {
            console.log(document.querySelectorAll('.ant-transfer-list >.ant-transfer-list-body'));
            document.querySelectorAll('.ant-transfer-list >.ant-transfer-list-body')[0].style.borderRadius = '0 4px 4px 0'
            document.querySelectorAll('.ant-transfer-list >.ant-transfer-list-body')[1].style.borderRadius = '4px'
            clearTimeout(timers)
            timers = null
          })
          this.step = e
        } if (e == 3) {
          if (this.isShopOrBloc == 3) {
            if (this.limitsModel.targetKeys && this.limitsModel.targetKeys.length > 0) {
              this.openDatails()
              this.getTagoptions()
              this.step = e
            } else {
              this.$message.error('请选择上架范围')
              return false
            }
          } else {
            if (this.lslimitsModel.targetKeys && this.lslimitsModel.targetKeys.length > 0) {
              this.openDatails()
              this.getTagoptions()
              this.step = e
            } else {
              this.$message.error('请选择上架范围')
              return false
            }
          }

        } else if (e == 1) {
          this.this.pushForm.company_id = this.limitsModel.targetKeys
        }
        else {
          this.step = e
        }

      }
    },
    changeRoute() {
      this.$router.replace({ path: this.$route.fullpath ? this.$route.fullpath : this.$route.path });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)

      this.$forceUpdate();
    },
    //  门店分类 树形列表
    getCompanyoptionList(e) {
      GetgoodsoptionsList().then(res => {
        this.treeRecursion(res.data, 'category_id')
        this.treeData = res.data
        console.log(this.treeData, '>>>this.coptionsList');


      })
    },
    //  财务分类 树形列表
    getCompanyoptionList2(e) {
      TreetypeFinanceList().then(res => {
        this.treeRecursion(res.data, 'finance_category_id')
        this.treeData2 = res.data
        console.log(this.treeData2, '>>>this.treeData2');


      })
    },
    //  商城分类 树形列表
    getCompanyoptionList3(e) {
      TreetypeMallList().then(res => {
        this.treeRecursion(res.data, 'store_category_id')
        this.treeData3 = res.data
        console.log(this.treeData, '>>>this.coptionsList');


      })
    },
    // 递归改变id key number
    treeRecursion(data, str) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el[str]) == 'number') {
          el[str] = el[str].toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },
    getBrands() {
      GetgoodsbrandsoptionsList().then(res => {
        console.log(res, "getBrands");
        if (res.error_code == 0) {
          this.brandsList = res.data
          // this.brandsList.forEach(el => {
          //   el.brand_id = el.brand_id.toString()
          // })
          // this.brandsList.unshift({
          //   brand_icon: "meiye-routeicon-chart-pie",
          //   brand_id: 99999,
          //   brand_name: "全部",
          //   brand_sort: 1,
          //   brand_status: 1,
          //   created_at: "2023-04-20 16:30:25",
          // })
        }
      })
    },


    onChange() { },
    onChangeTimes(date, dateString) {
      this.form.goods_shou_end_time = dateString
    },
    onChange1() { },
    getPopupContainer(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })

      return document.querySelectorAll('.styledatas')[0]
    },
    getPopupContainer2(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas2')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })

      return document.querySelectorAll('.styledatas2')[0]
    },
    getPopupContainer3(triggerNode) {
      console.log(triggerNode);
      let timer = setTimeout(() => {
        document.querySelectorAll('.styledatas3')[0].children[1].style.width = 'auto'
        clearTimeout(timer)
        timer = ''
      })

      return document.querySelectorAll('.styledatas3')[0]
    },
    // 单规格 上传规格图
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {

        this.form.goods_attr_val[0].product_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    // 选择规格
    changeGoodsSpecs(e, i) {
      let self = this
      if (e == 9999) {
        this.goods_specs.goods_specs_id = undefined
        this.showaddGoodsprivatespec = true //自定义新增规格弹框
        this.ischangeGoodsSpecs1 = false
        return false
      }
      this.goods_specs.goods_specs_list.forEach(el => {
        if (el.id == e) {
          self.goods_specs.goods_specs_values_list = el.attr_values
          //  
          // self.goods_specs.new_goods_specs_list.push(el)
          this.goods_specs.goods_specs_selectobj = el
        }

      })
      this.ischangeGoodsSpecs1 = true
      console.log(this.goods_specs.goods_specs_values_list, "goods_specs_values_list");
      // console.log(self.goods_specs.goods_specs_keys_list, " self.goods_specs.goods_specs_keys_list");

    },
    // 确认规格
    changeGoodsSpecs1() {
      // 确认没有空的规格值  然后push

      let flag = false
      let self = this
      this.goods_specs.goods_specs_values_list.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.goods_specs_values_list.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后进入下一步。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
            // self.qystep = 2
          },
        });
        return false
      }
      console.log(this.goods_specs.goods_specs_values_list);
      // this.goods_specs.goods_specs_values_list.push('')
      console.log(this.goods_specs.goods_specs_selectobj);
      this.goods_specs.new_goods_specs_list.push(this.goods_specs.goods_specs_selectobj)
      this.goods_specs.goods_specs_list.forEach((el, idx) => {
        if (el.id == this.goods_specs.goods_specs_selectobj.id) {

          this.goods_specs.goods_specs_list.splice(idx, 1)
          this.goods_specs.goods_specs_id = undefined
        }
      })
      console.log(this.goods_specs.goods_specs_list);
      // 生成多规格列表
      this.hideModal()
      this.ischangeGoodsSpecs1 = false
      this.isShowGuigeAddSelect = false
    },
    // 
    onqyChange(e, it, idex) {
      let self = this
      let a = self.goods_specs.goods_specs_values_list[idex]
      console.log(e, self.goods_specs.goods_specs_values_list[idex]);
      console.log(this.goods_specs.goods_specs_values_list);

      self.goods_specs.goods_specs_values_list[idex] = e.target.innerText
    },
    closegoodsSpecsValues(e) {
      this.goods_specs.goods_specs_values_list.splice(e, 1)
      console.log(this.goods_specs.goods_specs_values_list, "this.goods_specs.goods_specs_values_list");
    },
    legalObjadd() {
      let flag = false
      let self = this
      this.goods_specs.goods_specs_values_list.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.goods_specs_values_list.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后进入下一步。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
            // self.qystep = 2
          },
        });
        return false
      }
      console.log(this.goods_specs.goods_specs_values_list);
      this.goods_specs.goods_specs_values_list.push('')
    },
    // 删除新增的规格名 规格值
    deleteNewspecs(e, it) {
      let self = this
      // delete this.goods_specs.new_goods_specs_list[e]
      this.goods_specs.new_goods_specs_list.splice(e, 1)
      // 恢复 goods_specs_list 的值
      this.goods_specs.goods_specs_list0.forEach(el => {
        if (el.id == it.id) {
          self.goods_specs.goods_specs_list.unshift(it)
        }
      })
      // 重新 生成多规格列表
      this.hideModal()
      console.log(this.goods_specs.new_goods_specs_list);
    },
    closegoodsSpecsValues(e) {
      this.goods_specs.goods_specs_values_list.splice(e, 1)
      console.log(this.goods_specs.goods_specs_values_list, "this.goods_specs.goods_specs_values_list");
    },
    deletenewGoodsSpecs(idx, n) {
      // 调取 如果

      let obj = {}
      this.goods_specs.goods_specs_list0.forEach((el, index) => {
        if (el.attr_name == this.goods_specs.new_goods_specs_list[idx].attr_name) {
          obj = el
        }
      })
      this.goods_specs.new_goods_specs_list[idx].attr_values.splice(n, 1)
      if (this.goods_specs.new_goods_specs_list[idx].attr_values.length == 0) {
        this.goods_specs.new_goods_specs_list.splice(idx, 1)
        if (obj.length) this.goods_specs.goods_specs_list.unshift(obj)
      }
      // console.log(this.goods_specs.new_goods_specs_list[idx].attr_values);
      // 选择框 加入 删除的选项

      this.hideModal()
    },
    // 批量生成规格表格  设置 单价 和 货品码
    createdSpecsTable() {
      let self = this
      this.goodSpecstableData.forEach((el, idx) => {
        self.goodSpecstableData[idx].pool_price = self.goods_specs.pool_price
        self.goodSpecstableData[idx].product_show_sales = self.goods_specs.product_show_sales
        self.form.product_list.forEach(m => {
          if (m.product_id == el.product_id) {
            m.pool_price = el.pool_price
          }
        })
      })
      this.goods_keys++
      console.log(this.goodSpecstableData, "vvvthis.goodSpecstableData");
    },
    // 改变 某一个单价/条码
    changeSingeSpecIpt(record, i, flag) {
      console.log(record, i, flag, "val,record");

      console.log(this.goodSpecstableData, 'goodSpecstableData')
      if (flag) {
        this.goodSpecsSingletableData.forEach((el, idx) => {
          if (el.product_id == record.product_id) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      } else {
        this.goodSpecstableData.forEach((el, idx) => {
          if (el.product_id == record.product_id) {
            this.goodSpecstableData[idx][i] = record[i]

          }
          this.form.product_list.forEach((m, n) => {
            if (m.product_id == el.product_id) {
              m.pool_price = el.pool_price
            }
          })
        })
        this.goods_keys++
      }

    },

    addnewgoodsspecs(idx, n) {
      let flag = false
      let self = this
      this.goods_specs.new_goods_specs_list[idx].attr_values.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.new_goods_specs_list[idx].attr_values.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后操作。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
          },
        });
        return false
      }
      console.log(this.goods_specs.new_goods_specs_list[idx].attr_values);
      this.goods_specs.new_goods_specs_list[idx].attr_values.push('')
    },
    addnewgoodsspecs1() {
      let flag = false
      let self = this
      if (this.singelguigeadd.attr_values.length) {
        this.singelguigeadd.attr_values.forEach(el => {
          if (!el) {
            flag = true
          }
        })
        if (flag && self.singelguigeadd.attr_values.length > 0) {
          this.$warning({
            title: '提示',
            content: h => {
              return h('div', [
                h(
                  'span',
                  [' 信息尚未填写，请完善内容后操作。']
                ),
              ])
            },

            okText: '好的',
            okType: 'primary',
            // cancelText: '取消',
            onOk() {
            },
          });
          return false
        }
      } else {
        // this.singelguigeadd[idx].attr_values.push('')
      }

      console.log(this.singelguigeadd.attr_values);
      this.singelguigeadd.attr_values.push('')
    },
    addguigeokFn(e) {

      console.log(e, 'addguigeokFn');
      console.log(this.singelguigeadd, 'singelguigeadd');
      this.goods_specs.new_goods_specs_list.push(this.singelguigeadd)
      this.singelguigeadd = {
        attr_name: '',
        attr_values: [],
      }
      this.hideModal()
      this.showaddGoodsprivatespec = false
      this.isShowGuigeAddSelect = false
    },
    // 生成多规格table
    hideModal() {
      console.log('往表格里面加规格列表');

      var arr = []
      var arr_goods_attr = []
      this.goods_specs.new_goods_specs_list
      this.goods_specs.goods_specs_list
      //  
      arr = [...this.goods_specs.new_goods_specs_list]
      // todo 以后这里需要把规格列表传给后台 成功之后 1，2
      // 1 在这里就需要渲染table的数据和columns操作
      let arri1 = []
      // coluns
      let columns = []
      let specs_clomns = [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          title: '标准单价',
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          title: '市场价',
          dataIndex: 'product_market_price',
          key: 'product_market_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_market_price' },
        },
        {
          title: '货品码',
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
        {
          title: '门店售价',
          dataIndex: 'pool_price',
          key: 'pool_price',
          ellipsis: true,
          scopedSlots: { customRender: 'pool_price' },
        },
        {
          title: '虚拟销量',
          dataIndex: 'product_show_sales',
          key: 'product_show_sales',
          ellipsis: true,
          scopedSlots: { customRender: 'product_show_sales' },
        },
      ]
      arr.forEach((el, index) => {
        let object1 = {
          attr_name: el.attr_name,
          attr_values: el.attr_values,
        }
        let object2 = {
          title: el.attr_name,
          dataIndex: el.attr_name,
          key: el.attr_name,
          ellipsis: true,
          scopedSlots: { customRender: el.attr_name },
        }
        arri1.push(object1)
        columns.push(object2)
      })
      this.form.goods_attr = arri1 //规格
      // 动态生成表头
      this.goods_specs_clomns = [...columns, ...specs_clomns]

      // 
      let newResult2 = []
      let newResult = arri1.reduce((a, b, c) => {
        let res = []
        a.map(x => {
          b.attr_values.map(y => {
            res.push({ ...x, [b.attr_name]: y })
          })
        })
        return res
      }, [{}])
      // newResult2 = Object.assign(newResult2, newResult)
      console.log(newResult, 1, newResult2)
      // 动态生成 商品规格表

      // 编辑条件下 比较与旧版的商品规格表 把数据贴上去
      if (this.addorupdateSpecFlag == 'update') {
        newResult.forEach((el, idx) => {
          this.form.goods_attr_val.forEach((m, n) => {
            //  
            let reg = /\\"/g;
            let a = JSON.stringify(m.product_attr).replace(reg, '');
            console.log(JSON.stringify(el) == a)
            if (JSON.stringify(el) == a) {
              newResult[idx] = Object.assign(m, el)
              // newResult[idx] = m
            }

          })

        })

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        console.log(newResult, 2)

        this.goodSpecstableData = newResult2
      } else {

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        this.goodSpecstableData = newResult2
      }

      this.goods_specs.ischooseModehow = false

    },
    hideModal2(new_goods_specs_list) {
      console.log('往表格里面加规格列表');

      var arr = []
      var arr_goods_attr = []
      this.goods_specs.new_goods_specs_list = new_goods_specs_list
      this.goods_specs.goods_specs_list

      arr = [...this.goods_specs.new_goods_specs_list]
      // todo 以后这里需要把规格列表传给后台 成功之后 1，2
      // 1 在这里就需要渲染table的数据和columns操作
      let arri1 = []
      // coluns
      let columns = []
      let specs_clomns = [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          title: '标准单价',
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          title: '市场价',
          dataIndex: 'product_market_price',
          key: 'product_market_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_market_price' },
        },
        {
          title: '货品码',
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
        {
          title: '门店售价',
          dataIndex: 'pool_price',
          key: 'pool_price',
          ellipsis: true,
          scopedSlots: { customRender: 'pool_price' },
        },
        {
          title: '虚拟销量',
          dataIndex: 'product_show_sales',
          key: 'product_show_sales',
          ellipsis: true,
          scopedSlots: { customRender: 'product_show_sales' },
        },
      ]
      arr.forEach((el, index) => {
        let object1 = {
          attr_name: el.attr_name,
          attr_values: el.attr_values,
        }
        let object2 = {
          title: el.attr_name,
          dataIndex: el.attr_name,
          key: el.attr_name,
          ellipsis: true,
          scopedSlots: { customRender: el.attr_name },
        }
        arri1.push(object1)
        columns.push(object2)
      })
      this.form.goods_attr = arri1 //规格
      this.form.goods_attr = arri1
      // 动态生成表头
      this.goods_specs_clomns = [...columns, ...specs_clomns]

      // 
      let newResult2 = []
      let newResult = arri1.reduce((a, b, c) => {
        let res = []
        a.map(x => {
          b.attr_values.map(y => {
            res.push({ ...x, [b.attr_name]: y })
          })
        })
        return res
      }, [{}])
      // newResult2 = Object.assign(newResult2, newResult)
      console.log(newResult, 1, newResult2)
      // 动态生成 商品规格表

      // 编辑条件下 比较与旧版的商品规格表 把数据贴上去
      if (this.addorupdateSpecFlag == 'update') {
        newResult.forEach((el, idx) => {
          this.form.goods_attr_val.forEach((m, n) => {
            //  
            let reg = /\\"/g;
            let a = JSON.stringify(m.product_attr).replace(reg, '');
            console.log(JSON.stringify(el) == a)
            if (JSON.stringify(el) == a) {
              newResult[idx] = Object.assign(m, el)
              // newResult[idx] = m
            }

          })

        })

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        console.log(newResult, 2)

        this.goodSpecstableData = newResult2
      } else {

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        this.goodSpecstableData = newResult2
        this.form.goods_attr_val = newResult2
      }

      // 2
      this.goods_specs.ischooseModehow = false

    },
    // 批量 规格图
    async batchSetuploadspecFiles(info, record, flag) {
      // console.log(info)
      // console.log(record); 
      // console.log(this.goodSpecstableData);
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.goods_specs.product_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 列表 规格图
    // 规格图
    async uploadspecFiles(info, record, flag) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        if (flag) {
          this.goodSpecsSingletableData[0].product_image = res.data.url
        } else {
          this.goodSpecstableData.forEach(el => {

            if (record.product_id >= 0) {
              if (el.product_id == record.product_id) {
                el.product_image = res.data.url
              }
            }
            if (record.product_id_add >= 0) {
              if (el.product_id_add == record.product_id_add) {
                el.product_image = res.data.url
              }
            }

          })
        }


        // this.form.goods_image = res.data.url
        console.log(this.goodSpecstableData);
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 轮播图
    getDelImageId(val) {
      if (this.form.goods_slider && this.form.goods_slider.length > 0) {

      } else {
        this.form.goods_slider = []
      }
      this.form.goods_slider.push(val)
    },
    getImageshow(val) {
      this.pulsshow = val
    },
    delDelimageId(index) {
      this.form.goods_slider.forEach((val, key) => {
        if (index === key) {
          this.form.goods_slider.splice(key, 1)
        }
      })
    },
    // 获取到重新排序后的图片
    handleDraggable(e) {
      const imgDrag = []
      for (var i = 0; i < e.length; i++) {
        // var a = e[i].url.split('/')
        imgDrag.push(e[i].url)
      }
      this.form.goods_slider = imgDrag
    },
    // 商品 f封面图
    async ffuploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.form.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 提交按钮
    okclickmod(e) {

      let arr = []
      if (this.isShopOrBloc == 2) {
        this.lslimitsModel.goods_is_list.forEach(el => {
          if (this.lslimitsModel.goods_id.includes(el.goods_tag_id)) {
            arr.push({
              tag_name: el.tag_name,
              tag_id: (el.goods_tag_id + '').indexOf("id") == -1 ? el.goods_tag_id + '' : undefined
            })
            // tag_id:el.goods_tag_id.includes('id')? undefined :el.goods_tag_id})
          }
        })
      }
      let data_pa = {
        goods_list: [{
          goods_id: this.form.goods_id,
          // company_ids: this.limitsModel.targetKeys,//选择上架的公司数组
          is_recommend: this.form.is_recommend,
          is_show: this.form.is_show, // 
          goods_shou_type: this.form.goods_shou_type, //
          goods_shou_end_time: this.form.goods_shou_end_time,
          goods_alias_name: this.form.goods_alias_name,//别名
          delivery_method: this.form.delivery_method,//快递类型
          delivery_money: 1,//快递模板
          product_list: this.form.product_list,
          tag_list: this.isShopOrBloc == 3 ? undefined : arr
        }],
        company_ids: this.isShopOrBloc == 3 ? this.limitsModel.targetKeys : this.lslimitsModel.targetKeys,//选择上架的公司数组

      }
      if (!this.form.goods_alias_name) {
        this.$message.error('输入商品别名')
        return false
      }

      if (this.form.goods_shou_type == 2 && !this.form.goods_shou_end_time) {
        this.$message.error('请选择截止时间')
        return false
      }

      // 单规格  
      let that = this

      if (this.goods_specs.isSingle == 1) {
        this.form.product_list = []
        this.form.goods_attr_val.forEach(el => {
          that.form.product_list.push({
            product_id: el.product_id,
            pool_price: el.product_price,
            product_show_sales: this.isShopOrBloc == 2 ? el.product_show_sales : undefined

          })
        })

        data_pa.goods_list[0].product_list = this.form.product_list
      } else {
        if (this.form.product_list.length < 1) {
          this.$message.error('请至少选择一个规格')
          return false
        }
        if (this.isShopOrBloc == 3) {
          data_pa.goods_list[0].product_list.forEach((el, idx) => {
            data_pa.goods_list[0].product_list[idx].product_show_sales = undefined
          })
        }
      }
      data_pa.goods_list[0].product_list[0].product_show_sales = this.form.product_show_sales
      pushCompany(data_pa).then(res => {
        console.log(res);
        if (res.error_code == 0) {
          this.$message.success('操作成功')
          this.pageStatu = 'list'
          if (this.pushForm.orignData.length) {
            this.pushForm.isshowbloc = true
            this.pushForm.isprev = false
            this.pushForm.openDatails = false
            // 初始化数据
            this.openPushinfos()
          } else {
            this.$router.replace({ path: '/shop/storedvaluecard' })

          }
        }
      })


    },
    okclickmod2(e) {
      let arr = []
      if (this.isShopOrBloc == 2) {
        this.lslimitsModel.goods_is_list.forEach(el => {
          if (this.lslimitsModel.goods_id.includes(el.goods_tag_id)) {
            arr.push({
              tag_name: el.tag_name,
              tag_id: (el.goods_tag_id + '').indexOf("id") == -1 ? el.goods_tag_id + '' : undefined
            })
            // tag_id:el.goods_tag_id.includes('id')? undefined :el.goods_tag_id})
          }
        })
      }
      if (!this.form.goods_attr_val[0].product_price) {
        let str = this.isShopOrBloc == 3 ? '门店' : '商城'
        return this.$message.error('请输入' + str + '售价')
      }
      let data_pa = {
        goods_id: this.form.goods_id,
        company_id: this.pushForm.company_id,//选择上架的公司数组
        is_recommend: this.form.is_recommend,
        is_show: this.form.is_show, // 
        goods_shou_type: this.form.goods_shou_type, //
        goods_shou_end_time: this.form.goods_shou_end_time,
        goods_alias_name: this.form.goods_alias_name,//别名
        delivery_method: this.form.delivery_method,//快递类型
        delivery_money: 1,//快递模板 
        product_list: this.form.product_list,
        tag_list: this.isShopOrBloc == 3 ? undefined : arr
      }
      if (!this.form.goods_alias_name) {
        this.$message.error('请输入商品别名')
        return false
      }
      if (this.form.goods_shou_type == 2 && !this.form.goods_shou_end_time) {
        this.$message.error('请选择截止时间')
        return false
      }
      if (!this.form.goods_attr_val[0].product_price) {
        this.$message.error('请输入售价')
        return false
      }

      // 单规格  
      let that = this
      if (this.goods_specs.isSingle == 1) {
        // this.form.product_list = []
        // this.form.goods_attr_val.forEach(el => {
        //   that.form.product_list.push({
        //     product_id: el.product_id,
        //     pool_price: el.product_price
        //   })
        // })
        data_pa.product_list = this.form.product_list
        data_pa.product_list[0].pool_price = this.form.goods_attr_val[0].product_price
      } else {
        if (this.form.product_list.length < 1) {
          this.$message.error('请至少选择一个规格')
          return false
        }
      }
      data_pa.product_list[0].product_show_sales = this.form.product_show_sales
      editPoolGoods(data_pa).then(res => {
        console.log(res);
        if (res.error_code == 0) {
          this.$message.success('操作成功')
          this.pageStatu = 'list'
          this.pushForm.isshowbloc = true
          this.pushForm.isprev = false
          this.pushForm.openDatails = false
          // 初始化数据
          this.cancle3()
          this.openPushinfos()
        } else {
          if (res.error_msg) this.$message.error(res.error_msg)
        }
      })


    },
    // 机构搜索框
    getListSearchlimits(e) {
      console.log(e);
      let str = ''
      // 挑选数据 打乱顺序 排到最上面
      if (e.activeStatus1.id == 1) {//机构简称
        str = 'company_name_short'
      }
      if (e.activeStatus1.id == 2) {//机构名称
        str = 'company_name'
      }
      if (e.activeStatus1.id == 3) {//机构代码
        str = 'company_code'
      }
      if (e.activeStatus1.id == 4) {//机构代码
        str = 'company_code'
      }
      e.value
      let arr = []
      this.limitsModel.mockData.forEach((el, idx) => {
        if (el[str].includes(e.value)) {
          arr.push(el)
          this.limitsModel.mockData.splice(idx, 1);
        }
      })
      this.limitsModel.mockData = arr.concat(this.limitsModel.mockData)
      // this.limitsModel.originalmockData.forEach(el => {

      // })

    },
    // 授权穿梭框相关
    onlimitsChange(nextTargetKeys) {

      this.limitsModel.targetKeys = nextTargetKeys;
      this.limitsModel.selectedRowKeys = []
      this.limitsModel.selectedleftRowKeys = []
      let that = this
      let arr = []
      this.limitsModel.originalmockData.forEach(el => {
        if (el.data) {
          el.data.forEach(m => {
            if (!that.limitsModel.selectedRowKeys.includes(m.key)) {
              arr.push(m.isParent)
            }
          })
        }
      })

    },
    // 连锁
    onlslimitsChange(nextTargetKeys) {
      this.lslimitsModel.targetKeys = nextTargetKeys;
      this.lslimitsModel.selectedRowKeys = []
      this.lslimitsModel.selectedleftRowKeys = []
      let that = this
      let arr = []
      this.lslimitsModel.originalmockData.forEach(el => {
        if (el.data) {
          el.data.forEach(m => {
            if (!that.lslimitsModel.selectedRowKeys.includes(m.key)) {
              arr.push(m.isParent)
            }
          })
        }
      })

    },
    triggerDisable(disabled) {
      this.disabled = disabled;
    },

    triggerShowSearch(showSearch) {
      this.showSearch = showSearch;
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {

      let that = this
      that.limitsModel.transferShow = true
      return {
        getCheckboxProps: item => ({ props: { disabled: disabled || item.disabled } }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: that.limitsModel.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          that.limitsModel.selectedRowKeys = selectedRowKeys
          that.limitsModel.selectedRows = selectedRows

        }
      };
    },
    lsgetRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {

      let that = this
      that.lslimitsModel.transferShow = true
      return {
        getCheckboxProps: item => ({ props: { disabled: disabled || item.disabled } }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: that.lslimitsModel.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          that.lslimitsModel.selectedRowKeys = selectedRowKeys
          that.lslimitsModel.selectedRows = selectedRows

        }
      };
    },
    // 树形结构 转 平铺 从外到内依次递归，有 children 则继续递归
    treeToArr(data, pid = null, res = []) {
      data.forEach(item => {
        res.push({ pid: pid, id: item.id, name: item.name });
        if (item.children && item.children.length !== 0) {
          this.treeToArr(item.children, item.id, res);
        }
      });
      return res;
      // 
    },
    // 逆向 平铺-转-树形结构 ，有 children 则继续递归
    arrToTree(arr, pid = null) {
      const res = [];
      arr.forEach(item => {
        if (item.pid === pid) {
          // 这样每次都需要遍历整个数组，因此时间复杂度为 n*n
          // const children = arrToTree(arr, item.id)   

          // 往下递归时，每次数组的大小都在减小，每次都筛选掉父代元素，最终的时间复杂度为 n*logn
          const children = this.arrToTree(arr.filter(v => v.pid !== pid), item.id);
          if (children.length) {
            res.push({ ...item, children })
          } else {
            res.push({ ...item })
          }
        }
      });
      return res;
    },

    loop(data) {
      let flag = false
      let self = this
      let p = Promise.resolve()
      for (let i = 0; i < self.limitsModel.provinceList.length; i++) {
        p = p.then(_ => {
          return GetCompanylist(data).then(res => {
            res.data.list.forEach(el => {
              el.key = el.company_id.toString()
              el.title = el.company_name
            })
            self.limitsModel.originalmockData.concat(res.data.list)
            flag = true
          }, err => { })
        })
      }
      p.then(_ => {
        flag || setTimeout(loop(data), 0)
      })


    },
    formatTimes(timestamp) {
      var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
      // return moment(value).format('yyyy-MM-DD  hh:mm:ss')//将时间格式转成yyyy-MM-DD
    },
    // 获取连锁公司
    getCompany(companylist) {
      let data = {
        "filter": {
          "company_type": 1
        },
        "limit": 500,
        "page": this.lslimitsModel.productPage
      }
      let self = this

      GetCompanylist(data).then(res => {
        let productList = res.data.list ? res.data.list : [];
        self.lslimitsModel.ztotal = res.data.total;
        if (productList.length > 0) {
          res.data.list.forEach(el => {
            if (companylist && companylist.length && companylist.includes(el.company_id)) {
              return false
            } else {
              self.lslimitsModel.mockData.push({
                key: el.company_id.toString(),
                title: el.company_name,
                company_name: el.company_name,
                company_code: el.company_code,
                company_parent_path: el.company_parent_path,
                company_name_short: el.company_name_short,
                isParent: el.company_parent_path.split(',')[1]
              })
            }
          })
          self.lslimitsModel.productPage++;
          self.getCompany(companylist); //获取所有 
        } else {
          if (self.lslimitsModel.mockData.length == 0) {
            return self.$message.error('全部机构已上架！')
          } else {
            self.cancle3()
            self.step = 2
            self.isShopOrBloc = 2

            self.pushForm.isshowbloc = false
            self.pushForm.isprev = true
          }
          console.log(self.lslimitsModel.mockData, "-----lslimitsModel-------mockData");//全部 商城数据

        }
      })

    },
    async loadProductAll() {
      let that = this
      let data = {
        "filter": {
          "company_level": 1, //0总公司。1 省级代理 2连锁主体 3门店
          // "company_parent_path": i,
        },
        "limit": 500,
        "page": that.limitsModel.proventPage
      }
      GetCompanylist(data).then(res => {
        let productList = res.data.list ? res.data.list : [];
        that.limitsModel.priTotal = res.data.total;
        if (productList.length > 0) {
          res.data.list.forEach(el => {
            that.limitsModel.provinceList.push({
              key: el.company_id.toString(),
              title: el.company_name,
              company_name: el.company_name,
              company_code: el.company_code,
              company_parent_path: el.company_parent_path,
              company_name_short: el.company_name_short
            })
          })
          that.limitsModel.proventPage++;
          that.loadProductAll();
        } else {

          console.log(that.limitsModel.provinceList, "------------provinceList");   //key

          //得到  that.limitsModel.provinceList   that.limitsModel.originalmockData
          that.limitsModel.provinceList.forEach((el, idx) => {
            that.limitsModel.originalmockData.forEach((m, n) => {
              if (el.key == m.isParent) {
                m.company_id = el.company_id
                m.key = el.key
                m.company_name = el.company_name
                m.title = m.title
                m.company_name_short = el.company_name_short
              }
            })
          })
          console.log(that.limitsModel.originalmockData, "------------originalmockData"); // isParent

          // that.rangeDataList(that.limitsModel.provinceList)
          // 匹配 company_name 
          // company_boss_name: "秦超"
          // company_boss_phone: "18178083112"
          // company_code: "202302183660"
          // company_id: 7
          // company_level: 2
          // company_logo: "https://zhengxin-pub.cdn.bcebos.com/mark/93aca5eea8f6c96bd704880bafb3b9f6.jpg"
          // company_name: "B2代理商"
          // company_name_short: "B2代理商"
          // company_parent: 3
          // company_parent_path: "1,3"
          // company_status: 2
          // created_at: "2023-02-18 17:09:24"
          // parent_name: "山西分公司"
          // updated_at: "2023-02-18 17:09:24"
        }

      })
    },

    async loadProduct(companylist, flag) {
      let that = this;
      let data = {
        "filter": {
          "company_level": that.isShopOrBloc, //0总公司。1 省级代理 2连锁主体 3门店
          // "company_parent_path": i,
        },
        "limit": 500,
        "page": that.limitsModel.productPage
      }
      GetCompanylist(data).then(res => {
        let productList = res.data.list ? res.data.list : [];
        that.limitsModel.ztotal = res.data.total;
        if (productList.length > 0) {
          res.data.list.forEach(el => {
            if (companylist && companylist.length) {
              if (companylist.includes(el.company_id)) {
                return false
              } else {
                that.limitsModel.mockData.push({
                  key: el.company_id.toString(),
                  title: el.company_name,
                  company_name: el.company_name,
                  company_code: el.company_code,
                  company_parent_path: el.company_parent_path,
                  company_name_short: el.company_name_short,
                  isParent: el.company_parent_path.split(',')[1]
                })
              }
            } else {
              that.limitsModel.mockData.push({
                key: el.company_id.toString(),
                title: el.company_name,
                company_name: el.company_name,
                company_code: el.company_code,
                company_parent_path: el.company_parent_path,
                company_name_short: el.company_name_short,
                isParent: el.company_parent_path.split(',')[1]
              })
            }

          })
          that.limitsModel.productPage++;
          that.loadProduct(companylist, flag); //获取所有门店的数据
        } else {
          console.log(that.limitsModel.mockData, "------------mockData");//全部门店或者商城数据
          if (that.limitsModel.mockData.length == 0) {
            return that.$message.error('全部机构已上架！')
          } else {
            that.limitsModel.originalmockData = that.getCompanyData() //全部 数据
            that.loadProductAll() //获取省的数据 
            that.cancle3()
            that.step = 2
            that.isShopOrBloc = 3
            that.pushForm.isshowbloc = false
            that.pushForm.isprev = true
          }
        }

      })

    },
    // 获取 公司的总数据
    getCompanyData() {
      let arr2 = this.limitsModel.mockData
      var map = {},
        // pro = [],
        dest = [];
      for (var i = 0; i < arr2.length; i++) {
        var ai = arr2[i];
        if (!map[ai.isParent]) {
          dest.push({
            company_name: ai.company_name,
            company_id: ai.key,
            isParent: ai.isParent,
            data: [ai]
          });
          map[ai.isParent] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.isParent == ai.isParent) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }

      console.log(dest);

      // function uniqueFunc(arr, company_name) {
      //   const res = new Map();
      //   return arr.filter((item) => !res.has(item[company_name]) && res.set(item[company_name], 1));
      // } 
      // this.limitsModel.provinceCompanyList = dest
      return dest


    },
    callback(key) {
      console.log(key, 'keycallbackcallbackcallbackcallback');
    },
    callbackqd(key) {
      // 回显 数据 
      // 门店1  商城2

      this.isShopOrBloc = key == 1 ? 3 : 2
      this.pushForm.showdata = key == 1 ? this.pushForm.shopDate : this.pushForm.mallDate
      // this.pushForm.goodsInfo = key == 1 ? this.pushForm.shopGoodsInfo : this.pushForm.mallGoodsInfo
      this.pushForm.goodsInfo = key == 1 ? this.pushForm.shopDate[0] ? this.pushForm.shopDate[0].goods_info : {} : this.pushForm.mallDate[0] ? this.pushForm.mallDate[0].goods_info : {}
      this.pushForm.company_id = key == 1 ? this.pushForm.shopDate[0] ? this.pushForm.shopDate[0].company_id : '' : this.pushForm.mallDate[0] ? this.pushForm.mallDate[0].company_id : ''
      let that = this

      console.log(key, 'keycallbackcallbackcallbackcallback');
      //  区分单规格
      if (!that.pushForm.goodsInfo.product_list) {
        that.pushForm.goodsInfo.product_list = []
      } else if (that.pushForm.goodsInfo.product_list.length == 1 && !that.pushForm.goodsInfo.product_list[0].product_attr) {
        // that.pushForm.goodsInfo.product_list = []
        that.pushForm.skuColumns = that.goods_specs_clomns3
      } else {
        //  表单头 
        let arr = Object.keys(that.pushForm.goodsInfo.product_list[0].product_attr)
        console.log(arr, '/////arr'); //["颜色", "量装"] 
        let arrconfig = []
        arr.forEach(el => {
          let obj = {
            title: el,
            dataIndex: el,
            key: el,
            ellipsis: true,
            scopedSlots: { customRender: el },
            className: 'datestyle',
          }
          arrconfig.push(obj)
        })
        that.pushForm.skuColumns = [...arrconfig, ...that.goods_specs_clomns3]
        // 表单体数据重组加入 规格数据  
        that.pushForm.goodsInfo.product_list.forEach((el, idx) => {
          // el.product_attr
          for (let key in el.product_attr) {
            that.pushForm.goodsInfo.product_list[idx][key] = el.product_attr[key]
          }
        })
      }
      that.pushForm.activeItems = '0'
    },

    // onPsChange
    onPsChange(e) {
      console.log(e, '..........配送支持');
    },
    changeSkSingststus() {
      let self = this
      // self.$confirm({
      //   closable: true,
      //   title: '切换规格',
      //   content: '切换规格模式，将自动下架各渠道的全部规格，需重新上架至各渠道，请谨慎操作！',
      //   okText: '确定',
      //   okType: 'primary',
      //   cancelText: '取消',
      //   onOk() {
      //     if (self.goods_specs.isSingle == 1) {
      //       self.goods_specs.isSingle = 2
      //     } else {
      //       self.goods_specs.isSingle = 1
      //     }

      //   },
      //   onCancel() { },
      // })
      self.skModel.visible = true;
    },
    skModelokFn() {
      this.goods_specs.isShowGuigeAddSelect = this.skModel.isShowGuigeAddSelect
      this.goods_specs.isSingle = this.skModel.isSingle
      this.skModel.visible = false
    },
    ontransferTableChange(selectedRowKeys, selectedRows, event) {

      //selectedRowKeys 为你点击选框时这一页选中的所有key
      //selectedRows 为你点击选框时这一页选中的所有数据

    },
    goEditor(id) {
      this.$router.push({ path: '/shop/management/managementlist', query: { type: 3, id: id } })
    },
    // 搜索公司信息
    onSearchcompany(e) {
      let that = this
      console.log(e);
      that.pushForm.searchValue
      that.pushForm.showdata = []
      let orignData = that.isShopOrMall == 1 ? that.pushForm.shopDate : that.pushForm.mallDate
      orignData.forEach(el => {
        if (el.company_name.includes(that.pushForm.searchValue)) {
          that.pushForm.showdata.push(el)
        }
      })
      console.log(that.pushForm.showdata);
      that.pushForm.goodsInfo = that.pushForm.showdata[0].goods_info
      that.pushForm.company_id = that.pushForm.showdata[0].company_id
      // 筛选出复合条件的门店数据  2 取第一个渲染右边的数据 todu
      //  表单头 
      let arr = Object.keys(that.pushForm.goodsInfo.product_list[0].product_attr)
      console.log(arr, '/////arr'); //["颜色", "量装"] 
      let arrconfig = []
      arr.forEach(el => {
        let obj = {
          title: el,
          dataIndex: el,
          key: el,
          ellipsis: true,
          scopedSlots: { customRender: el },
          className: 'datestyle',
        }
        arrconfig.push(obj)
      })
      that.pushForm.skuColumns = [...arrconfig, ...this.goods_specs_clomns3]
      // 表单体数据重组加入 规格数据  
      that.pushForm.goodsInfo.product_list.forEach((el, idx) => {
        // el.product_attr
        for (let key in el.product_attr) {
          that.pushForm.goodsInfo.product_list[idx][key] = el.product_attr[key]
        }
      })
      that.pushForm.activeItems = '0'
    },
    // 删除上架门店信息
    deleteCompany(e) {
      // 调取接口 删除门店信息
      let that = this
      this.$confirm({
        closable: true,
        title: '提示',
        content: '将删除门店的信息，请谨慎操作！',
        okText: '确定',
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          let data = {
            goods_id: e.goods_info.goods_id,
            company_id: e.company_id
          }
          delCompanyGoods(data).then(res => {

            if (res.error_code == 0) {
              that.$message.success('删除成功')
              that.openPushinfos()
            }
          })

        },
        onCancel() { },
      })


    },
    addCompany() {//新增门店 需要跳转第二步 且数据排除已经上架的门店
      let that = this
      let companylist = []
      that.pushForm.orignData.forEach(el => {
        companylist.push(el.company_id)
      })
      // that.loadProduct(companylist)
      // that.step = 2
      // that.cancle3()
      // that.pushForm.isshowbloc = false
      // that.pushForm.isprev = true
      if (that.isShopOrMall == 1) {
        that.loadProduct(companylist, true)
      }
      if (that.isShopOrMall == 2) {
        that.getCompany(companylist)
      }
    },
    clickgoodInfos(it, idx) {

      this.pushForm.activeItems = idx
      this.pushForm.company_id = it.company_id
      this.pushForm.goodsInfo = JSON.parse(JSON.stringify(it.goods_info));
      //  表单头 
      let arr = Object.keys(this.pushForm.goodsInfo.product_list[0].product_attr)
      console.log(arr, '/////arr'); //["颜色", "量装"] 
      let arrconfig = []
      arr.forEach(el => {
        let obj = {
          title: el,
          dataIndex: el,
          key: el,
          ellipsis: true,
          scopedSlots: { customRender: el },
        }
        arrconfig.push(obj)
      })
      this.pushForm.skuColumns = [...arrconfig, ...this.goods_specs_clomns2]
      // 表单体数据重组加入 规格数据  
      this.pushForm.goodsInfo.product_list.forEach((el, idx) => {
        // el.product_attr
        for (let key in el.product_attr) {
          this.pushForm.goodsInfo.product_list[idx][key] = el.product_attr[key]
        }
      })

    },
    gostep3() {//去第三步 需要回显信息和规格值 pushForm.skuColumns pushForm.goodsInfo.product_list
      let that = this
      if (!that.pushForm.goodsInfo.product_list || !that.pushForm.goodsInfo.product_list.length) {
        return false
      }
      that.form.goods_alias_name = that.pushForm.goodsInfo.goods_alias_name
      that.form.is_show = that.pushForm.goodsInfo.is_show
      that.form.goods_shou_type = that.pushForm.goodsInfo.goods_shou_type
      // form.goods_shou_type form.goods_shou_end_time
      that.form.goods_shou_end_time = that.pushForm.goodsInfo.goods_shou_end_time ? that.formatTimes(that.pushForm.goodsInfo.goods_shou_end_time) : 0
      that.form.is_recommend = that.pushForm.goodsInfo.is_recommend ? that.pushForm.goodsInfo.is_recommend : 1
      that.form.product_show_sales = that.pushForm.goodsInfo.product_list[0].product_show_sales
      // that.form.is_recommend = that.pushForm.goodsInfo.is_recommend
      that.form.delivery_method = that.pushForm.goodsInfo.delivery_method
      that.form.delivery_money = that.pushForm.goodsInfo.delivery_money
      // that.form.goods_attr_val[0].product_price
      if (that.form.goods_attr_val && that.form.goods_attr_val[0]) {
        that.form.goods_attr_val[0].product_price = that.pushForm.goodsInfo.product_list[0].pool_price
        that.form.goods_attr_val[0].product_id = that.pushForm.goodsInfo.product_list[0].produt_id
        that.form.goods_alias_name = that.pushForm.goodsInfo.goods_alias_name
        that.form.goods_bn = that.pushForm.goodsInfo.product_list[0].product_bn
      }
      // 还需要回显已经上架的规格id
      that.form.product_list = []
      that.pushForm.goodsInfo.product_list.forEach(el => {
        that.form.product_list.push(
          { product_id: el.product_id, pool_price: el.pool_price, product_show_sales: el.product_show_sales }
        )
      })
      that.goods_keys++
      // that.pushForm.checkedproductids = that.form.product_list
      // that.openDatails(that.pushForm.checkedproductids, that.form)
      // console.log(that.pushForm.checkedproductids, 'that.pushForm.checkedproductids ***********');
      // that.step = 3
      that.pushForm.openDatails = true
      that.pushForm.isshowbloc = false
      that.pushForm.isprev = true
      let newtagsarr = []
      that.lslimitsModel.goods_is_list.forEach(el => {
        newtagsarr.push(el.goods_tag_id + '')
      })
      let addtagsarr = [] //包含的 还有不包含的要摘出来推入that.lslimitsModel.goods_is_list
      // [1,2,3]  [2,3,6]
      that.pushForm.goodsInfo.tag_list.forEach(el => {
        if (!newtagsarr.includes(el.pool_goods_tag_id)) {
          addtagsarr.push({
            created_at: el.created_at,
            goods_tag_id: el.pool_goods_tag_id + '',
            tag_name: el.tag_name,
            tag_status: 1,
            updated_at: el.updated_at,
          })
        }
      })
      that.lslimitsModel.goods_is_list = addtagsarr.concat(that.lslimitsModel.goods_is_list)
      that.lslimitsModel.goods_id = []
      that.pushForm.goodsInfo.tag_list.forEach(el => {
        that.lslimitsModel.goods_id.push(el.pool_goods_tag_id + '')
      })

      console.log(that.lslimitsModel.goods_is_list, 'that.lslimitsModel.goods_is_list')

      that.step = 3
    },
    // 初始化数据
    cancle3() {
      let that = this
      that.form.goods_alias_name = undefined
      that.form.is_show = 0
      that.form.goods_shou_type = 1
      that.form.goods_shou_end_time = 0
      that.form.is_recommend = 1
      that.form.delivery_method = undefined
      that.form.delivery_money = undefined
      that.selectedRowKeys = []
      // 还需要回显已经上架的规格id
      that.pushForm.checkedproductids = []
      that.pushForm.isshowbloc = true;
      that.pushForm.isprev = false;
    },
    // 选择标签 lslimitsModel.isshowTagsModal
    changetagsSpecs(e) {
      let self = this
      if (e == '9999' || e.includes('9999')) {
        self.lslimitsModel.isshowTagsModal = true //自定义新增弹框
        console.log(self.lslimitsModel.goods_id.indexOf('9999'), '//9999');
        self.lslimitsModel.goods_id.splice(self.lslimitsModel.goods_id.indexOf('9999'), 1)
        return false
      }
      // this.lslimitsModel.goods_id.forEach(el => {
      //   if ( !e.includes(9999)) {
      //     this.lslimitsModel.goods_id.push(el.goods_tag_id)
      //   }

      // })
      console.log(this.lslimitsModel.goods_id, "goods_id");
      // console.log(self.goods_specs.goods_specs_keys_list, " self.goods_specs.goods_specs_keys_list");

    },
    // 新增标签
    addTagsokFn() {
      // 将新增标签推入 this.lslimitsModel.tags_name  this.lslimitsModel.goods_is_list
      let obj = {
        goods_tag_id: "id" + this.lslimitsModel.tags_name,
        tag_name: this.lslimitsModel.tags_name,
        tag_status: undefined,
        created_at: undefined,
        updated_at: undefined,
      }
      this.lslimitsModel.goods_is_list.unshift(obj)
      this.lslimitsModel.isshowTagsModal = false
    },
    changenumIpt(e) {
      this.form.product_show_sales = e.target.value
      this.$set(this.form, this.form.product_show_sales, e.target.value)
    },
  },
  watch: {

  },
  computed: {
    rowSelection() {
      let that = this
      return {
        selectedRowKeys: that.limitsModel.selectedleftRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          // 搞事情  选项  selectedRows 
          console.log(selectedRowKeys, selectedRows);
          console.log(this.limitsModel.originalmockData);
          // that.limitsModel.targetKeys   去掉 selectedRowKeys
          that.limitsModel.selectedleftRowKeys = selectedRowKeys

          var arr = []
          that.limitsModel.originalmockData.forEach(el => {
            selectedRowKeys.forEach(m => {
              if (el.key == m) {

                arr = arr.concat(el.data)
                console.log(arr);
              }
            })
          })
          console.log(arr, '..................arr');
          let arrn = []
          let arrm = []
          if (that.limitsModel.targetKeys && that.limitsModel.targetKeys.length > 0) {
            arr.forEach(j => {
              that.limitsModel.targetKeys.forEach(k => {
                if (k != j.key) {
                  arrn.push(j.key)
                  arrm.push(j)
                }
              })
            })
          } else {
            arr.forEach(j => {
              arrn.push(j.key)
              arrm.push(j)
            })
          }


          that.limitsModel.transferShow = false
          // that.getRowSelection.selectedRowKey = arrn
          that.limitsModel.selectedRowKeys = arrn
          // that.limitsModel.selectedleftRowKeys = arrn
          that.limitsModel.selectedRows = arrm
          that.getRowSelection({ disabled: false })
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
          },
        }),

      };
    },
    rowSelection2() {

      let that = this
      return {
        selectedRowKeys: that.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          that.selectedRowKeys = selectedRowKeys
          that.form.product_list = []
          selectedRows.forEach(el => {
            that.form.product_list.push({
              product_id: el.product_id,
              pool_price: el.pool_price
            })
          })
          // that.form.product_list = selectedRowKeys
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    },
  },
};
</script>
<style lang="less" scoped>
.add {
  display: flex;
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 14px 12px 13px;
  flex: 1;
  position: relative;
  height: calc(100vh - 115px);
  .addleft {
    margin-right: 18px;
    width: 56px;
    height: 300px;
    z-index: 10;
    position: fixed;
    .onetxt {
      width: 16px;
      height: 16px;
      background: @stepDbgColor;
      border-radius: 16px;
      text-align: center;
      line-height: 16px;
      margin-bottom: 3px;
      font-size: 12px;
      font-family: Helvetica;
      color: #ffffff;
      margin-left: 33px;
    }
    .active {
      background: @stepAbgColor;
    }
    .base {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: @stepDfontColor;
      line-height: 20px;
      margin-bottom: 4px;
    }
    .active1 {
      color: @stepAfontColor;
      font-size: 14px;
      font-weight: 600;
    }
    .line {
      width: 1.5px;
      height: 32px;
      background: @steplineColor;
      margin: 0 5px 6px 40px;
    }
  }
  .addright {
    margin-left: 66px;
    padding-bottom: 20px;
    flex: 1;
    background: #ffffff;
    border-radius: 4px;
    position: relative;
    // overflow: auto;
    /deep/.ant-col-4 {
      width: 136px !important;
    }
    .card_i_shop {
      width: 320px;
      height: 132px;
      box-shadow: inset 0px -3px 0px 0px #dbeae8;
      border-radius: 8px;
      border: 1.2px solid #ccd8d6;
      margin-right: 50px;
      padding: 43px 0 0 48px;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      .card_i_names {
        height: 30px;
        font-size: 22px;
        font-weight: 600;
        color: @fontColor5;
        line-height: 30px;
        margin-bottom: 3px;
      }
      .card_i_names2 {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #828c89;
        line-height: 20px;
      }
      &:hover {
        box-shadow: inset 0px -3px 0px 0px @primaryColor;
        border-radius: 8px;
        border: 1.2px solid @primaryColor;
        .card_i_names {
          font-weight: 600;
          color: @fontColor;
        }
      }
      .card_i_shop-img {
        width: 122px;
        height: auto;
        position: absolute;
        right: -1px;
        bottom: 3px;
      }
    }
    .card_i_shopA {
      box-shadow: inset 0px -3px 0px 0px @primaryColor;
      border-radius: 8px;
      border: 1.2px solid @primaryColor;
      .card_i_names {
        font-weight: 600;
        color: @fontColor;
      }
    }
    .p_sh {
      font-weight: 500;
      color: @fontColor1;
    }
    .g_shops {
      width: 850px;
      height: 45px;
      line-height: 45px;
      border-bottom: 1px solid rgba(164, 179, 177, 0.28);
      font-size: 14px;
      font-weight: 400;
      color: rgba(45, 56, 53, 0.8);
      .g_shops_name {
        margin-right: 50px;
      }
    }
    .zengpinstep {
      padding: 24px 0 26px 28px;
      background: #fff;
      overflow: hidden;
    }
    .zengpinstep2 {
      padding: 24px 0 26px 0px;
    }
    .twoh1 {
      display: flex;
      margin-bottom: 20px;
      .tpointer {
        display: inline-block;
        width: 3px;
        height: 10px;
        background: @primaryColor;
        border-radius: 1px;
        margin: 4px 5px 0 0;
      }
      .ttxt {
        height: 22px;
        font-size: 14px;
        font-weight: 500;
        color: @fontColor1;
        line-height: 19px;
      }
    }
    .twoh2 {
      display: flex;
      margin-bottom: 28px;
      .tpointer {
        display: inline-block;
        width: 3px;
        height: 10px;
        background: @primaryColor;
        border-radius: 1px;
        margin: 7px 5px 0 0;
      }
      .ttxt {
        height: 22px;
        font-size: 18px;
        font-weight: 600;
        color: @fontColor1;
        line-height: 25px;
      }
    }
    .twoh3 {
      display: flex;
      margin-bottom: 12px;
      .tpointer {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: @primaryColor;
        border-radius: 1px;
        margin: 7px 5px 0 0;
      }
      .ttxt {
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: @fontColor1;
        line-height: 20px;
      }
    }
    .one {
      height: calc(100vh - 156px);
    }
    .two {
      height: calc(100vh - 147px);
      overflow-y: auto;
      padding-bottom: 60px;
      .zengpinstep {
        padding-bottom: 40px;
      }
    }
    .three {
      height: calc(100vh - 133px);
      overflow: hidden;
      overflow-y: auto;
    }
    .four {
      height: 100%;
    }
    .textColor1 {
      height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @fontColor5;
      line-height: 22px;
      // margin-bottom: 14px;
      .xing {
        color: #f35e67;
        font-weight: 500;
      }
    }
    .textSmall {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @primaryColor;
      line-height: 26px;
      height: 26px;
      background: @gray-border-background;
      border-radius: 4px;
      opacity: 0.65;
      padding-left: 8px;
      margin-top: 13px;
    }
    .a-center {
      display: flex;
      align-items: flex-start;
    }
    .three-btns {
      padding-left: 164px;
      padding-bottom: 20px;
      margin-left: 2px;
      // position: fixed;
      // bottom: 23px;
      background: #ffffff;
      // padding-top: 20px;
      width: 83%;
      z-index: 10;
      // border-top: 1px solid @dropdownBs5a;
      border-radius: 0 0 4px 4px;
    }
    .textSmalls {
      font-size: 15px;
      font-weight: 400;
      color: #819190;
    }
  }
  /deep/ .ant-table-footer {
    background: @gray-border-background;
  }
}
.row-body {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    .text-title {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 400;
      color: @btnColorfont1;
    }
    .yuan-blue {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: #ffffff;
      border-radius: 100%;
      background: @primaryColor;
      border: 1px solid @dropdownBs5a;
    }
    .dian {
      color: #e5e8e5;
      margin: 0 10px;
    }
    .yuan {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: @fontColor7;
      border-radius: 100%;
      background: #fdfdff;
      border: 1px solid @btnColorshodew;
    }
  }
}
.goods_editor {
  width: 600px;
  height: 259px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #cdd5d8;
  overflow: hidden;
  overflow-y: auto;
}
.font-22 {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor1;
}
// 面包屑相关
.mrj-layout-tabs-custom {
  border-bottom: 1px solid @breadcrumbdownlineColor;
}
.leftarrow {
  color: @breadcrumbbackiconColor !important;
  border: 1px solid @breadcrumbbackborderColor !important;
  outline: none;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
  line-height: 10px;
  margin-top: 1px;
  padding-top: 1.2px;
  &:hover {
    color: @breadcrumbbackiconHColor !important;
    border: 1px solid @breadcrumbbackborderHColor!important;
    outline: none;
  }
  .arrleft {
    margin-top: -1px;
    &:hover {
      color: @baseColor70 !important;
    }
  }
  .meiye-fanghui {
    font-size: 14px;
    margin-left: -1px;
  }
}
.leftarrow_hover {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-index span {
  color: @baseColor11;
  font-size: 14px;
}
.mrj-layout-tabs-custom-index:hover .anticon {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-fenge {
  color: @breadcrumblineColor;
}
.mrj-layout-tabs-custom-inner-page {
  color: @fontColor4;
  font-size: 14px;
}
.samerow {
  width: 100%;
  /deep/.ant-form-item {
    display: flex;
    align-items: center;
    width: 100%;
  }
  /deep/ .ant-cascader-picker-label {
    display: inline-block;
  }
}
.samerow1 {
  /deep/ .ant-form-item-children {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
  }
}
.samerow2 {
  /deep/.ant-form-item-label-left {
    margin-left: 68px;
  }
}
#styledatas {
  div {
    width: auto !important;
  }
}
// 单选另一种框
.checkqystatus {
  width: 180px;
  height: 32px;
  line-height: 34px;
  background: @labelDbgColor;
  // box-shadow: inset 0px 3px 0px 0px @labelDinsertColor;
  border-radius: 4px;
  border: 1px solid @labelDborderColor;
  box-sizing: border-box;
  padding: 3px;
  font-size: 16px;
  .cqt1 {
    width: 90px;
    height: 28px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: @labelDfontColor;
    cursor: pointer;
    border: 1px solid transparent;
    text-align: center;
    line-height: 26px;
    &:hover {
      color: @labelHfontColor;
    }
  }
  .cqt1A {
    width: 90px;
    height: 28px;
    background: @labelAbgColor;
    box-shadow: inset 0px -2px 0px 0px @labelAinsertColor;
    // box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: 1px solid @labelAborderColor;
    color: @labelAfontColor;
    text-align: center;
    line-height: 26px;
  }
}
//单规格
.sigleguige {
  .tabelHeader {
    // width: 1605px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: rgba(241, 246, 245, 0.6);
    border-radius: 4px;
    position: relative;
    padding-left: 25px;
    display: flex;
    align-items: center;
    &::after {
      content: " ";
      width: 100%;
      height: 2px;
      background: #e8edec;
      position: absolute;
      top: -4px;
      left: 0;
    }
    .tabelHeader_items {
      width: 206px;
      text-align: left;
      display: inline-block;
    }
  }
  .tableBody {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    .tabelBody_items {
      width: 206px;
      text-align: left;
    }
  }
}
/deep/ .avatar-uploader .ant-upload {
  width: 48px !important;
  height: 48px !important;
}
/deep/.uploaderguige .ant-upload-picture-card-wrapper .ant-upload {
  width: 48px !important;
  height: 48px !important;
  padding: 0;
  margin: 0;
}
.baseColor11 {
  color: @baseColor11;
}
.htmlboxshops {
  &:focus-visible {
    border: none !important;
  }
}
.addGuigeRules {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #2d3835;
  i {
    color: #2d3835;
  }
}
.guigeline {
  width: 2px;
  height: 31px;
  background: #e8edec;
  margin: 0 0 0 48px;
}
.batchSet {
  height: 73px;
  background: #f1fafa;
  line-height: 73px;
  font-size: 14px;
  font-weight: 400;
  color: #2d3835;
  .batchSet_h2 {
    font-size: 14px;
    font-weight: 500;
    color: @fontColor4;
    margin: 0 52px 0 10px;
  }
  .guigeImg {
    height: 73px;
    line-height: 73px;
    .guigeImg_txt {
      font-size: 14px;
      display: inline-block;
      font-weight: 400;
      color: #2d3835;
      margin-right: 10px;
      width: 64px;
      height: 73px;
      line-height: 73px;
    }
    /deep/ .ant-upload-picture-card-wrapper .ant-upload {
      margin: 0;
      width: 48px !important;
      height: 48px !important;
      border-radius: 4px;
      // border: 1px dashed #9ba7a7;
    }
    /deep/.ant-upload.ant-upload-select-picture-card:hover {
      border-color: @primaryColor;
    }
  }
}
.singlePrice {
}
.guigeTablebox {
  margin-top: 8px;
}
.goods_specx_add {
  position: relative;
  .guigeline {
    position: absolute;
    top: 0;
    right: 0;
  }
}
/deep/ .avatar-uploader2.ant-upload-picture-card-wrapper .ant-upload {
  width: 60px !important;
  height: 60px !important;
}
.transferPro,
.transferProTable {
  width: 166px;
  height: 637px;
}
.transfer_1 {
  height: 637px;
  border-color: #ccdada;
}
/deep/ .limits_body .ant-table-placeholder {
  // width: 164px;
}
.limits_body .transferBox {
  margin-right: 32px;
}
.transferBox {
  position: relative;
  .lstransfertxt {
    position: absolute;
    left: 0;
  }
}
.transferPro {
  .transferProTable {
    border: 1px solid #ccdada;
    border-right: none;
    border-radius: 4px 0px 0 4px;
  }

  /deep/ .ant-table-thead > tr:first-child > th:last-child {
    border-radius: 0 0px 0 0 !important;
  }
}
.transferPro_tip {
  color: @fontColor4;
  font-weight: 600;
  font-size: 16px;
  height: 40px;
}
.transferPro_tip_sp {
  font-size: 16px;
  font-weight: 500;
  color: #9ea6a4;
}
.transferPro_tip_po {
  display: inline-block;
  width: 3px;
  height: 10px;
  background: @primaryColor;
  border-radius: 3px;
  margin-right: 3px !important;
}
.ant-transfer_1 {
  margin-top: 38px;

  /deep/ .ant-transfer-list-header {
    display: none;
  }
  /deep/ .ant-transfer-list-body {
    margin-top: -38px;
  }

  /deep/ .ant-transfer-list {
    width: auto !important;
  }
  /deep/ .ant-table-placeholder {
    // width: 572px;
  }
  /deep/ .ant-table-small > .ant-table-content .ant-table-header {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: 1px solid #ccdada;
    overflow: hidden !important;
  }
  /deep/ .ant-transfer-list .ant-transfer-list-body {
    border: 1px solid #ccdada;
    height: 637px;
  }
  /deep/.ant-table-small > .ant-table-content .ant-table-header {
    background-color: transparent;
    border-radius: 0;
  }
}
.transfer_selected_tips {
  position: absolute;
  left: 60%;
  top: 0;
}
// 小页签
.addBox .add .ant_card_tabs {
  // 基本信息  基础配置
  width: 100%;
  background: #fff;
  & /deep/ .ant-tabs-bar {
    height: 49px !important;
    line-height: 49px !important;
    padding: 20px 0 0 0px;
    background: #fff !important;
    margin: 0px;
  }
  & /deep/ .ant-tabs-tab {
    height: 29px !important;
    line-height: 29px !important;
    background: #fff !important;
    // border: 1px solid #d3dedc !important;
    border: none !important;
    // border-color: #d3dedc !important;
    background: transparent !important;
    font-size: 15px !important;
    // margin-right: 12px;
    padding: 0 18px;
  }

  & /deep/ .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
  & /deep/ .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
    margin: 0 !important;
    height: 29px !important;
    font-weight: 600 !important;
    line-height: 29px !important;
    border: none !important;
    // border-color: #e8e8e8;
    // border-bottom: 1px solid #fff;
    font-size: 15px !important;
    color: #5b5a62;
    background: transparent !important;
    // padding: 20px 0 0 30px;
    // background: #fff !important;
  }

  & /deep/ .ant-tabs-tab {
    &::before {
      display: block;
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      bottom: -1.5px;
      background: url(../../../assets/images/tabs_card_1.png) no-repeat 0 0;
      background-size: 100% 100%;
      opacity: 1;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: -2;
      left: 0;
      width: 100%;
      height: 1px;
      bottom: 0px;
      background: transparent;
      background-size: 100% 100%;
      opacity: 1;
    }
  }
  & /deep/ .ant-tabs-tab:nth-child(2) {
    &::before {
      display: block;
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      bottom: -1px;
      background: url(../../../assets/images/tabs_card_2.png) no-repeat 0 0;
      background-size: 100% 100%;
      background-size: cover;
      opacity: 1;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: -2;
      left: 0;
      width: 100%;
      height: 1px;
      bottom: 0px;
      background: rgb(244, 245, 247);
      background-size: 100% 100%;
      opacity: 1;
    }
  }
  & /deep/ .ant-tabs-tab-active {
    color: @baseColor11 !important;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #fff !important;
    // &::before {
    //   content: "";
    //   display: none;
    //   position: absolute;
    //   z-index: -1;
    //   top: -2px;
    //   left: 0;
    //   right: 0;
    //   bottom: -0.5px;
    //   background: url(~@/assets/images/tabs-unselected-1.png) no-repeat 0 0;
    //   background-size: 100% 100%;
    //   opacity: 1;
    // }
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: -2;
      left: 0;
      width: 100%;
      height: 1px;
      bottom: 0px;
      background: transparent !important;
      background-size: 100% 100%;
      opacity: 1;
    }
  }

  .basic_name {
    color: @fontColor4;
    p {
      margin-bottom: 0;
      padding: 0;
    }
    .ba_p2 {
      color: @fontColor5;
      margin-top: 3px;
    }
  }

  .basicSet {
  }
}
.redColor {
  color: @infoColor;
}
/deep/ .ant-checkbox-group {
  margin-top: 0;
}
/deep/ .ant-checkbox-checked .ant-checkbox-inner {
  background: @primaryColor;
  border-color: @primaryColor;
}
.skuTips {
  font-size: 14px;
  font-weight: 400;
  color: @fontColor5;
}

.basic_name {
  position: relative;
}
.toeditoricon {
  display: inline-block;
  position: absolute;
  right: -33px;
  top: 10px;
  width: 21px;
  line-height: 17px;
  text-align: center;
  height: 21px;
  background: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(173, 207, 203, 0.35),
    inset 0px -2px 0px 0px #dbeae8;
  border-radius: 4px;
  border: 1px solid #bacbc7;

  .meiye-bianji {
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    transform: scale(0.85);
    color: @fontColor4;
  }
  &:hover {
    border: 1px solid @primaryColor;
    .meiye-bianji {
      color: @primaryColor;
    }
  }
}

// 上架相关
.showPushBox {
  background: #f0f2f5;

  height: calc(100vh - 137px);
  .showPush {
    margin: 18px;
    background: #fff;
    border-radius: 4px;
    height: 100%;

    .tophead {
      padding: 30px 0 0 36px;
      .tophead_txt {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: @fontColor4;
      }

      .tophead_cot {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: @fontColor4;
        line-height: 22px;
      }
    }
    .tabsContentBox {
      height: calc(100vh - 336px);
      margin: 0 18px 24px 47px;
      overflow: hidden;
      // background: #fcffff !important;
      // overflow-y: auto;
    }
    .tabsContent {
      // padding-bottom: 20px;
      height: 722px;
      height: 100%;
      // height: 402px;
      background: #fcffff;
      border-radius: 6px;
      border: 1px solid #d2e6e4;

      .tabsContent_left {
        width: 300px;
        height: 100%;
        border-right: 1px solid #d2e6e4;
        display: flex;
        flex-direction: column;
        .tabsContent_serch {
          display: flex;
          flex-direction: column;
          .ant-input_2 {
            margin: 18px 16px 0;
            width: 268px;
          }
        }
        .tabsContent_company {
          overflow: hidden;
          overflow-y: auto;
          .tabsContent_cpmname {
            color: @fontColor4;
            font-size: 12px;
            .meiye-jiantou {
              font-size: 12px;
              color: @fontColor5;
            }
            .meiye-routeicon-add {
              font-size: 12px;
              border: 1px solid #ccc;
              padding: 0 2px;
              color: @fontColor5;
            }
          }
          .tabscompany {
            padding: 0;
            font-size: 12px;
          }
        }
      }
      .tabsContent_rgt {
        height: 100%;
        .cpmSku {
          margin-left: 24px;
          margin-top: 30px;
          .tabsContent_rgt_skutxt {
            font-size: 14px;
            font-weight: 600;
            color: @fontColor4;
          }
          .normalbtn {
            letter-spacing: 0;
            &:hover {
              .meiye-bianji {
                color: @baseColor11;
              }
            }
          }
          .meiye-bianji {
            color: @fontColor4;
            margin-right: 4px;
            font-size: 12px;
            margin-right: 4px;
          }
        }
      }
    }
    /deep/.guigeTable .ant-table-thead > tr:first-child > th:first-child {
      padding-left: 13px !important;
    }
    .uptxt {
      color: @fontColor4;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }
  }
}
.linepoint {
  width: 3px;
  height: 10px;
  background: @primaryColor;
  display: inline-block;
  margin-right: 8px;
}
.mgl-47 {
  margin-left: 47px;
}
.mgb-16 {
  margin-bottom: 16px;
}
// 普通tabs
.addBox .showPush .ant_card_tabs {
  // width: 100%;
  background: #fff;
  // margin-top: 4px;
  margin-left: 47px;

  & /deep/ .ant-tabs-nav-wrap {
    margin-bottom: 1px;
    overflow: hidden;
  }
  & /deep/ .ant-tabs-bar {
    height: 49px !important;
    line-height: 49px !important;
    padding: 20px 0 0 0px;
    background: #fff !important;
    margin: 0px;
  }
  /deep/.ant-tabs-ink-bar {
    display: none !important;
  }
  & /deep/ .ant-tabs-tab {
    height: 29px !important;
    line-height: 29px !important;
    background: #fff !important;
    // border: 1px solid #d3dedc !important;
    border: none !important;
    // border-color: #d3dedc !important;
    background: transparent !important;
    font-size: 15px !important;
    // margin-right: 12px;
    padding: 0 18px;
    margin-right: 0;
  }

  & /deep/ .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
  & /deep/ .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
    margin: 0 !important;
    height: 29px !important;
    font-weight: 600 !important;
    line-height: 29px !important;
    border: none !important;
    // border-color: #e8e8e8;
    // border-bottom: 1px solid #fff;
    font-size: 15px !important;
    color: #5b5a62;
    background: transparent !important;
    // padding: 20px 0 0 30px;
    // background: #fff !important;
  }

  & /deep/ .ant-tabs-tab {
    &::before {
      display: block;
      position: absolute;
      top: -2px;
      left: 0px;
      right: 0;
      bottom: -0.5px;
      background: url(../../../assets/images/tabs_card_1.png) no-repeat 0 0;
      background-size: 100% 100%;
      opacity: 1;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: -2;
      left: 0;
      width: 100%;
      height: 1px;
      bottom: 0px;
      background: transparent;
      background-size: 100% 100%;
      opacity: 1;
    }
  }
  & /deep/ .ant-tabs-tab:nth-child(2),
  & /deep/ .ant-tabs-tab:nth-child(3) {
    &::before {
      display: block;
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      bottom: -1px;
      background: url(../../../assets/images/tabs_card_2.png) no-repeat 0 0;
      background-size: 100% 100%;
      background-size: cover;
      opacity: 1;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: -2;
      left: 0;
      width: 100%;
      height: 1px;
      bottom: 0px;
      background: rgb(244, 245, 247);
      background-size: 100% 100%;
      opacity: 1;
    }
  }
  & /deep/ .ant-tabs-tab-active {
    color: @baseColor11 !important;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #fff !important;
    // &::before {
    //   content: "";
    //   display: none;
    //   position: absolute;
    //   z-index: -1;
    //   top: -2px;
    //   left: 0;
    //   right: 0;
    //   bottom: -0.5px;
    //   background: url(~@/assets/images/tabs-unselected-1.png) no-repeat 0 0;
    //   background-size: 100% 100%;
    //   opacity: 1;
    // }
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: -2;
      left: 0;
      width: 100%;
      height: 1px;
      bottom: 0px;
      background: transparent !important;
      background-size: 100% 100%;
      opacity: 1;
    }
  }
  .basic_name {
    color: @fontColor4;
    p {
      margin-bottom: 0;
      padding: 0;
    }
    .ba_p2 {
      color: @fontColor5;
      margin-top: 3px;
    }
  }

  .basicSet {
  }
}
.addBox .add {
  /deep/ .ant-tabs-ink-bar {
    background-color: transparent !important;
  }
  /deep/.ant-tabs-top .ant-tabs-ink-bar-animated:before {
    content: " ";

    background-color: #fff;

    width: 150px;

    height: 2px;

    position: absolute;

    margin: auto;

    margin-left: 25%;
    z-index: 10000;
  }
  /deep/.ant-tabs-nav {
    position: relative;
    &::before {
      position: absolute;
      height: 2px;
      width: 10px;
      background: #fff;
      bottom: 0;
      left: -5px;
    }
  }
}
.cpmSkuTable {
  margin-top: 16px;
  margin: 16px 0px 0 26px;
  .tableheadline {
    width: 100%;
    height: 2px;
    background: #e8edec;
    margin-bottom: 2px;
  }
}

.guigeTable {
  // margin-top: 8px;
}
.productImage {
  width: 48px !important;
  height: 48px !important;
  padding: 0;
  margin: 0;
}
.activeItems {
  background: @verticalnavigationbgAColor;
  background: #e4f7f4;
  border-radius: 4px;
  color: @baseColor11 !important;
}
.tabscompany_items {
  height: 40px;
  width: 268px;
  line-height: 40px;
  margin: 4px;
  padding: 0 14px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: @fontColor4;
  justify-content: space-between;
}

.tabsContent_rgt_nametxt {
  color: @fontColor3;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  // color: rgba(45, 56, 53, 0.8);
  margin: 0 50px 0 26px;
}
.mgr-270 {
  margin-right: 270px;
}
.tabsContent_rgt_nametxt2 {
  color: @fontColor4;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.tabsContent_rgt_nametxt3 {
  width: 41px;
  height: 23px;
  background: #ecffeb;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #217f13;
  line-height: 23px;
  text-align: center;
}
.tabsContent_rgt_nametxt4 {
  color: #f35e67;
  background: #ffe6e7;
}
.mgl-26 {
  margin-left: 47px;
}
.meiye-shanchu {
  color: @fontColor5;
  font-weight: 400;
}
.left_opacity {
  width: 90px;
  height: 91px;
  background: linear-gradient(
    144deg,
    rgba(220, 226, 255, 0.9) 0%,
    rgba(70, 254, 233, 0.9) 100%
  );
  opacity: 0.32;
  filter: blur(40px);
  position: absolute;
  top: -24px;
  left: -18px;
}
.right_opacity {
  width: 134px;
  height: 85px;
  background: linear-gradient(
    155deg,
    rgba(174, 189, 255, 0.9) 0%,
    rgba(57, 255, 233, 0.9) 100%
  );
  opacity: 0.44;
  filter: blur(45px);
  position: absolute;
  bottom: 0;
  right: 6px;
}
.ba_p1 {
  color: @fontColor4;
  margin: 0;
  padding: 0;
}
.ba_p2 {
  color: @fontColor5;
  margin: 0;
  padding: 0;
  margin-bottom: -4px;
}

.last-btns-step2 {
  height: 68px;
  width: calc(100vw - 278px);
  position: fixed;
  bottom: 21px;
  padding-top: 16px;
  background: #fff;
  border-top: 1px solid #d8eceb;
  z-index: 100;
}
.mgl-28 {
  margin-left: 28px;
}
.zengpinstep2 {
  padding: 31px 0 26px 0px;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 1540px) {
  .transfer_selected_tips {
    left: 57%;
  }
  .transfer_selected_tips2 {
    left: 51.6%;
  }
}
@media screen and (min-width: 1708px) and (max-width: 1921px) {
  .transfer_selected_tips {
    margin-left: 10px;
  }
}
@media screen and (min-width: 1540px) and (max-width: 1708px) {
  .transfer_selected_tips {
    margin-left: 20px;
  }
}
/deep/.guigeTablebox .ant-table-row > td:nth-child(1),
.ant-table-thead > tr:first-child > th :first-child {
  padding-right: 11px;
}
.mgr-20 {
  margin-right: 40px;
}
/deep/.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 34px;
}
</style>

