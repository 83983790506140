<template>
  <div class="pageBox">
    <div
      class="page-list personnelfiles flex-1"
      v-if="pageStatus=='list'"
    >
      <a-row class="filter-wrap ">
        <div class="fillter_flx flex">
          <a-button
            type="primary"
            class="table-btn-clk"
            style="margin-right:19px;margin-left: 0px;height: 32px;"
            @click="addRules('',1)"
          >
            <i
              class="meiye-icon meiye-routeicon-add-copy"
              style="color:#fff;font-size:12px;margin-right:4px;"
            ></i>
            添加
          </a-button>
          <!-- 复合搜索框 -->
          <ComposeInput
            class="storeCpipt"
            :selectArr="selectArr"
            placeholderValue="请输入名称"
            @getList="getListSearch"
          ></ComposeInput>
          <!-- 批量操作 -->
          <span
            class="btn-padding"
            v-show="table.selectedRowKeys.length"
          >
            <a-dropdown
              :overlayStyle='{"width":"81px","paddingBottom":"6px"}'
              overlayClassName="mydropdown"
            >
              <a-menu
                slot="overlay"
                @click="handleMenuClick"
              >
                <a-menu-item
                  @click=';activeStatus2="1"'
                  key="insale"
                  style="text-align:center;margin: 0 5px;padding:5px 0;border-radius: 3px;"
                >在售
                </a-menu-item>
                <a-menu-item
                  key="outsale"
                  @click=';activeStatus2="2"'
                  style="text-align:center;margin: 0 5px;padding:5px 0;border-radius: 3px;"
                >停售
                </a-menu-item>
                <!-- <a-menu-item
                  key="export"
                  @click=';activeStatus2="3"'
                  style="text-align:center;margin: 0 5px;padding:5px 0;border-radius: 3px;"
                >导出 
                </a-menu-item>-->
                <!-- <a-menu-item
                  key="groundingAll"
                  @click=';activeStatus2="4"'
                  style="text-align:center;margin: 0 5px;padding:5px 0;border-radius: 3px;"
                >上架
                </a-menu-item> -->
              </a-menu>
              <a-button class="plbtn normalbtn"> 批量操作

                <i class="meiye-icon  meiye-xiala"></i>
              </a-button>
            </a-dropdown>
            <span class="datas">已选 <span class="baseColor11">{{ table.selectedRowKeys.length }}</span> 条数据</span>
          </span>

        </div>

        <span
          class="right_warp flex-1"
          style="text-align: right;"
          v-if="false"
        >
          <a-button
            class="normalbtn mgr-12"
            @click="exportsobj.visible=true;"
          ><a-icon type="download" />导入</a-button>
          <a-button
            class="normalbtn"
            @click="importsobj.visible=true;"
          ><a-icon
              type="download"
              :rotate="180"
            />导出</a-button>
        </span>
      </a-row>
      <table-filter
        :flitList="flitList"
        :isAllLength="table.pagination.total"
        :isShowAll="true"
        @transferson="clearfliterSingle"
      ></table-filter>
      <a-table
        class="table-manage flex-1"
        :class=" (flitList[0].value.length || flitList[1].value.length || flitList[2].value.length ||flitList[3].value.length)?'':'pdt-6'"
        @change="tableChanged"
        :pagination="table.pagination"
        :rowKey="record=>record.goods_id"
        :loading="table.loading"
        :columns="config.storedvaluecard.columns"
        :data-source="table.tableData"
        :scroll="{  y:getfullHeight((flitList[0].value.length || flitList[1].value.length || flitList[2].value.length ||flitList[3].value.length)? 180 :  126)  }"
        :row-selection=" { selectedRowKeys: table.selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxPropsfn }"
        :rowClassName="setRowClass"
        :customRow="customRow"
      >
        <template
          slot="_1"
          slot-scope="action, record"
        >
          <a-tooltip>
            <template slot="title">
              {{  record.goods_name}}
            </template>
            {{  record.goods_name || '--'}}
          </a-tooltip>
        </template>
        <template
          slot="_1"
          slot-scope="action, record"
        >
          <a-tooltip>
            <template slot="title">
              {{  record.goods_bn}}
            </template>
            {{  record.goods_bn || '--'}}
          </a-tooltip>
        </template>
        <!-- 分类 -->
        <span
          slot="goodstypes"
          class="bigdropdown pointer"
          style="padding-left:0px;"
          :class="statusvisible?'hovedropdown':''"
        >
          <!-- <div class="goodsTypesBox"> -->
          <a-dropdown
            placement="bottomLeft"
            :trigger="['click']"
            v-model="statusvisible"
            :overlayStyle='{"marginLeft":"-7px"}'
          >
            <span style="width: 64px;display: inline-block;">
              <i
                class="meiye-icon meiye-fenlei"
                style="margin-left:8px;"
              ></i>
              分类
              <i
                class="meiye-icon meiye-xiala"
                style="width:10px; margin-top: -1px;"
              ></i>
            </span>
            <div
              class="typesbox"
              slot="overlay"
              @click="e => e.preventDefault()"
            >
              <div class="typesHead">
                <ComposeInput
                  :selectArr="selectArr2"
                  placeholderValue="搜索分类内容"
                  @getList="getTypestreeListSearch"
                  @handleMenuClickSearch="handleMenuClickSearch"
                  :styles="typestyles"
                ></ComposeInput>
              </div>
              <!-- <div class=" "> -->
              <!-- 树形控件 -->
              <!-- 财务 -->
              <div
                class="typesBody"
                v-if="CheckSureObj.tabs ==1"
              >
                <div class="tios1">
                  <a-tree
                    class="ant-tree_own"
                    v-model="permission_id_list"
                    checkable
                    :replace-fields="selectreeObj"
                    :selectedKeys="selectedKeys"
                    :expandedKeys="expandedKeys"
                    :autoExpandParent="autoExpandParent"
                    :tree-data="treeData1"
                    @expand="onExpand"
                    @check="onCheck"
                    show-icon
                  >
                    <i
                      slot="switcherIcon"
                      class="meiye-icon meiye-jiantou"
                    ></i>
                  </a-tree>
                </div>
              </div>

              <div
                class="typesFooter flex "
                v-if="CheckSureObj.tabs ==1"
              >
                <span>已选 <span class="baseColor11">{{  permission_id_list.length}}</span> </span>
                <span>
                  <a-button
                    class="normalbtn"
                    style="width:64px; height:32px;margin-right:16px"
                    @click="offset(true); "
                  >重置</a-button>
                  <a-button
                    type="primary"
                    style="width:64px;height:32px;"
                    @click="onCheckSure"
                  >确定</a-button>
                </span>
              </div>

              <!-- 门店 -->
              <div
                class="typesBody"
                v-if="CheckSureObj.tabs ==2"
              >
                <div class="tios1 tios2">
                  <a-tree
                    class="ant-tree_own"
                    v-model="permission_id_list2"
                    checkable
                    :replace-fields="selectreeObj2"
                    :selectedKeys="selectedKeys2"
                    :expandedKeys="expandedKeys"
                    :autoExpandParent="autoExpandParent"
                    :tree-data="treeData2"
                    @expand="onExpand"
                    @check="onCheck"
                    show-icon
                  >
                    <i
                      slot="switcherIcon"
                      class="meiye-icon meiye-jiantou"
                    ></i>
                  </a-tree>
                </div>
              </div>

              <div
                class="typesFooter flex"
                v-if="CheckSureObj.tabs ==2"
              >
                <span>已选 <span class="baseColor11">{{  permission_id_list2.length}}</span> </span>
                <span>
                  <a-button
                    class="normalbtn"
                    style="width:64px; height:32px;margin-right:16px"
                    @click="offset(true); "
                  >重置</a-button>
                  <a-button
                    type="primary"
                    style="width:64px;height:32px;"
                    @click="onCheckSure"
                  >确定</a-button>
                </span>
              </div>

              <!-- 商城 -->
              <div
                class="typesBody"
                v-if="CheckSureObj.tabs ==3"
              >
                <div class="tios1 flex">
                  <a-tree
                    class="ant-tree_own"
                    v-model="permission_id_list3"
                    checkable
                    :replace-fields="selectreeObj3"
                    :selectedKeys="selectedKeys3"
                    :expandedKeys="expandedKeys"
                    :autoExpandParent="autoExpandParent"
                    :tree-data="treeData3"
                    @expand="onExpand"
                    @check="onCheck"
                    show-icon
                  >
                    <i
                      slot="switcherIcon"
                      class="meiye-icon meiye-jiantou"
                    ></i>
                  </a-tree>
                </div>
              </div>

              <div
                class="typesFooter flex "
                v-if="CheckSureObj.tabs ==3"
              >
                <span>已选 <span class="baseColor11">{{  permission_id_list3.length}}</span> </span>
                <span>
                  <a-button
                    class="normalbtn"
                    style="width:64px; height:32px;margin-right:16px"
                    @click="offset(true)"
                  >重置</a-button>
                  <a-button
                    type="primary"
                    style="width:64px;height:32px;"
                    @click="onCheckSure"
                  >确定</a-button>
                </span>

              </div>

            </div>

          </a-dropdown>

        </span>
        <template
          slot="_11"
          slot-scope="action, record"
        >
          <span style="margin-left:12px;"> {{ record.finance_category_name }}</span>

        </template>
        <!-- 状态 -->
        <template
          slot="_12"
          slot-scope="action, record"
        >

          <span
            style="margin-left:12px;"
            v-if="record.is_show==1"
            class="baseColor11"
          > 在售</span>
          <span
            style="margin-left:12px;"
            v-else
          > 停售</span>
        </template>
        <span
          slot="goodsStatus"
          class="bigdropdown pointer"
          :class="statusIsshow?'hovedropdown':''"
        >

          <a-dropdown
            :trigger="['click']"
            placement="bottomCenter"
            :overlayStyle='{"width":"80px"}'
            overlayClassName="mydropdown"
            v-model="statusIsshow"
          >
            <span
              class="flex align-item-center"
              style="width: 64px;display: inline-block;"
            >
              <i class="meiye-icon meiye-zhuangtai"></i>
              状态
              <i
                class="meiye-icon meiye-xiala"
                style="width:10px; margin-top: -1px;"
              ></i>
              <span class="borderbox">

              </span>
            </span>
            <a-menu
              slot="overlay"
              :default-selected-keys="['0']"
              :selected-keys="flitList[2].value"
            >
              <a-menu-item
                v-for="(v) in filters_status"
                :key="v.text"
                @click=";getbrandsItem('status',v)"
                style="text-align:center;margin:0px 5px;padding: 5px 0px;border-radius: 3px;"
              >
                {{ v.text }}
              </a-menu-item>
            </a-menu>

          </a-dropdown>

        </span>
        <!-- 品牌 -->
        <div
          slot="goodsBrands"
          class="bigdropdown pointer"
          style="padding-left:0px;"
          :class="visiblePlan?'hovedropdown':''"
        >
          <div class="cursor-pointer hoverClass">
            <a-popover
              :title="null"
              v-model="visiblePlan"
              trigger="click"
              placement="bottomLeft"
            >
              <template slot="content">
                <a-select
                  class="width-min-200"
                  optionFilterProp="label"
                  showSearch
                  allowClear
                  v-model="query.goods_brand"
                  @change="event=>getbrandsItem('brands',event)"
                  placeholder="全部品牌"
                >
                  <a-select-option
                    v-for="it in brandsList"
                    :key="it.brand_id"
                    :value="it.brand_id"
                    :label="it.brand_name"
                  >
                    {{ it.brand_name }}
                  </a-select-option>
                </a-select>
              </template>
              <span class="flex align-item-center">
                <i
                  style="font-size:15px;color:#9EA6A4; padding-left:8px; margin-right: 3px;"
                  class="meiye-icon  meiye-fangan"
                ></i>
                品牌
                <i
                  style="font-size:9px;margin-right: 10px;"
                  class="meiye-icon meiye-xiala"
                ></i>
              </span>
            </a-popover>
          </div>

        </div>
        <template
          slot="_13"
          slot-scope="action, record"
        >
          <span style="margin-left:12px;"> {{ record.brand_name }}</span>

        </template>

        <template
          slot="_7"
          slot-scope="action, record"
        >
          <a-button
            size="small"
            class="mgr-12 smallbtn"
            v-if="record.is_show==1"
            @click="stopSell(record,0)"
          >停售</a-button>
          <a-button
            size="small"
            class="mgr-12 smallbtn"
            v-if="record.is_show==0"
            @click="stopSell(record,1)"
          >在售</a-button>

          <a-button
            size="small"
            class="mgr-12 smallbtn"
            @click="addRules(record,3)"
          >编辑</a-button>
          <a-button
            size="small"
            class="mgr-12 smallbtn"
            @click="addRules(record,2)"
          >上架</a-button>
          <!-- @click="openproductsDetais(record)" -->
          <a-button
            size="small"
            class="mgr-12 smallbtn"
            @click="handleBatch('delete',[record],'提示')"
          >删除</a-button>
        </template>
      </a-table>
    </div>
    <div
      class=" flex-1"
      v-if="pageStatus=='add'"
    >
      <management-add></management-add>
    </div>
    <div
      class=" flex-1"
      v-if="pageStatus=='grounding'"
    >
      <management-grounding></management-grounding>
    </div>
    <div
      class=" flex-1"
      v-if="pageStatus=='groundingAll'"
    >
      <management-grounding-all></management-grounding-all>
    </div>

    <!-- 导出 -->
    <a-modal
      title="导出文件"
      :visible="importsobj.visible"
      @cancel=";importsobj.visible=false;"
      :width="600"
      okText="导出"
      :cancel-button-props="{ style: { display: 'none' } }"
      @ok="exportsFn"
      class=" "
    >
      <div class="exports_body">
        <div
          class="exports_select flex align-item-center"
          style="justify-content: space-between;margin-bottom: 32px;"
        >
          <span>导出类型</span>
          <a-select
            default-value="1"
            style="width: 411px"
          >
            <a-select-option value="1">
              导出全部产品
            </a-select-option>
            <a-select-option value="2">
              导出部分分类
            </a-select-option>
            <a-select-option value="3">
              导出部分品牌
            </a-select-option>
          </a-select>
        </div>
        <!-- <div
          class="exports_setxt"
          style=""
        > 选择分类、选择品牌
        </div> -->
        <div class="exports_setxt2">
          <p class="mgb-12"><a-button class="btn3">编辑</a-button></p>
          <div class="exports_setxt2_box">
            已选产品:全部
            已选分类：财务分类
            已选品牌：财务分类
            排毒、祛脂肪粒、分类三、分类四、分类五、分类名称、分类名称、分类名称、分类名称
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 导入  import-->
    <a-modal
      title="导入文件"
      :visible="exportsobj.visible"
      @cancel=";exportsobj.visible=false;"
      :width="600"
      okText="确定"
      cancelText="取消"
      @ok="importsFn"
      class=" "
    >
      <div
        class="imports_body "
        style="margin-bottom: 8px;"
      >
        <div class="downloadFile baseColor11 mgb-24">下载产品导入模板.xlsx</div>
        <div
          class="download"
          style="height: 213px;"
        >
          <a-upload-dragger
            name="file"
            :multiple="true"
            action="http://sandbox.interface.bloc.meirenji.vip/api/upload"
            @change="handleChangedrag"
            :customRequest="uploadFilesDrag"
          >
            <p class="ant-upload-drag-icon">
              <a-icon
                type="file-add"
                class="baseColor11"
                style="color:#00B2A8;"
                theme="filled"
              />
            </p>
            <p class="ant-upload-text">
              将文件拖拽至此区域，也可以 <span class="baseColor11">点击上传</span>
            </p>
            <p class="ant-upload-text">
              文件名.xlcl
            </p>
            <p class="ant-upload-text">
              <a-button class="btn3">更换文件</a-button>
            </p>

          </a-upload-dragger>
        </div>
      </div>
    </a-modal>
    <!-- 分类选择器 树形的选择器 -->
    <a-modal
      title="选择分类"
      :visible="typesModel.visible"
      @cancel=";typesModel.visible=false;"
      :width="1210"
      class="oversizeModal"
    >
      <div class="limits_body">
        <!-- 复合搜索框 -->
        <ComposeInput
          :selectArr="selectArr3"
          placeholderValue="输入关键字"
          @getList="getListSearchlimits"
          :styles="typestyles2"
        ></ComposeInput>
        <!-- 穿梭框 -->
        <div class="transferBox">
          <a-transfer
            class="tree-transfer"
            :data-source="typesModel.dataSource"
            :render="item => item.title"
            :target-keys="typesModel.targetKeys"
            :show-select-all="false"
            @change="onlimitsChange2"
          >
            <template
              slot="children"
              slot-scope="{ props: { direction, selectedKeys }, on: { itemSelect } }"
            >
              <a-tree
                v-if="direction === 'left'"
                blockNode
                checkable
                checkStrictly
                defaultExpandAll
                :checkedKeys="[...selectedKeys, ...typesModel.targetKeys]"
                :treeData="treeDatas"
                @check="
            (_, props) => {
              onChecked1(_, props, [...selectedKeys, ...typesModel.targetKeys], itemSelect);
            }
          "
                @select="
            (_, props) => {
              onChecked1(_, props, [...selectedKeys, ...typesModel.targetKeys], itemSelect);
            }
          "
              />
            </template>
          </a-transfer>
        </div>
      </div>
    </a-modal>
  </div>

</template>
<script> 
import storage from 'store'
import moment from 'moment';
import Msg from '@/components/mrj-menu/msg'
import {
  GetmanageGoodslist,
  GetgoodsoptionsList, //门店分类
  TreetypeFinanceList,//财务分类
  TreetypeMallList,//商城分类
  CreatecardsProposal, UpdateGoods, UpdatcardsProposal, cardsInfoProposal, GetgoodsbrandsoptionsList, DeLeteGoods, BatchGoods
} from '@/api/commodity'
import config from '../config'
import axios from 'axios'
import UploadImage from '../modules/drag'
import ShopEditor from '../modules/wangeditor'
import ManagementAdd from './storedvaluecard_add'
import ManagementGrounding from './storedvaluecard_grounding'
import ManagementGroundingAll from './storedvaluecard_grounding_all'
import ComposeInput from '@/components/compose_input'
import mockData1 from '../modules/mockdata'
import difference from 'lodash/difference';
import { TableFilter } from 'huanlong-components'

const leftTableColumns = [
  {
    dataIndex: 'title',
    title: 'title',
  },
  {
    dataIndex: 'component',
    title: 'component',
  },
];
const rightTableColumns = [
  {
    dataIndex: 'title',
    title: 'title',
  },
  {
    dataIndex: 'component',
    title: 'component',
  },
];
const treeDatas = [
  { key: '0-0', title: '0-0' },
  {
    key: '0-1',
    title: '0-1',
    children: [
      { key: '0-1-0', title: '0-1-0' },
      { key: '0-1-1', title: '0-1-1' },
    ],
  },
  { key: '0-2', title: '0-3' },
]
const transferDataSource = [];
function flatten(list = []) {
  list.forEach(item => {
    transferDataSource.push(
      // {
      // title: item.title,
      // key: item.id.toString(),
      // value: item.path ? item.path : 'xxx'
      item
      // }
    );
    flatten(item.children);
    console.log(transferDataSource, 'typesModel.dataSource');
  });
}
flatten(JSON.parse(JSON.stringify(treeDatas)));
function isChecked(selectedKeys, eventKey) {
  return selectedKeys.indexOf(eventKey) !== -1;
}

function handleTreeData(data, targetKeys = []) {
  data.forEach(item => {
    item['disabled'] = targetKeys.includes(item.key);
    if (item.children) {
      handleTreeData(item.children, targetKeys);
    }
  });
  return data;
}

export default {
  components: {
    UploadImage, ShopEditor, ComposeInput, ManagementAdd, ManagementGrounding, ManagementGroundingAll
    // TableFilter 
  },
  data() {
    return {
      pageStatus: 'list',//groundingAll  add  grounding 
      config,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        authorization: storage.get('token'),
        "Content-Type": "multipart/form-data",
      },
      selectArr: [
        { name: '储值卡名称', id: '1' },
        { name: '商品码', id: '2' },
      ],
      selectArr2: [
        { name: '财务分类', id: '1' },
        { name: '门店分类', id: '2' },
        { name: '商城分类', id: '3' },
      ],
      selectArr3: [
        { name: '机构简称', id: '1' },
        { name: '机构名称', id: '2' },
        { name: '机构代码', id: '3' },
      ],
      statusvisible: false,
      typestyles: {
        width: '334px',
        height: "32px"
      },
      typestyles2: {
        width: '506px',
        height: "32px"
      },
      isactive: false,
      activeStatus1: '1',//商品名称 商品码
      activeStatus2: '1',//批处理
      filters_categorys: [
        { text: '全部', value: '0', key: 0 },
        { text: '客装', value: '1', key: 0 },
        { text: '院装', value: '2', key: 2 },
      ],
      activeStatus_catagory: [],
      filters_status: [
        { text: '全部', value: '0', key: 0 },
        { text: '在售', value: '1', key: 1 },
        { text: '停售', value: '2', key: 2 },
      ],
      activeStatus_status: [],
      activeStatus_brand: [],
      // 分类 树形选择框
      filters_types: [

      ],
      visiblePlan: false,
      activeStatus_types: [],
      ugro_id: '',
      query: {
        goods_name: undefined,//商品名称
        goods_bn: undefined,//商品码
        goods_type: '7', // 
        is_show: undefined, //1在售 0停售 不传为全部
        goods_brand: undefined, //品牌id 不传为全部
        goods_category: undefined, //门店分类 不传为全部
        store_category_id: undefined,// 商城分类
        finance_category_id: undefined,//财务分类
        limit: 10,
        page: 1,
      },
      table: {
        tableData: [],
        selectedRowKeys: [],
        selectedRows: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },

      pulsshow: true,
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        isDisabled: false,
        info: {
          permission_id_list: [],
          goods_type: '7',//商品类型
          goods_category: undefined,//商品分类
          goods_brand: undefined,//商品品牌
          goods_bn: undefined,
          goods_id: undefined,
          goods_name: undefined,
          goods_supplier: undefined,
          goods_unit: undefined,// 单位
          is_show: 1,// 商品状态
          is_del: 0,//是否删除
          goods_image: undefined,
          goods_slider: undefined,//轮播图
          goods_attr: [],//商品规格
          goods_attr_val: [],//商品规格值 
          goods_content: ' ',//商品描述
          goods_desc: undefined,//商品简介
        }
      },
      permission_id_list: [],
      permission_id_list2: [],
      permission_id_list3: [],
      brandsList: [],
      treeData: [],
      treeData1: [],
      treeData2: [],
      treeData3: [],
      selectedKeys: [],
      selectedKeys2: [],
      selectedKeys3: [],
      expandedKeys: [],
      autoExpandParent: false,
      backupsExpandedKeys: [],
      titleList: [],//分类筛选名字 //财务  
      searchValue: '',
      selectreeObj: {
        children: 'children',
        title: 'category_name',
        key: 'finance_category_id',
        value: 'finance_category_id'
      },
      selectreeObj2: {
        children: 'children',
        title: 'category_name',
        key: 'category_id',
        value: 'category_id'
      },
      selectreeObj3: {
        children: 'children',
        title: 'category_name',
        key: 'store_category_id',
        value: 'store_category_id'
      },
      // 规格相关
      goods_specs: {
        isSingle: 1,//1  0单规格 1多规格
        barcode: undefined,//批量设置货品码
        price: undefined,//批量设置单价
        ischooseModehow: false,//选择规格model
        goods_attr: [
          // { attr_name: '', attr_values: '' ,id:0}
        ],
        goods_specs_id: undefined,
        goods_attr_val: [
        ],
        goods_specs_list: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
        ],
        new_goods_specs_list: [
          // { attr_name: ' ', attr_values: [''], id: 3 },
        ],
        goods_specs_values_list: ['红色', '白色'],
        goods_specs_keys_list: [],
      },
      // 表单头
      goods_specs_clomns: [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          title: '*标准单价',
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          title: '货品码',
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
      ],
      goods_specs__sigle_clomns: [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          title: '*标准单价',
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          title: '货品码',
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
      ],
      goodSpecstableData: [],
      goodSpecsSingletableData: [
        {
          product_price: undefined,
          product_image: undefined,
          product_bn: undefined,
          product_attr: '',
          product_is_def: 1,
          product_id: 0,
          product_cost_price: '',
          product_market_price: '',
          product_stock: ''
        }
      ],
      addorupdateSpecFlag: 'add',//是修改规格还是编辑 编辑的时候只能添加规格值
      // 权限弹框
      limitsModel: {
        visible: false,
        originalmockData: undefined,
        mockData: [],
        targetKeys: ['1', '3', '16', '30'],
        leftColumns: leftTableColumns,
        rightColumns: rightTableColumns,
        limitsreplaceFields: {
          key: "id",
          title: 'title'
        }
      },
      typesModel: {
        visible: false,
        originalmockData: undefined,
        mockData: [],
        targetKeys: [],
        leftColumns: leftTableColumns,
        rightColumns: rightTableColumns,
        dataSource: transferDataSource,
      },

      opentimes: {
        visible: false,
        data: {
          goods_id: '',
          goods_shou_type: 1,//1 长期 （默认） 2截止
          goods_shou_end_time: ''
        },
      },
      exportsobj: { //导出
        visible: false,
      },
      importsobj: {//导入
        visible: false,
      },
      // 
      flitList: [
        { key: '类别', value: [], paramas: 'goods_type' },
        { key: '分类', value: [], paramas: 'goods_category' },
        { key: '状态', value: [], paramas: 'is_show' },
        { key: '品牌', value: [], paramas: 'goods_brand' }
      ],
      isAllLength: 0,
      CheckSureObj: {
        checkedKeys: undefined,
        info: undefined,
        tabs: 1,
      },
      statusIsshow: false,
    }
  },
  computed: {
    treeDatas() {
      return handleTreeData(treeDatas, this.typesModel.targetKeys);
    },
  },
  async created() {
    if (this.$route.query.type && this.$route.query.type == 1) {// 添加
      this.pageStatus = 'add'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)

    } else if (this.$route.query.type && this.$route.query.type == 3) { //编辑
      this.pageStatus = 'add'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)
    } else if (this.$route.query.type && this.$route.query.type == 2) { //上架
      this.pageStatus = 'grounding'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)
    } else if (this.$route.query.type && this.$route.query.type == 4) { //批量上架
      this.pageStatus = 'groundingAll'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', false)
        clearTimeout(timers)
        timers = ''
      },)
    } else { //列表
      this.pageStatus = 'list'
      let timers = setTimeout(() => {
        Msg.$emit('changethirdaryShow', true)
        clearTimeout(timers)
        timers = ''
      },)
    }
    // this.getBrands()
    // this.getList()
    // this.getCompanyoptionList()
    // this.getCompanyoptionList2()
    // this.getCompanyoptionList3() 
    // if (this.$route.query.type == 2 || this.$route.query.type == 4) {

    // } else {
    //   this.getBrands()
    //   this.getList()
    //   this.getCompanyoptionList()
    //   this.getCompanyoptionList2()
    //   this.getCompanyoptionList3()
    // }
    if (!this.$route.query.type) {
      this.getBrands()
      this.getList()
      this.getCompanyoptionList()
      this.getCompanyoptionList2()
      this.getCompanyoptionList3()
    }

  },
  mounted() {

  },
  methods: {
    flatten(list = []) {
      let _this = this
      if (list) {
        list.forEach(item => {
          _this.limitsModel.mockData.push({
            key: item.id.toString(),
            title: item.title ? item.title : 'we' + item.id,
            component: item.component ? item.component : 'component',
            pid: item.pid,
            disabled: false,
            description: 'xxx'
          });
          _this.flatten(item.children);
        });
      }
    },
    handleMenuClick({ key }) {
      // insale   outsale groundingAll export
      if (key == 'insale' || key == 'outsale') {
        let data = {
          "goods_ids": this.table.selectedRowKeys,
          "save_data": {
            "is_show": key == 'insale' ? 1 : 0
          }
        }
        BatchGoods(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success('操作成功')
            this.query.page = 1
            this.getList()
          }
        })
      } else if (key == 'groundingAll') {//批量上架
        // 传值this.table.selectedRows  数组   跳转页面  

        console.log(this.table.selectedRowKeys.join());
        console.log(this.$store.state.groudingList);
        // this.$store.commit("setGroudingList", this.table.selectedRows);
        storage.set('groudingList', this.table.selectedRows);
        this.pageStatus = 'groundingAll'
        this.$router.replace({ path: '/shop/storedvaluecard/', query: { type: 4, ids: this.table.selectedRowKeys.join() } })

        // this.$router.replace({ path: '/shop/storedvaluecard/', query: { type: 2 } })
      }

    },

    handleMenuClickSearch(key) {
      if (key.id == 1) { //1财务  
        this.treeData = this.treeData1
        this.CheckSureObj.tabs = 1
        // this.permission_id_list = []
        // this.selectreeObj = this.selectreeObj

        this.permissionInfo.info.permission_id_list = this.permission_id_list

      }
      if (key.id == 2) { // 2 门店 
        this.treeData = this.treeData2
        this.CheckSureObj.tabs = 2
        // this.selectreeObj = this.selectreeObj2
        // this.permissionInfo.info.permission_id_list = []
        this.permissionInfo.info.permission_id_list = this.permission_id_list2
      }
      if (key.id == 3) { // 3 商城
        this.treeData = this.treeData3
        this.CheckSureObj.tabs = 3
        // this.selectreeObj = this.selectreeObj3
        // this.permissionInfo.info.permission_id_list = []
        this.permissionInfo.info.permission_id_list = this.permission_id_list3

      }

    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    // table 多选
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys, selectedRows);
      this.table.selectedRowKeys = selectedRowKeys
      this.table.selectedRows = selectedRows
      // if (!selectedRows.length) {
      //   var isshow = false
      //   if (document.querySelector('.table-manage .ant-table-header .ant-checkbox-inner')) { document.querySelector('.table-manage .ant-table-header .ant-checkbox-inner').style.display = "none" }
      //   document.querySelector('.btn-padding').style.display = "none"
      // } else {
      //   isshow = true
      //   document.querySelector('.btn-padding').style.display = "inline-block"
      //   document.querySelector('.table-manage .ant-table-header .ant-checkbox-inner').style.display = "inline-block"
      // }
      // let times = setTimeout(() => {
      //   if (isshow) {

      //     // console.log(document.querySelectorAll('.ant-table-row-selected'));
      //     document.querySelectorAll('.ant-table-row-selected').forEach(el => {
      //       el.children[0].children[0].style.display = "inline-block"
      //       el.children[0].children[0].children[0].style.display = "inline-block"
      //       el.children[0].children[0].children[0].children[0].style.display = "inline-block"
      //       clearTimeout(times)
      //       times = ''
      //     })
      //   } else {
      //     document.querySelectorAll('.ant-table-body .ant-table-selection-column').forEach(el => {
      //       el.children[0].children[0].style.display = "none"
      //       clearTimeout(times)
      //       times = ''
      //     })
      //   }

      // });


    },
    getCheckboxPropsfn(record) {
      return {
        props: {
          disabled: record.status_text == "已结束" // 禁选项
        }
      };
    },
    setRowClass(record) {
      // console.log(record, 'record');
      if (record.card_status == 0) {
        // return "rowClass";
        return "failRowClass";
      } if (record.card_status == 1) {
        return "successRowClass";
      } else {
        return "successRowClass";
      }

    },
    customRow(record, index) {
      return {
        on: {
          click: event => {
            // document.querySelector('.ant-table-header .ant-checkbox-inner').style.display = "inline-block"
          },
          // mouseenter: event => {
          //   // console.log(document.querySelector('.ant-table-header .ant-checkbox-inner'), "vxvxfvfxv");
          //   // console.log(event.target.children[0].children[0]);
          //   // console.log(event.target.children[0].children[0].children[0]);
          //   event.target.children[0].children[0].style.display = "inline-block"
          //   event.target.children[0].children[0].children[0].style.display = "inline-block"
          //   event.target.children[0].children[0].children[0].children[0].style.display = "inline-block"
          //   // document.querySelector('.ant-table-header .ant-checkbox-inner').style.display = "inline-block"
          //   // document.querySelector('.ant-table-header .ant-table-selection-column').style.display = "inline-block"
          // },
          // mouseleave: (event) => {
          //   // console.log(event.target.className.indexOf('ant-table-row-selected'));
          //   if (event.target.className.indexOf('ant-table-row-selected') < 0) {
          //     event.target.children[0].children[0].style.display = "none";
          //     // document.querySelector('.ant-table-header .ant-checkbox-inner').style.display = "none"
          //     // document.querySelector('.ant-table-header .ant-table-selection-column').style.display = "none"

          //   }

          // }

        },
      }

    },
    getfullHeight(val) {
      console.log(document.body.offsetHeight - val);
      return document.body.offsetHeight - val - 159 + 16
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.info.pic_url_loading = true
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        const { file_url } = info.file.response.data
        this.permissionInfo.info.permission_icon = file_url
        this.permissionInfo.info.pic_url_loading = false
      }
    },
    handleChangedrag(info) {
      const status = info.file.status;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    // 删除 
    handleBatch(key, record, title = "删除") {
      let self = this
      let deleteRules = DeLeteGoods
      const urlArr = [
        { name: deleteRules, key: 'delete', okType: 'primary', msg: '您将删除产品【' + record[0].goods_name + '】，是否确认删除？' },
      ]
      const activeObj = urlArr.filter(it => it.key == key)[0]
      self.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title,
        content: activeObj.msg,
        okText: '确定',
        closable: true,
        okType: activeObj.okType,
        cancelText: '取消',
        onOk() {
          const data = {
            goods_id: record[0].goods_id,
          };
          (activeObj.name)(data).then(res => {
            if (res.error_code == 0) {
              self.$message.success('操作成功')
              self.query.page = 1
              self.getList()
            }
          }).finally(r => {
          })
        },
        onCancel() {
        },
      });
    },
    // 商品品牌
    getBrands() {
      GetgoodsbrandsoptionsList().then(res => {
        console.log(res, "getBrands");
        if (res.error_code == 0) {
          this.brandsList = res.data
          this.brandsList.unshift({
            brand_icon: "meiye-routeicon-chart-pie",
            brand_id: 99999,
            brand_name: "全部",
            brand_sort: 1,
            brand_status: 1,
            created_at: "2023-04-20 16:30:25",
          })
        }
      })
    },
    // 
    getListSearch(e) {
      // console.log(e);
      this.query.page = 1
      if (e.activeStatus1.name == "商品名称") {
        this.query.goods_name = e.value
        this.query.goods_bn = undefined
        this.getList()
      } else {
        this.query.goods_name = undefined
        this.query.goods_bn = e.value
        this.getList()
      }
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        filter: {
          goods_name: self.query.goods_name,//商品名称
          goods_bn: self.query.goods_bn,//商品码
          goods_type: self.query.goods_type, //1客装 2院装 不传全部
          is_show: self.query.is_show, //1在售 0停售 不传为全部
          goods_brand: self.query.goods_brand == 99999 ? undefined : self.query.goods_brand, //品牌id 不传为全部
          goods_category: self.query.goods_category, //门店分类 不传为全部
          store_category_id: self.query.store_category_id,// 商城分类
          finance_category_id: self.query.finance_category_id,//财务分类
        },
        page: self.query.page,
        limit: self.query.limit
      }

      return GetmanageGoodslist(params).then(res => {
        console.log(res.data, "data");
        const { list = [], total = 0 } = res.data
        self.table.tableData = list
        self.table.pagination = Object.assign({}, { pageSize: this.query.limit, current: this.query.page }, {
          total: total
        })
        console.log(self.table.tableData, "///self.table.tableData");
        self.onSelectChange([], [])
      }).finally(r => {
        self.table.loading = false
      })
    },
    // 商品树形列表
    getCompanyoptionList(e) {
      GetgoodsoptionsList().then(res => {
        this.treeRecursion(res.data)
        this.treeData3 = res.data
        console.log(this.treeData, '>>>this.coptionsList');
        if (!e) {
          // this.query.company_parent = res.data[0].company_id
          // this.permissionInfo.info.company_parent = res.data[0].company_id
          this.getList()
        }

      })
    },
    //  门店分类 树形列表
    getCompanyoptionList(e) {
      GetgoodsoptionsList().then(res => {
        this.treeRecursion(res.data, 'category_id')
        this.treeData2 = res.data
        console.log(this.treeData2, '>>>this.coptionsList');
        this.treeRecursion2(this.treeData2, 'disabled')

      })
    },
    //  财务分类 列表
    getCompanyoptionList2(e) {
      TreetypeFinanceList().then(res => {
        this.treeRecursion(res.data, 'finance_category_id')
        this.treeData1 = res.data
        this.treeData = res.data
        console.log(this.treeData, '>>>this.treeData2');
      })
    },
    //  商城分类 树形列表
    getCompanyoptionList3(e) {
      TreetypeMallList().then(res => {
        this.treeRecursion(res.data, 'store_category_id');
        this.treeData3 = res.data
        this.treeRecursion2(this.treeData3, 'disabled')
        console.log(this.treeData, '>>>this.coptionsList');


      })
    },

    // 递归  disabled   disableCheckbox
    treeRecursion2(data, i) {
      data.forEach(el => {
        // 把数字类型转为字符串  
        if (el.category_parent_path.includes(',')) {
          el[i] = false
        } else {
          el[i] = true
        }
        if (el.children && el.children.length && !el.category_parent_path.includes(',')) this.treeRecursion2(el.children, 'disableCheckbox');
      })
    },


    // 递归  disabled   disableCheckbox
    treeRecursion2(data, i) {
      data.forEach(el => {
        // 把数字类型转为字符串  
        if (el.category_parent_path.includes(',')) {
          el[i] = false
        } else {
          el[i] = true
        }
        if (el.children && el.children.length && !el.category_parent_path.includes(',')) this.treeRecursion2(el.children, 'disableCheckbox');
      })
    },

    // 递归改变id key number
    treeRecursion(data, str) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el[str]) == 'number') {
          el[str] = el[str].toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },




    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { limit: Number(pageSize), page: Number(current) })
      this.getList()
    },

    changeStatus($event, e) {
      let obj = {}
      this.table.tableData.forEach(el => {
        if (e.role_id == el.role_id) {
          el.role_status = $event ? 1 : 0
          obj = el
        }
      })
      this.permissionInfo.type = 3 //编辑
      this.permissionInfo.info = {
        role_id: obj.role_id,
        // system_id: obj.system_id,
        role_name: obj.permission_name,
        permission_id_list: [],
        role_status: obj.role_status
      }
      this.okclickmod()
    },
    onChangePermissiontype(e) {
      console.log('radio checked', e.target.value, this.permissionInfo.info.permission_type);
    },
    // 提交按钮
    okclickmod(e) {

      function fn(beforeData, addorupdateSpecFlag) {
        var afterData = []
        beforeData.forEach(item => {
          let flag
          if (addorupdateSpecFlag == 'add') {
            flag = afterData.find(item1 => item1.product_id === item.product_id)
          } else {
            flag = afterData.find(item1 => item1.product_id_add === item.product_id_add)
          }

          if (!flag) {
            if (addorupdateSpecFlag == 'add') {
              afterData.push({
                product_id: item.product_id,
                product_attr: [item]
              })
            } else {
              afterData.push({
                product_id_add: item.product_id_add,
                product_attr: [item]
              })
            }

          } else {
            flag.product_attr.push(item)
          }
        })
        console.log(afterData, 'afterDataafterData');
        return afterData

      }
      function chkstrlen(str) {
        var strlen = false;
        for (var i = 0; i < str.length; i++) {
          if (str.charCodeAt(i) > 255) //如果是汉字， 
            strlen = true;
        }
        return strlen;
      }
      // 这里要搞事情 goods_specs.isSingle  生成多规格商品列表 goodSpecstableData
      if (this.goods_specs.isSingle !== 0) {
        this.permissionInfo.info.goods_attr_val = this.goodSpecstableData
        let arry = []
        this.permissionInfo.info.goods_attr_val.forEach(el => {
          this.permissionInfo.info.goods_attr.forEach((m, n) => {
            let obj = {}
            if (el.hasOwnProperty(m.attr_name)) {
              //   
              obj[m.attr_name] = el[m.attr_name]
              obj.product_id = el.product_id
              obj.product_id_add = el.product_id_add
              arry.push(obj)
            }
          })
        })
        //   
        console.log(arry, "mainarr....");
        // arry=[ {"颜色":"白色"}， {"颜色":"黑色"}，{"尺寸":"m"}，{"尺寸":"l"}  ]
        let mainarr = fn(arry, this.addorupdateSpecFlag)
        mainarr.forEach(m => {
          let s = JSON.stringify(m.product_attr)
          m.product_attr = JSON.parse(s.replace(/},{/g, ","))
        })
        //   
        console.log(mainarr, "mainarr....");
        this.permissionInfo.info.goods_attr_val.forEach(el => {
          mainarr.forEach((m, n) => {
            if (el.product_id == m.product_id || el.product_id_add == m.product_id_add) {
              el.product_attr = m.product_attr[0]
              for (let item in el.product_attr) {
                let names = `"${item}"`
                el.product_attr[names] = el.product_attr[item]
                delete el.product_attr[item]
              }
            }
          })
        })

        console.log(this.permissionInfo.info.goods_attr_val, " this.permissionInfo.info.goods_attr_val");
        this.permissionInfo.info.goods_attr_val.forEach((el, idx) => {
          delete el.product_id
          delete el.product_id_add
          delete el.product_attr['"product_id"']
          delete el.product_attr['"product_id_add"']
          delete el.product_attr['"created_at"']
          delete el.product_attr['"updated_at"']
          delete el.created_at
          delete el.updated_at
          for (var pl in el) { //数组对象遍历
            console.log(pl); //获取key
            console.log(el[pl]) //获取key的值
            let flag = chkstrlen(pl) //判断是否中文字符
            if (flag) {
              delete el[pl]
            }
          }
        })
        //   
        console.log(this.permissionInfo.info.goods_attr_val);

      } else {
        this.permissionInfo.info.goods_attr = []
        delete this.goodSpecsSingletableData[0].product_id
        delete this.goodSpecsSingletableData[0].product_id_add
        this.permissionInfo.info.goods_attr_val = this.goodSpecsSingletableData
      }
      delete this.permissionInfo.info.pic_url_loading

      // 区分新增 修改接口
      if (this.permissionInfo.type == 3) {
        delete this.permissionInfo.info.created_at
        delete this.permissionInfo.info.updated_at
        let data = {
          ...this.permissionInfo.info
        }
        data.goods_type = 7

        UpdatcardsProposal(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        })
      } else {
        //新增


        let data = {
          ...this.permissionInfo.info
        }
        data.goods_type = 7
        delete data.product_id
        delete data.product_id_add
        CreatecardsProposal(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    async addRules(e, i) {
      this.permissionInfo.isDisabled = false
      this.permissionInfo.info = {
        goods_id: undefined,
        goods_name: '',
        goods_bn: '',
        goods_unit: undefined,
        is_give: 0,//0不允许 
        is_show: 1,
        goods_type_hz: 0,//合作项目 需要联动 goods_type   
        goods_attr: undefined,
        goods_attr_val: [{ product_image: '', product_price: '', product_bn: "" }],
        goods_desc: undefined,
        goods_image: undefined,
        goods_slider: undefined,
        goods_type: '7',
        goods_supplier: 1,
        goods_brand: undefined,
        finance_category_id: undefined,
        store_category_id: undefined,
        goods_category: undefined,
        goods_content: undefined,
        goods_shou_type: 1,
        goods_shou_end_time: null
      }
      // await this.getCompanyoptionList(true)
      console.log(this.treeData);
      this.permissionInfo.visible = true

      if (i == 3) {//编辑
        this.pageStatus = 'add'
        this.permissionInfo.isDisabled = true
        this.permissionInfo.title = '编辑商品'
        this.addorupdateSpecFlag = 'update'
        this.permissionInfo.info.goods_id = e.goods_id
        this.permissionInfo.type = 3
        // this.openDatails()
        console.log(this.permissionInfo.info);
        this.$router.push({ path: '/shop/storedvaluecard/', query: { type: i, id: e.goods_id } })
      } else if (i == 1) {
        this.pageStatus = 'add'
        this.permissionInfo.title = '添加商品'
        this.addorupdateSpecFlag = 'add'
        this.permissionInfo.type = 1
        delete this.permissionInfo.info.goods_id
        this.$router.replace({ path: '/shop/storedvaluecard/', query: { type: i } })
        console.log(this.permissionInfo);

      } else if (i == 2) {
        this.permissionInfo.title = '上架'
        this.pageStatus = 'grounding'
        this.$router.replace({ path: '/shop/storedvaluecard/', query: { type: i, id: e.goods_id, bn: e.goods_bn, name: e.goods_name } })

      }
      Msg.$emit('changethirdaryShow', false)
    },

    openDatails() {
      cardsInfoProposal({ goods_id: this.permissionInfo.info.goods_id }).then(res => {
        this.permissionInfo.info = Object.assign(this.permissionInfo.info, res.data)
        this.permissionInfo.info.goods_type = this.permissionInfo.info.goods_type + ''
        this.permissionInfo.info.goods_brand = this.permissionInfo.info.goods_brand + ''
        this.permissionInfo.info.goods_supplier = this.permissionInfo.info.goods_supplier + ''
        this.permissionInfo.info.goods_slider = JSON.parse(this.permissionInfo.info.goods_slider)
        this.permissionInfo.info.goods_attr_val.forEach((el, idx) => {
          el.product_id_add = idx
        })
        let fileList = []
        // 回显轮播图
        this.permissionInfo.info.goods_slider.forEach((val, key) => {
          fileList.push({
            uid: '-' + key,
            // name: '' + val.name, // 文件名
            status: 'done',
            url: '' + val
          })
        })
        this.$refs.uploadCom.fileList = fileList
        // 单规格 or 多规格
        if (this.permissionInfo.info.goods_attr.length) {//多规格
          this.goods_specs.isSingle = 2
          this.goods_specs.new_goods_specs_list = []
          this.goods_specs.goods_specs_keys_list = []
          //  这里多规格 回显设置规格的值列表
          this.permissionInfo.info.goods_attr.forEach((el, idx) => {
            let obj = {
              id: "p" + idx,
              attr_name: el.attr_name,
              attr_values: el.attr_values
            }
            this.goods_specs.new_goods_specs_list.push(obj)
          })
          // 还需要回显规格商品表
          this.goodSpecstableData = []

          // 处理 goods_specs_clomns
          this.goods_specs_clomns = []
          let arri1 = []
          this.permissionInfo.info.goods_attr.forEach((el, idx) => {
            let obj = {
              title: el.attr_name,
              dataIndex: el.attr_name,
              key: el.attr_name,
              ellipsis: true,
              scopedSlots: { customRender: el.attr_name },
            }
            let object1 = {
              attr_name: el.attr_name,
              attr_values: el.attr_values,
              product_id: el.product_id
            }
            this.goods_specs_clomns.push(obj)
            arri1.push(object1)
          })
          this.permissionInfo.info.goods_attr = arri1
          this.goods_specs_clomns = [... this.goods_specs_clomns, ...this.goods_specs__sigle_clomns]
          console.log(this.goods_specs_clomns, "...this.goods_specs_clomns");
          let newResult = arri1.reduce((a, b, c) => {
            let res = []
            a.map(x => {
              b.attr_values.map(y => {
                res.push({ ...x, [b.attr_name]: y })
              })
            })
            return res
          }, [{}])
          //   
          newResult.forEach((el, idx) => {
            let obj = {
              product_id: this.permissionInfo.info.goods_attr_val[idx].product_id,
              product_bn: this.permissionInfo.info.goods_attr_val[idx].product_bn,
              product_attr: this.permissionInfo.info.goods_attr_val[idx].product_attr,
              product_is_def: this.permissionInfo.info.goods_attr_val[idx].product_is_def,
              product_image: this.permissionInfo.info.goods_attr_val[idx].product_image,
              product_price: this.permissionInfo.info.goods_attr_val[idx].product_price,
              product_cost_price: this.permissionInfo.info.goods_attr_val[idx].product_cost_price,
              product_market_price: this.permissionInfo.info.goods_attr_val[idx].product_market_price,
              product_stock: this.permissionInfo.info.goods_attr_val[idx].product_stock
            }
            el = Object.assign(el, obj)
          })

          this.goodSpecstableData = newResult
          console.log(this.goods_specs.new_goods_specs_list, "goods_specs.new_goods_specs_list");

        } else {//单规格
          this.goods_specs.isSingle = 1
          this.goodSpecsSingletableData = this.permissionInfo.info.goods_attr_val
        }

      })
    },
    // 递归改变id key number
    treeRecursion(data) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el.company_id) == 'number') {
          el.company_id = el.company_id.toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      console.log(props)
      // if(props.record.children.length > 0){
      if (props.expanded) {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">关闭详情</a-button></a>
      } else {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">查看详情</a-button></a>
      }
      // }else{
      //     return <span style={{marginRight:8 }}></span>
      // }
    },

    beforeUpload(file, fileList) {
      //   
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    // 商品头图
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.permissionInfo.info.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 规格图
    async uploadspecFiles(info, record, flag) {
      // console.log(info)
      // console.log(record); 
      // console.log(this.goodSpecstableData);
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        if (flag) {
          this.goodSpecsSingletableData[0].product_image = res.data.url
        } else {
          this.goodSpecstableData.forEach(el => {
            if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
              el.product_image = res.data.url
            }
          })
        }


        // this.permissionInfo.info.goods_image = res.data.url
        console.log(this.goodSpecstableData);
      } else {
        this.$message.error(res.error.message)
      }


    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async uploadFilesDrag(info) {

      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.permissionInfo.info.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    callback(key) {
      console.log(key);
    },

    getDelImageId(val) {
      if (this.permissionInfo.info.goods_slider && this.permissionInfo.info.goods_slider.length > 0) {

      } else {
        this.permissionInfo.info.goods_slider = []
      }
      this.permissionInfo.info.goods_slider.push(val)
    },
    getImageshow(val) {
      this.pulsshow = val
    },
    delDelimageId(index) {
      this.permissionInfo.info.goods_slider.forEach((val, key) => {
        if (index === key) {
          this.permissionInfo.info.goods_slider.splice(key, 1)
        }
      })
    },
    // 获取到重新排序后的图片
    handleDraggable(e) {
      const imgDrag = []
      for (var i = 0; i < e.length; i++) {
        // var a = e[i].url.split('/')
        imgDrag.push(e[i].url)
      }
      this.permissionInfo.info.goods_slider = imgDrag
    },
    hideModal() {
      console.log('往表格里面加规格列表');

      var arr = []
      var arr_goods_attr = []
      this.goods_specs.new_goods_specs_list
      this.goods_specs.goods_specs_list
      //   
      arr = [...this.goods_specs.new_goods_specs_list, ...this.goods_specs.goods_specs_keys_list]

      // todo 以后这里需要把规格列表传给后台 成功之后 1，2
      // 1 在这里就需要渲染table的数据和columns操作
      let arri1 = []
      // coluns
      let columns = []
      let specs_clomns = [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          title: '*标准单价',
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          title: '货品码',
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
      ]
      arr.forEach((el, index) => {
        let object1 = {
          attr_name: el.attr_name,
          attr_values: el.attr_values,
        }
        let object2 = {
          title: el.attr_name,
          dataIndex: el.attr_name,
          key: el.attr_name,
          ellipsis: true,
          scopedSlots: { customRender: el.attr_name },
        }
        arri1.push(object1)
        columns.push(object2)
      })
      this.permissionInfo.info.goods_attr = arri1 //规格
      // 动态生成表头
      this.goods_specs_clomns = [...columns, ...specs_clomns]

      //  
      let newResult2 = []
      let newResult = arri1.reduce((a, b, c) => {
        let res = []
        a.map(x => {
          b.attr_values.map(y => {
            res.push({ ...x, [b.attr_name]: y })
          })
        })
        return res
      }, [{}])
      // newResult2 = Object.assign(newResult2, newResult)
      console.log(newResult, 1, newResult2)
      // 动态生成 商品规格表

      // 编辑条件下 比较与旧版的商品规格表 把数据贴上去
      if (this.addorupdateSpecFlag == 'update') {
        newResult.forEach((el, idx) => {
          this.permissionInfo.info.goods_attr_val.forEach((m, n) => {
            //   
            let reg = /\\"/g;
            let a = JSON.stringify(m.product_attr).replace(reg, '');
            console.log(JSON.stringify(el) == a)
            if (JSON.stringify(el) == a) {
              newResult[idx] = Object.assign(m, el)
              // newResult[idx] = m
            }

          })

        })

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        console.log(newResult, 2)

        this.goodSpecstableData = newResult2
      } else {
        this.goodSpecstableData = newResult2
      }

      //   
      // 2
      this.goods_specs.ischooseModehow = false

    },
    changeGoodsSpecs(e, i) {
      this.goods_specs.goods_specs_values_list = []
      // goods_specs.new_goods_specs_list.push
      let self = this
      this.goods_specs.goods_specs_list.forEach(el => {
        if (el.id == e) {
          self.goods_specs.goods_specs_values_list = el.attr_values
          //   
          self.goods_specs.new_goods_specs_list.push(el)
        }

      })
      console.log(this.goods_specs.goods_specs_values_list, "goods_specs_values_list");
      console.log(self.goods_specs.goods_specs_keys_list, " self.goods_specs.goods_specs_keys_list");
    },
    onqyChange(e, it, idex) {
      console.log(this.goods_specs.goods_specs_values_list);
      let self = this
      self.goods_specs.goods_specs_values_list[idex] = e.target.innerHTML
    },
    legalObjadd() {
      let flag = false
      let self = this
      this.goods_specs.goods_specs_values_list.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.goods_specs_values_list.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后进入下一步。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
            // self.qystep = 2
          },
        });
        return false
      }
      console.log(this.goods_specs.goods_specs_values_list);
      this.goods_specs.goods_specs_values_list.push('')
    },
    addnewgoodsspecs(idx, n) {
      let flag = false
      let self = this
      this.goods_specs.new_goods_specs_list[idx].attr_values.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.new_goods_specs_list[idx].attr_values.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后操作。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
          },
        });
        return false
      }
      console.log(this.goods_specs.new_goods_specs_list[idx].attr_values);
      this.goods_specs.new_goods_specs_list[idx].attr_values.push('')
    },
    // 删除新增的规格名 规格值
    deleteNewspecs(e) {
      // delete this.goods_specs.new_goods_specs_list[e]
      this.goods_specs.new_goods_specs_list.splice(e, 1)
      console.log(this.goods_specs.new_goods_specs_list);
    },
    closegoodsSpecsValues(e) {
      this.goods_specs.goods_specs_values_list.splice(e, 1)
      console.log(this.goods_specs.goods_specs_values_list, "this.goods_specs.goods_specs_values_list");
    },
    deletenewGoodsSpecs(idx, n) {
      this.goods_specs.new_goods_specs_list[idx].attr_values.splice(n, 1)
    },
    // 批量生成规格表格  设置 单价 和 货品码
    createdSpecsTable() {

      let self = this
      // this.goodSpecstableData  
      this.goodSpecstableData.forEach((el, idx) => {
        self.goodSpecstableData[idx].product_price = self.goods_specs.price
        self.goodSpecstableData[idx].product_bn = self.goods_specs.barcode
        // el.product_price = this.goods_specs.price
        // el.product_bn = this.goods_specs.barcode
      })
      //   
      console.log(this.goodSpecstableData, "vvvthis.goodSpecstableData");
    },
    // 改变 某一个单价/条码
    changeSingeSpecIpt(record, i, flag) {
      console.log(record, i, flag, "val,record");
      //   
      console.log(this.goodSpecstableData, 'goodSpecstableData')
      if (flag) {
        this.goodSpecsSingletableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      } else {
        this.goodSpecstableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      }

    },
    changeSingeSpecIpt1(e, i, j) {
      //   
      console.log(e, i, j);
    },
    getUser(e) {
      console.log(e);
      this.permissionInfo.info.goods_content = e
    },

    // 清除 类别  分类 品牌  状态 恢复 初始值
    clearfliterAll() {
      // 清除分类
      this.flitList[1].value = []
      this.selectedKeys = []
      this.expandedKeys = []
      this.autoExpandParent = false
      this.backupsExpandedKeys = []
      this.permissionInfo.info.permission_id_list = []

      // // 清除状态
      // this.activeStatus_status = []
      // // 清除类别
      // this.activeStatus_catagory = []
      // // 清除品牌
      // this.activeStatus_brand = [] 
      this.flitList = [
        { key: '类别', value: [], paramas: 'goods_type' },
        { key: '分类', value: [], paramas: 'goods_category' },
        { key: '状态', value: [], paramas: 'is_show' },
        { key: '品牌', value: [], paramas: 'goods_brand' }
      ]
      this.isAllLength = 0
      this.query = {
        goods_name: undefined,//商品名称
        goods_bn: undefined,//商品码
        goods_type: '7', //1客装 2院装 不传全部
        is_show: undefined, //1在售 0停售 不传为全部
        goods_brand: undefined, //品牌id 不传为全部
        goods_category: undefined, //分类id 不传为全部
        limit: this.query.limit,
        page: 1,
      },
        // todu 调取更新list的接口
        this.getList()
    },
    clearfliterSingle(e) {
      console.log(e);

      if (e) {
        // 单个清除 根据业务 去除 
        this.query[e.paramas] = undefined
        this.flitList.forEach(el => {
          if (el.paramas == e.paramas) {
            el.value = []
          }
        })
        // 筛选项
        if (e.key == "分类") {
          this.permissionInfo.info.permission_id_list = this.permission_id_list = this.permission_id_list2 = this.permission_id_list3 = []
        }
        this.query.page = 1
        this.getList()
      } else {
        this.permissionInfo.info.permission_id_list = this.permission_id_list = this.permission_id_list2 = this.permission_id_list3 = []
        // 全部清除
        this.clearfliterAll()
      }

    },
    // getTypestreeListSearch
    getTypestreeListSearch(e) {

    },

    // 搜索框配合 分类 树形
    getTypestreeListSearch(e) {
      console.log(e);
      var _this = this;
      //添加这行代码是为了只点击搜索才触发
      _this.searchValue = e.value;
      //如果搜索值为空，则不展开任何项，expandedKeys置为空
      //如果搜索值不位空，则expandedKeys的值要为搜索值的父亲及其所有祖先
      if (_this.searchValue === '') {
        _this.expandedKeys = [];
      } else {
        //首先将展开项与展开项副本置为空
        _this.expandedKeys = [];
        _this.backupsExpandedKeys = [];
        //获取所有存在searchValue的节点
        let candidateKeysList = _this.getkeyList(_this.searchValue, _this.treeData, []);
        //遍历满足条件的所有节点
        candidateKeysList.map(
          item => {
            //获取每个节点的母亲节点
            var key = _this.getParentKey(item, _this.treeData);
            //当item是最高一级，父key为undefined，将不放入到数组中
            //如果母亲已存在于数组中，也不放入到数组中
            if (key && !_this.backupsExpandedKeys.some(item => item === key))
              _this.backupsExpandedKeys.push(key);
          }
        )
        let length = this.backupsExpandedKeys.length;
        for (let i = 0; i < length; i++) {
          _this.getAllParentKey(_this.backupsExpandedKeys[i], _this.treeData)
        }
        _this.expandedKeys = _this.backupsExpandedKeys.slice();
      }
    },
    //获取节点中含有value的所有key集合
    getkeyList(value, tree, keyList) {
      //遍历所有同一级的树
      for (let i = 0; i < tree.length; i++) {
        let node = tree[i];
        //如果该节点存在value值则push
        if (node.category_name.indexOf(value) > -1) {
          keyList.push(node.category_id);
        }
        //如果拥有孩子继续遍历
        if (node.children) {
          this.getkeyList(value, node.children, keyList);
        }
      }
      //因为是引用类型，所有每次递归操作的都是同一个数组
      return keyList;
    },
    //该递归主要用于获取key的父亲节点的key值
    getParentKey(key, tree) {
      let parentKey, temp;
      //遍历同级节点
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          //如果该节点的孩子中存在该key值，则该节点就是我们要找的父亲节点
          //如果不存在，继续遍历其子节点
          if (node.children.some(item => item.category_id === key)) {
            parentKey = node.category_id;
          } else if (temp = this.getParentKey(key, node.children)) {
            //parentKey = this.getParentKey(key,node.children)
            //改进，避免二次遍历
            parentKey = temp;
          }
        }
      }
      return parentKey;
    },
    //获取该节点的所有祖先节点
    getAllParentKey(key, tree) {
      var parentKey;
      if (key) {
        //获得父亲节点
        parentKey = this.getParentKey(key, tree);
        if (parentKey) {
          //如果父亲节点存在，判断是否已经存在于展开列表里，不存在就进行push
          if (!this.backupsExpandedKeys.some(item => item === parentKey)) {
            this.backupsExpandedKeys.push(parentKey);
          }
          //继续向上查找祖先节点
          this.getAllParentKey(parentKey, tree);
        }
      }
    },
    onExpand(expandedKeys) {
      //用户点击展开时，取消自动展开效果
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys, info) {
      console.log(info.node.dataRef);
      console.log('onCheck', checkedKeys);
      this.CheckSureObj = {
        checkedKeys: checkedKeys,
        info: info,
        tabs: this.CheckSureObj.tabs
      }
      // this.permissionInfo.info.permission_id_list = checkedKeys;
      // this.flitList[1].value = []
      // if (checkedKeys.length) {
      //   this.getTitleList(checkedKeys, this.treeData)
      // }
      // console.log(this.flitList[1].value, 'this.flitList[1].value');
    },
    onCheckSure() {
      let { checkedKeys, info, tabs } = this.CheckSureObj
      let that = this
      that.permissionInfo.info.permission_id_list = []
      that.flitList[1].value = []
      that.flitList[1].paramas = 'finance_category_id'

      let arrobj = []
      info.checkedNodes.forEach(el => {
        let a = that.CheckSureObj.tabs == 1 ? el.data.props.finance_category_id : that.CheckSureObj.tabs == 2 ? el.data.props.category_id : el.data.props.store_category_id
        // that.permissionInfo.info.permission_id_list.push(a)
        arrobj.push(a)
        that.flitList[1].value.push(el.data.props.category_name)
      })
      // let Timers = setTimeout(() => {
      //   that.permissionInfo.info.permission_id_list = arrobj
      //   clearTimeout(Timers)
      //   Timers = ''
      // }, 500)
      if (that.CheckSureObj.tabs == 1) {
        that.query.goods_category = undefined
        that.query.store_category_id = undefined
        that.flitList[1].paramas = 'finance_category_id'

        that.query.finance_category_id = arrobj


      }
      if (that.CheckSureObj.tabs == 2) {
        that.query.finance_category_id = undefined
        that.query.store_category_id = undefined
        that.flitList[1].paramas = 'goods_category'
        that.query.goods_category = arrobj
        // that.permissionInfo.info.permission_id_list


      }
      if (that.CheckSureObj.tabs == 3) {

        that.query.finance_category_id = undefined
        that.query.goods_category = undefined
        that.flitList[1].paramas = 'store_category_id'

        that.query.store_category_id = arrobj
        // that.query.store_category_id = arrobj
        //  that.permissionInfo.info.permission_id_list


      }
      that.statusvisible = false;
      // console.log(that.permissionInfo.info.permission_id_list, '33333333');
      // console.log(this.treeData3)
      // console.log(this.treeData2)
      // console.log(this.treeData1)
      that.query.page = 1
      that.getList()
    },
    getTitleList(arr, tree) {
      let newArr = this.treeToArray(tree)
      arr.forEach((el, idx) => {
        newArr.forEach((m, n) => {
          if (m.category_id == el) {
            this.flitList[1].value.push(m.category_name)
          }
        })
      })
      return newArr
    },
    // 数据扁平化
    treeToArray(tree) {
      return tree.reduce((res, item) => {
        const { children, ...i } = item
        return res.concat(i, children && children.length ? this.treeToArray(children) : [])
      }, [])
    },
    onSelect(selectedKeys, info) {
      console.log('onSelect', info);
      this.selectedKeys = selectedKeys;
    },
    getbrandsItem(i, v) {
      if (i == 'catagory') {
        console.log(this.activeStatus_catagory);
        this.flitList[0].value = [v.text]
        let a = ''
        a = v.text == '客装' ? [1, 3] : v.text == '院装' ? [2, 4] : []
        if (v.text == '全部') {
          let arr = []
          this.filters_categorys.forEach(el => {
            if (el.text != '全部') {
              arr.push(el.text)
            }
          })
          // this.activeStatus_catagory = arr
          this.flitList[0].value = arr
          a = [1, 2, 3, 4]
        }

        this.query.goods_type = a
      }
      if (i == 'status') {
        console.log(this.activeStatus_status);
        // this.activeStatus_status = [v.text]
        this.flitList[2].value = [v.text]
        let a = ''
        a = v.text == '在售' ? '1' : '0'
        if (v.text == '全部') {
          let arr = []
          this.filters_status.forEach(el => {
            if (el.text != '全部') {
              arr.push(el.text)
            }
          })
          // this.activeStatus_status = arr
          this.flitList[2].value = arr
          a = undefined
        }
        this.query.is_show = a
        this.statusIsshow = false
      }
      if (i == 'brands') {
        this.visiblePlan = false;
        // this.activeStatus_brand = [v.brand_name]
        // this.flitList[3].value = [i]
        console.log(v, this.activeStatus_brand);
        // if (v == 99999) {
        v = [v]
        let arr = []
        let arr2 = []
        let arr3 = []
        this.brandsList.forEach(el => {
          if (el.brand_id != 99999) {
            arr.push(el.brand_name)
            arr3.push(el.brand_id)
          }
          v.forEach(m => {
            if (m == el.brand_id && m != 9999) {
              arr2.push(el.brand_name)
            }
          })
        })
        if (v == 99999) {
          this.flitList[3].value = arr
        } else {
          this.flitList[3].value = arr2
        }
        // this.query.goods_brand = v.includes(99999) ? arr3 : v
      }
      this.query.page = 1
      // 发送请求 
      this.getList()
    },
    giveLimits(report) {
      this.limitsModel.visible = true
      let obj = {
        activeStatus1: {
          id: "1",
          name: "机构简称"
        },
        value: ''
      }

      let timers = setTimeout(() => {
        let o = document.querySelectorAll('.ant-transfer-list-header-selected')[0]
        if (o.innerHTML.includes('未授权机构')) {
          clearTimeout(timers)
          timers = ''
          return false
        }
        let o1 = document.querySelectorAll('.ant-transfer-list-header-selected')[1]
        let a = document.querySelectorAll('.ant-transfer-list-header-selected>span')[0]
        let b = document.querySelectorAll('.ant-transfer-list-header-selected>span')[2]
        var ospan = document.createElement("span");
        ospan.className = 'baseColor11'
        var ospan2 = document.createElement("span");
        ospan2.className = 'baseColor11'
        var oTxt = document.createTextNode("未授权机构 ");
        var oTxt2 = document.createTextNode("已授权机构 ");
        ospan.appendChild(oTxt);
        o.insertBefore(ospan, a);
        ospan2.appendChild(oTxt2);
        o1.insertBefore(ospan2, b);
        console.log(document.querySelectorAll('.ant-transfer-list-header-selected>span'));
        clearTimeout(timers)
        timers = ''
      }, 500)

      this.getListSearchlimits(obj)
    },
    // 授权机构 搜索
    getListSearchlimits(e) {
      console.log(e);
      // 根据条件 判断是机构简称1 机构代码2 机构名称3 的搜索条件
      this.checkLimitsListfn()

    },
    checkLimitsListfn(e) {

    },
    // 授权穿梭框相关
    onlimitsChange(nextTargetKeys) {
      this.limitsModel.targetKeys = nextTargetKeys;
    },
    onlimitsChange2(nextTargetKeys) {

      console.log(this.typesModel);
      this.typesModel.targetKeys = nextTargetKeys;
    },
    onChecked1(_, e, checkedKeys, itemSelect) {
      const { eventKey } = e.node;
      itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
    },
    triggerDisable(disabled) {
      this.disabled = disabled;
    },

    triggerShowSearch(showSearch) {
      this.showSearch = showSearch;
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: item => ({ props: { disabled: disabled || item.disabled } }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },

    // 树形结构 转 平铺 从外到内依次递归，有 children 则继续递归
    treeToArr(data, pid = null, res = []) {
      data.forEach(item => {
        res.push({ pid: pid, id: item.id, name: item.name });
        if (item.children && item.children.length !== 0) {
          this.treeToArr(item.children, item.id, res);
        }
      });
      return res;
      // 
    },
    // 逆向 平铺-转-树形结构 ，有 children 则继续递归
    arrToTree(arr, pid = null) {
      const res = [];
      arr.forEach(item => {
        if (item.pid === pid) {
          // 这样每次都需要遍历整个数组，因此时间复杂度为 n*n
          // const children = arrToTree(arr, item.id)   

          // 往下递归时，每次数组的大小都在减小，每次都筛选掉父代元素，最终的时间复杂度为 n*logn
          const children = this.arrToTree(arr.filter(v => v.pid !== pid), item.id);
          if (children.length) {
            res.push({ ...item, children })
          } else {
            res.push({ ...item })
          }
        }
      });
      return res;
    },
    // 在售时间确认
    openTimes(record) {
      // 调取 编辑接口
      this.opentimes.visible = true
      this.opentimes.data.goods_id = record.goods_id
      // this.opentimes.goods_shou_type = 1
      this.opentimes.data.goods_shou_type = 1
      // this.permissionInfo.info.goods_shou_type='' //销售时间 1长期 （默认） 2截止
      // this.permissionInfo.info.goods_shou_end_time ='' //截止时间 戳

    },
    updataOpentime() {
      let data = this.opentimes.data
      UpdatcardsProposal(data).then(res => {
        if (res.error_code == 0) {
          this.$message.success(res.data.message)
          this.opentimes.visible = false
          this.query.page = 1
          this.getList()
        }
      })
    },
    onChangeopentime(e, i) {
      console.log(e, i);
      this.opentimes.data.goods_shou_type = e.target.value
    },
    // 停售 在售
    stopSell(record, i) {
      let data = {
        goods_id: record.goods_id,
        is_show: i,
      }
      UpdateGoods(data).then(res => {
        if (res.error_code == 0) {
          this.$message.success(res.data.message)
          this.query.page = 1
          this.getList()
        }
      })
    },

    // 导入 导出
    importsFn() { },
    exportsFn() { },
    offset(e) {
      this.CheckSureObj.info = undefined
      this.CheckSureObj.checkedKeys = undefined
      this.query.finance_category_id = undefined;
      this.query.store_category_id = undefined;
      this.query.category_id = [];
      this.permission_id_list = [];
      this.permission_id_list2 = [];
      this.permission_id_list3 = [];
      this.permissionInfo.info.permission_id_list = [];
      this.expandedKeys = [];
      this.selectedKeys = [];
      this.selectedKeys2 = [];
      this.selectedKeys3 = [];
      this.query.page = 1
      this.flitList[1].value = '';
      this.query.goods_category = undefined //门店分类 不传为全部 

      if (e) {  //判断 flitList 是否为空 如果为空 不显示 
        let arr = []
        if (this.flitList.length) {
          this.flitList.forEach(el => {
            if (el.value && el.value.length) {
              arr = arr.concat(el.value)
            }
          })
        }
        if (!arr.length) {
          this.clearfliterSingle()
        }
      }
      this.statusvisible = false
      this.getList()
      this.$forceUpdate();
    },
  },
  watch: {

  },

}
</script>
<style lang="less" scoped>
.personnelfiles {
  margin: 18px;
  padding: 0;
  overflow: hidden;
  height: calc(100vh - 90px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  // height: calc(100% - 91px);
  .filter-wrap {
    background: #fff;
    border-radius: 4px 4px 0 0;
    height: 66px;
    display: flex;
    align-items: center;
    z-index: 1;
    margin-bottom: 7px;
    // justify-content: space-between;
    // &::before{
    //   content: none;

    // }
  }
  /deep/ .ant-checkbox-wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
.borderbox {
  margin-left: 4px;
  display: inline-block;
  border-radius: 6px;
  text-align: center;
  width: 18px;
  height: 18px;
  // background: @btn2mianbgDColor;
  // box-shadow: 0px 2px 3px 0px @btn2mianOutDColor,
  //   inset 0px -2px 0px 0px @btn2mianInsertDColor;
  border-radius: 4px;
  // border: 1px solid @btn2mianboderDColor;
  line-height: 14px;

  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.bigdropdown_imga {
  display: none;
}
.bigdropdown_img1a {
  display: none;
}
.bigdropdown {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 26px;
  // background: #f2f5f5;
  border-radius: 4px;
  line-height: 26px;
  padding: 0 8px 0 8px;
  &:hover {
    background: #f2f5f5;
  }
  // .borderbox {
  //   position: absolute;
  //   top: 4px;
  //   left: 55px;
  .meiye-xiala {
    position: absolute;
    top: 2px;
    left: 59px;
    font-size: 12px;
    margin-top: -1px;
    // transform: scale(0.83);
    display: inline-block;
    // color: @btn2mianfontDColor;
    color: @fontColor4;
  }
  // &:hover {
  //   // border: 1px solid @btn2mianboderHColor;
  //   .meiye-xiala {
  //     position: absolute;
  //     top: 1px;
  //     left: 64px;
  //     font-size: 8px;
  //     margin-top: -1px;
  //     transform: scale(0.83);
  //     display: inline-block;
  //     // color: @btn2mianfontHColor;
  //     color: #414041;
  //     transform: scale(0.83) rotate(180deg);
  //   }
  // }
  // }
}
/deep/ .bgchangenode {
  background: #ccc;
}
/deep/.ant-modal-header {
  padding-left: 24px;
}
/deep/.ant-modal-footer {
  padding-bottom: 32px;
}
.btn-padding {
  // display: none;
  // background: rgba(216, 219, 236, 0.27);
  border-radius: 4px;
  color: @btn3mianFontDColor;
  // background: #f5f5fa;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    color: @btn3mianFontHColor !important;
    // background-color: @btn3mianbgHColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    color: @btn3mianFontFColor !important;
    // background-color: @btn3mianbgFColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  // border: 1px solid @btn2mianboderDColor;
  // box-shadow: 0px 2px 4px 0px @btn2mianOutDColor, inset 0px -2px 0px 0px @btn2mianInsertDColor;
  // background-color: @btn2mianbgDColor;
  // color: @btn2mianfontDColor;
  // &:active {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor !important;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:focus {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:hover {
  //   color: @btn2mianfontHColor !important;
  //   border: 1px solid @btn2mianboderHColor !important;
  //   background-color: @btn2mianbgHColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutHColor, inset 0px -2px 0px 0px @btn2mianInsertHColor;
  // }
}
/deep/ .ant-table-header {
  // margin-top: -23px;
}
/deep/.bigdropdown .ant-dropdown-open {
  .meiye-xiala {
    position: absolute;
    top: 1px;
    left: 61px;
    font-size: 12px;
    margin-top: -1px;
    // transform: scale(0.83);
    display: inline-block;
    // color: @btn2mianfontHColor;
    color: #414041;
    transform: rotate(180deg);
  }
}
/deep/.bigdropdown .ant-popover-open {
  .meiye-xiala {
    position: absolute;
    top: 1px;
    left: 56px;
    font-size: 8px;
    margin-top: -1px;
    display: inline-block;
    // color: @btn2mianfontHColor;
    color: #414041;
    transform: rotate(180deg);
    margin-left: 3px;
  }
}
/deep/
  .ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-cascader-menu-item:hover,
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-tree li .ant-tree-node-content-wrapper:hover,
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: @selectdownbgHColor !important;
  // color: #0A955D;
  color: @selectdownfontHColor;
  font-weight: 400;
}
.smallbtnlong {
  letter-spacing: -1px !important;
}

.meiye-icon {
  color: @primaryColor;
}
/deep/ .ant-input2 .ant-input {
  padding-top: 5px;
  &::placeholder {
    padding-top: 3px;
  }
}
.linebox {
  display: flex;
  padding: 12px 0;

  .lfbox {
    width: 110px;
    font-size: 14px;
    color: @fontColor5;
    text-align: left;
    .imgxing {
      width: 8px;
      height: 22px;
      color: red;
    }
  }

  .rgbox {
    font-size: 14px;
    color: @btnColorfont1;
    // width: calc(100% - 120px);
    position: relative;
    width: 542px;

    .tuijia {
      font-size: 14px;
      color: @fontColor6;
    }
  }
}
/deep/ .ant-upload-picture-card-wrapper .ant-upload {
  width: 45px !important;
  height: 45px !important;
  padding: 0;
}
/deep/ .ant-upload-select-picture-card i {
  font-size: 14px;
}
.constmodel {
  min-height: 420px;
}
.chooseguige {
  margin-right: 120px;
  margin-left: 12px;
  &:hover {
    color: @primaryColor;
  }
}
.allsetguige {
  &:hover {
    color: @primaryColor;
  }
}
.guige_values_options {
  position: relative;
  margin-right: 20px;
  // .
  i {
    position: absolute;
    right: -8px;
    top: 0;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.13);
  }
}
.closeIcon {
  position: absolute;
  right: -8px;
  top: 0;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.13);
}
.guige_values {
  margin: 0 28px;
  flex-wrap: wrap;
  .guige_values_options {
    margin-bottom: 20px;
  }
}
.goods_specx_add {
  margin: 0 28px 12px;
}
.goods_editor {
  // width: 582px;
  width: 432px;
  height: 320px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #ccc;
  overflow: hidden;
  overflow-y: auto;
}
/deep/ .myFileList .ant-spin-nested-loading {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-height: 40px;
}
/deep/ .guigeTable .ant-spin-nested-loading {
  min-height: 40px;
}
/deep/.ant-table-pagination.ant-pagination {
  height: 65px;
}

/deep/ .ant-table-header .ant-checkbox {
  width: 14px;
  height: 14px;
}
/deep/ .table-manage .ant-table-header .ant-checkbox-inner {
  // display: none;
}
/deep/.table-manage .ant-table-tbody .ant-checkbox-inner {
  // display: none;
}
/deep/
  .table-manage
  .ant-table-tbody
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner {
  display: block !important;
}
/deep/ .table-manage .ant-table-selection-column:hover .ant-checkbox-inner {
  display: block !important;
}
/deep/.tableBox .ant-table-thead .ant-table-selection-column span:nth-child(1) {
  display: inline-block;
}
/deep/.tableBox .ant-table-body .ant-table-selection-column span:nth-child(1) {
  display: none;
}
.meiye-xiala {
  color: @fontColor4;
}

// 复合搜索框
.owninputbox {
  display: inline-block;
  background: #f5f7f7;
  width: 401px;
  height: 36px;
  line-height: 36px;
  background: #f5f7f7;
  box-shadow: inset 0px 3px 0px 0px rgba(76, 124, 121, 0.1);
  border-radius: 4px;
  border: 1px solid #b0c3c2;
  margin-right: 20px;
  .meiye-icon {
    color: @fontColor4;
  }
  .meiye-sousuo {
    color: @fontColor5;
  }
  .owninputbox_selested {
    cursor: pointer;
    width: 103px;
    border-right: 1px solid #b0c3c2;
    display: inline-block;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: @fontColor4;
    line-height: 22px;
    position: relative;
    &:hover {
      .meiye-icon {
        color: @primaryColor;
      }
    }
    // padding-left: 8px;
  }
  .owninputbox_search {
    box-shadow: none;

    // i {
    //   position: absolute;
    //   right: 12px;
    //   top: 2px;
    // }
  }
  .ant-input2 {
    height: 22px;
    border: none !important;
    box-shadow: none !important;
    background: #f5f7f7;
    /deep/ .ant-input {
      height: 22px;
      border: none !important;
      box-shadow: none !important;
      background: transparent;
    }
  }
  &:hover {
    border: 1px solid @primaryColor;
    .meiye-sousuo {
      color: @primaryColor;
    }
  }
}
.datas {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor5;
  line-height: 22px;
  margin-left: 12px;
}
.baseColor11 {
  color: @baseColor11;
}
.baseColor1 {
  color: red;
}
.filter {
  margin-top: -9px;
  padding-bottom: 6px;
  background: #fff;
}
.filter-wrap2 {
  background: #fff;
  height: 55px;
  padding: 18px 44px 0 22px;
  align-items: center;
  .filter_sx {
    font-size: 16px;
    font-weight: 400;
    color: @fontColor3;
    height: 16px;
    line-height: 16px;
    border-right: 1px solid #dadada;
    padding-right: 12px;
    margin-right: 18px;
  }
  .filter_type {
    height: 24px;
    background: #f3fafa;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 14px;
    font-weight: 400;
    color: #9ea6a4;
    line-height: 24px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    .typeitems {
      font-size: 14px;
      font-weight: 400;
      color: @baseColor11;
      display: flex;
      align-items: center;
      .overflowElip {
        display: inline-block;
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .meiye-close-bold {
        font-size: 9px;
        color: #578380;
        margin: 2px 0 0 4px;
      }
    }
  }
  .filter_clear {
    cursor: pointer;
    .meiye-routeicon-ashbin {
      color: @fontColor5;
      font-size: 10px;
    }
  }
}
.meiye-leixing,
.meiye-zhuangtai,
.meiye-fenlei,
.meiye-pinpai {
  color: #9ea6a4;
  font-size: 16px;
}

.typesbox {
  width: 358px;
  height: 368px;
  background: #ffffff;
  box-shadow: 0px 6px 8px 0px rgba(99, 161, 148, 0.08),
    0px 12px 24px 0px rgba(0, 0, 0, 0.08),
    inset 0px -3px 0px 0px rgba(205, 231, 231, 0.49);
  border-radius: 6px;
  border: 1px solid #e4e9e9;
  display: flex;
  flex-direction: column;
  .typesHead {
    margin: 12px;
  }
  .typesBody {
    flex: 1;
    overflow: auto;
    margin: 0 12px;
  }
}
.typesFooter {
  width: 356px;
  height: 58px;
  line-height: 58px;
  border-top: 1px solid #e8edec;
  background: #ffffff;
  padding: 0px 12px;
  align-items: center;
  justify-content: space-between;
}
.btn3 {
  border-radius: 4px;
  color: @btn3mianFontDColor;
  background: #f5f5fa;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    color: @btn3mianFontHColor !important;
    background-color: @btn3mianbgHColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    color: @btn3mianFontFColor !important;
    background-color: @btn3mianbgFColor !important;
    border: none !important;
    box-shadow: none !important;
  }
}
.exports_setxt {
  width: 100%;
  height: 42px;
  border-radius: 4px;
  border: 1px dashed #ccdada;
  text-align: center;
  line-height: 42px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    border: 1px dashed @primaryColor;
    color: @baseColor11;
  }
}
.exports_setxt2_box {
  width: 100%;
  height: 237px;
  overflow-y: auto;
  background: #fafcfc;
  border-radius: 4px;
  border: 1px dashed #ccdada;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor4;
  padding: 12px 16px;
}
/deep/.filter-wrap2 .filter_sx {
  color: @fontColor3 !important;
}
/deep/ .storeCpipt.owninputbox .owninputbox_selested {
  // width: 115px;
}
.normalbtn {
  font-size: 14px;
  &:hover {
    .meiye-xiala {
      color: @baseColor11;
    }
  }
  .meiye-xiala {
    width: 10px;
    margin-left: 5px;
    font-size: 12px;
  }
}
/deep/.typesBody .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  display: none;
}
.plbtn {
  width: 101px;
  position: relative;
  text-align: left;
  padding-right: 30px;
  .meiye-xiala {
    margin-left: 5px;
    font-size: 12px;
    position: absolute;
    right: 15px;
    top: 9px;
  }
  &:hover {
    .meiye-xiala {
      color: @baseColor11;
      transform: rotate(180deg);
      font-size: 12px;
      position: absolute;
      right: 14px;
      top: 9px;
    }
  }
}
.ant-dropdown-open {
  // color: @baseColor11 !important;
}
.ant-dropdown-open .meiye-xiala {
  // color: @baseColor11 !important;
  transform: rotate(180deg) !important;
  position: absolute;
  right: 14px;
  top: 9px;
}
.table-manage {
  margin-top: -1px;
}
.hovedropdown {
  background: #f2f5f5;
}
/deep/.ant-btn > i,
/deep/.ant-btn > span {
  transition: margin-left 0s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-duration: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0s;
}
/deep/ .table-manage .ant-table-thead > tr > th {
  margin-bottom: 0;
  padding-bottom: 2px !important;
}
/deep/.filter_clear {
  color: @fontColor5;
  display: inline-block;
  height: 24px;
  width: 46px;
  line-height: 24px;
  position: relative;
  text-align: right;
}
/deep/.filter_clear .meiye-shanchu {
  font-size: 14px;
  // line-height: 20px;
  position: absolute;
  left: 0;
  top: 1px;
}
</style>